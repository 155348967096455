export function pixelToTopCss(input: number): string {
  switch (input) {
    case 1:
      return "top-[1px]";
    case 2:
      return "top-[2px]";
    case 3:
      return "top-[3px]";
    case 4:
      return "top-[4px]";
    case 5:
      return "top-[5px]";
    case 6:
      return "top-[6px]";
    case 7:
      return "top-[7px]";
    case 8:
      return "top-[8px]";
    case 9:
      return "top-[9px]";
    case 10:
      return "top-[10px]";
    case 11:
      return "top-[11px]";
    case 12:
      return "top-[12px]";
    case 13:
      return "top-[13px]";
    case 14:
      return "top-[14px]";
    case 15:
      return "top-[15px]";
    case 16:
      return "top-[16px]";
    case 17:
      return "top-[17px]";
    case 18:
      return "top-[18px]";
    case 19:
      return "top-[19px]";
    case 20:
      return "top-[20px]";
    case 21:
      return "top-[21px]";
    case 22:
      return "top-[22px]";
    case 23:
      return "top-[23px]";
    case 24:
      return "top-[24px]";
    case 25:
      return "top-[25px]";
    case 26:
      return "top-[26px]";
    case 27:
      return "top-[27px]";
    case 28:
      return "top-[28px]";
    case 29:
      return "top-[29px]";
    case 30:
      return "top-[30px]";
    case 31:
      return "top-[31px]";
    case 32:
      return "top-[32px]";
    case 33:
      return "top-[33px]";
    case 34:
      return "top-[34px]";
    case 35:
      return "top-[35px]";
    case 36:
      return "top-[36px]";
    case 37:
      return "top-[37px]";
    case 38:
      return "top-[38px]";
    case 39:
      return "top-[39px]";
    case 40:
      return "top-[40px]";
    case 41:
      return "top-[41px]";
    case 42:
      return "top-[42px]";
    case 43:
      return "top-[43px]";
    case 44:
      return "top-[44px]";
    case 45:
      return "top-[45px]";
    case 46:
      return "top-[46px]";
    case 47:
      return "top-[47px]";
    case 48:
      return "top-[48px]";
    case 49:
      return "top-[49px]";
    case 50:
      return "top-[50px]";
    case 51:
      return "top-[51px]";
    case 52:
      return "top-[52px]";
    case 53:
      return "top-[53px]";
    case 54:
      return "top-[54px]";
    case 55:
      return "top-[55px]";
    case 56:
      return "top-[56px]";
    case 57:
      return "top-[57px]";
    case 58:
      return "top-[58px]";
    case 59:
      return "top-[59px]";
    case 60:
      return "top-[60px]";
    case 61:
      return "top-[61px]";
    case 62:
      return "top-[62px]";
    case 63:
      return "top-[63px]";
    case 64:
      return "top-[64px]";
    case 65:
      return "top-[65px]";
    case 66:
      return "top-[66px]";
    case 67:
      return "top-[67px]";
    case 68:
      return "top-[68px]";
    case 69:
      return "top-[69px]";
    case 70:
      return "top-[70px]";
    case 71:
      return "top-[71px]";
    case 72:
      return "top-[72px]";
    case 73:
      return "top-[73px]";
    case 74:
      return "top-[74px]";
    case 75:
      return "top-[75px]";
    case 76:
      return "top-[76px]";
    case 77:
      return "top-[77px]";
    case 78:
      return "top-[78px]";
    case 79:
      return "top-[79px]";
    case 80:
      return "top-[80px]";
    case 81:
      return "top-[81px]";
    case 82:
      return "top-[82px]";
    case 83:
      return "top-[83px]";
    case 84:
      return "top-[84px]";
    case 85:
      return "top-[85px]";
    case 86:
      return "top-[86px]";
    case 87:
      return "top-[87px]";
    case 88:
      return "top-[88px]";
    case 89:
      return "top-[89px]";
    case 90:
      return "top-[90px]";
    case 91:
      return "top-[91px]";
    case 92:
      return "top-[92px]";
    case 93:
      return "top-[93px]";
    case 94:
      return "top-[94px]";
    case 95:
      return "top-[95px]";
    case 96:
      return "top-[96px]";
    case 97:
      return "top-[97px]";
    case 98:
      return "top-[98px]";
    case 99:
      return "top-[99px]";
    case 100:
      return "top-[100px]";
    case 101:
      return "top-[101px]";
    case 102:
      return "top-[102px]";
    case 103:
      return "top-[103px]";
    case 104:
      return "top-[104px]";
    case 105:
      return "top-[105px]";
    case 106:
      return "top-[106px]";
    case 107:
      return "top-[107px]";
    case 108:
      return "top-[108px]";
    case 109:
      return "top-[109px]";
    case 110:
      return "top-[110px]";
    case 111:
      return "top-[111px]";
    case 112:
      return "top-[112px]";
    case 113:
      return "top-[113px]";
    case 114:
      return "top-[114px]";
    case 115:
      return "top-[115px]";
    case 116:
      return "top-[116px]";
    case 117:
      return "top-[117px]";
    case 118:
      return "top-[118px]";
    case 119:
      return "top-[119px]";
    case 120:
      return "top-[120px]";
    case 121:
      return "top-[121px]";
    case 122:
      return "top-[122px]";
    case 123:
      return "top-[123px]";
    case 124:
      return "top-[124px]";
    case 125:
      return "top-[125px]";
    case 126:
      return "top-[126px]";
    case 127:
      return "top-[127px]";
    case 128:
      return "top-[128px]";
    case 129:
      return "top-[129px]";
    case 130:
      return "top-[130px]";
    case 131:
      return "top-[131px]";
    case 132:
      return "top-[132px]";
    case 133:
      return "top-[133px]";
    case 134:
      return "top-[134px]";
    case 135:
      return "top-[135px]";
    case 136:
      return "top-[136px]";
    case 137:
      return "top-[137px]";
    case 138:
      return "top-[138px]";
    case 139:
      return "top-[139px]";
    case 140:
      return "top-[140px]";
    case 141:
      return "top-[141px]";
    case 142:
      return "top-[142px]";
    case 143:
      return "top-[143px]";
    case 144:
      return "top-[144px]";
    case 145:
      return "top-[145px]";
    case 146:
      return "top-[146px]";
    case 147:
      return "top-[147px]";
    case 148:
      return "top-[148px]";
    case 149:
      return "top-[149px]";
    case 150:
      return "top-[150px]";
    case 151:
      return "top-[151px]";
    case 152:
      return "top-[152px]";
    case 153:
      return "top-[153px]";
    case 154:
      return "top-[154px]";
    case 155:
      return "top-[155px]";
    case 156:
      return "top-[156px]";
    case 157:
      return "top-[157px]";
    case 158:
      return "top-[158px]";
    case 159:
      return "top-[159px]";
    case 160:
      return "top-[160px]";
    case 161:
      return "top-[161px]";
    case 162:
      return "top-[162px]";
    case 163:
      return "top-[163px]";
    case 164:
      return "top-[164px]";
    case 165:
      return "top-[165px]";
    case 166:
      return "top-[166px]";
    case 167:
      return "top-[167px]";
    case 168:
      return "top-[168px]";
    case 169:
      return "top-[169px]";
    case 170:
      return "top-[170px]";
    case 171:
      return "top-[171px]";
    case 172:
      return "top-[172px]";
    case 173:
      return "top-[173px]";
    case 174:
      return "top-[174px]";
    case 175:
      return "top-[175px]";
    case 176:
      return "top-[176px]";
    case 177:
      return "top-[177px]";
    case 178:
      return "top-[178px]";
    case 179:
      return "top-[179px]";
    case 180:
      return "top-[180px]";
    case 181:
      return "top-[181px]";
    case 182:
      return "top-[182px]";
    case 183:
      return "top-[183px]";
    case 184:
      return "top-[184px]";
    case 185:
      return "top-[185px]";
    case 186:
      return "top-[186px]";
    case 187:
      return "top-[187px]";
    case 188:
      return "top-[188px]";
    case 189:
      return "top-[189px]";
    case 190:
      return "top-[190px]";
    case 191:
      return "top-[191px]";
    case 192:
      return "top-[192px]";
    case 193:
      return "top-[193px]";
    case 194:
      return "top-[194px]";
    case 195:
      return "top-[195px]";
    case 196:
      return "top-[196px]";
    case 197:
      return "top-[197px]";
    case 198:
      return "top-[198px]";
    case 199:
      return "top-[199px]";
    case 200:
      return "top-[200px]";
    default:
      return `top-[${input}px]`;
  }
}

<div class="container mx-auto px-4 py-8">
  <div class="grid grid-cols-6 container mx-auto py-4">
    <div class="col-span-4">
      <div class="flex items-center gap-4">
        <div class="w-40 rounded-3xl overflow-hidden">
          <app-network-image [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
                             [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
          </app-network-image>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex gap-2">
            <p class="text-body2">{{ restaurantDetailState.response()?.data?.restaurantName }}</p>
            <app-rating-count [rating]="restaurantDetailState.response()?.data?.rating ?? 0"
                              [count]="restaurantDetailState.response()?.data?.ratingCount ?? 0"></app-rating-count>

          </div>
          <div class="flex flex-wrap gap-3 max-w-3xl">
            <app-chip
              [name]="numberToPricePoint(restaurantDetailState.response()?.data?.pricePoint ?? 0)"></app-chip>
            @for (cuisine of restaurantDetailState.response()?.data?.cuisines; track cuisine) {
              <app-chip [name]="cuisine.name">
              </app-chip>
            }
          </div>
          <div class="flex items-center gap-2">
            <app-svg-icon src="assets/icons/location.svg" class="text-neutral-400"></app-svg-icon>
            <p class="text-sky-400 text-body2">{{ distanceInMiles() }}</p>
            <p class="text-neutral-400 text-body2">-
              {{ arrayToCSV([restaurantDetailState.response()?.data?.location?.address1, restaurantDetailState.response()?.data?.location?.city, restaurantDetailState.response()?.data?.location?.state]) }}
            </p></div>
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-3">
          <button class="p-3 border rounded-md text-body2" (click)="onFavoriteClicked($event)">
            <div class="flex gap-3 justify-center items-center">
              <app-svg-icon
                [src]="restaurantLiked() ? 'assets/icons/heart-filled.svg' : 'assets/icons/heart-outline.svg'"
                class="w-6 h-6 items-center "
                [ngClass]="restaurantLiked() ? 'text-pink-500' : 'text-neutral-400'">
              </app-svg-icon>
              <p class="text-body2"> Favorite </p>
            </div>
          </button>
          @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
            <app-direction-button
              [destinationLatitude]="destinationGeoPoint().latitude"
              [destinationLongitude]="destinationGeoPoint().longitude">
            </app-direction-button>
          }
          <button class="p-3 bg-blue-100 border rounded-md text-body2" (click)="onShareClicked()">
            <div class="flex gap-3 justify-center items-center">
              <app-svg-icon src="assets/icons/share.svg"
                            class="w-6 h-6  items-center text-blue-700"></app-svg-icon>
              <p class="text-body2"> Share </p>
            </div>
          </button>
        </div>
        <div class="flex gap-3 items-center">
          <app-svg-icon src="assets/icons/phone.svg" class="text-neutral-400"></app-svg-icon>
          <p class="text-sky-400 text-body2">{{ restaurantDetailState.response()?.data?.phoneNumber }}</p>
          <app-svg-icon src="assets/icons/mail.svg" class="text-neutral-400"></app-svg-icon>
          <p class="text-sky-400 text-body2">{{ restaurantDetailState.response()?.data?.email }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="border-b border-neutral-100 container mx-auto"></div>

  <div class="mt-10" infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="onScroll()"
       [infiniteScrollDisabled]="photoGalleryState.loading()">
    <div class="columns-1 sm:columns-2 md:columns-3 lg:columns-4 gap-4 space-y-4">
      @for (photo of photos(); track photo) {
        <div
          class="bg-white rounded-lg shadow-2 overflow-hidden break-inside-avoid transform transition-transform duration-300 hover:scale-105 mb-4">
          <img [src]="photo.url" [alt]="'Gallery image'" class="w-full object-cover">
        </div>
      }
    </div>
  </div>


  @if (photos().length === 0 && !photoGalleryState.loading()) {
    <div class="flex justify-center items-center p-28">
      <app-no-data message="No photos found"></app-no-data>
    </div>
  }
</div>




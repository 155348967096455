@if(showTitle()) {
  <div class="header flex justify-center items-center px-3 py-6 border-b border-neutral-200">
    <p class="text-body1">{{ title() }}</p>
    <p class="absolute right-3 rounded-lg p-3 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
       (click)="onCloseClicked()">
      <app-svg-icon src="assets/icons/close.svg" class="text-neutral-800" [size]="12"></app-svg-icon>
    </p>
  </div>
}

<mat-dialog-content class="mat-dialog-content body overflow-y-auto py-6 px-8 h-50 my-12">
  <ng-content select="[body]"></ng-content>
</mat-dialog-content>

@if(showAction()) {
  <div class="border-t">
    <mat-dialog-actions>
      <div class="w-full py-2">
        <ng-content select="[actions]"></ng-content>
      </div>
    </mat-dialog-actions>
  </div>
}

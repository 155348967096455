<div class="inline-flex border border-neutral-400 px-2 py-2 gap-2 rounded-md items-center select-none">
    <p class="text-body2">
      <ng-content></ng-content>
    </p>
    <div class="hover:bg-neutral-100 rounded-full p-1 text-neutral-600 cursor-pointer" (click)="onChipRemoveClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24" fill="currentColor">
            <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="currentColor"/>
        </svg>
    </div>
</div>

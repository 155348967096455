import {Component, inject, OnInit, signal} from '@angular/core';
import {Constants} from "../../constants/constants";
import {Platform} from "@angular/cdk/platform";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-mobile-app-download-dialog',
  standalone: true,
  imports: [
    ButtonComponent
  ],
  templateUrl: './mobile-app-download-dialog.component.html',
  styleUrl: './mobile-app-download-dialog.component.scss'
})
export class MobileAppDownloadDialogComponent {
  platform = inject(Platform);
  protected readonly Constants = Constants;
  private _bottomSheetRef = inject(MatBottomSheetRef<MobileAppDownloadDialogComponent>);

  onSkipForNowClick() {
    this._bottomSheetRef.dismiss();
  }
}

<mat-form-field [ngClass]="fullWidth() ? 'w-full' : 'false'" [appearance]="appearance()">
  <mat-label>{{ label() }}</mat-label>
  <input matInput [formControl]="formControl" [matDatepicker]="picker" [min]="minDate()" [max]="maxDate()" (dateChange)="onDateSelected($event)"/>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  @if (formControl.errors && hasErrors) {
    <mat-error>
      {{ formControl.errors | humanizeFormMessages: errorMessages() }}
    </mat-error>
  }
</mat-form-field>

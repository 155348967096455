<app-base-dialog [title]="'Add Payment Method'">
  <div body>
    @if(elementsOptions.clientSecret) {
      <ng-container class="mb-5">
        <ngx-stripe-payment #stripeContent [clientSecret]="elementsOptions.clientSecret"></ngx-stripe-payment>
      </ng-container>
    }
  </div>
  <div actions>
    <div class="flex justify-end gap-3">
      <app-button (click)="onCancelClicked()" appearance="outline">Cancel</app-button>
      <app-button (click)="onAddPaymentMethodClicked()" >Save Card</app-button>
    </div>
  </div>
</app-base-dialog>

import {Component, inject, OnInit} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../../../../shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "../../../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../../../shared/base/base-component";
import {State} from "../../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../../core/models/generic-response";
import {AddressService} from "../../../../data/address.service";
import {AuthService} from "../../../../../auth/data/auth.service";
import {ChangePasswordRequest} from "../../../../../auth/data/change-password/change-password-request";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    MatDialogContent,
    ReactiveFormsModule,
    TextInputComponent,
    MatDialogActions,
    ButtonComponent
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  changePasswordForm!: FormGroup;
  private authService = inject(AuthService);
  dialogRef = inject(MatDialogRef<ChangePasswordComponent>);
  state = new State<GenericResponse>();


  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  onCloseClicked() {
    this.dialogRef.close();
  }

  onClearClicked() {
    this.changePasswordForm.reset();
  }

  onChangePasswordClicked() {
    this.validateForm(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
      return;
    }

    const formValue = this.changePasswordForm.value;
    const request: ChangePasswordRequest = {
      oldPassword: formValue.currentPassword,
      newPassword: formValue.newPassword
    };
    this.executeRequest({
      state: this.state,
      request: this.authService.sendConfirmCode(request),
      successMessage: 'Password changed successfully',
      handleSuccess: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }
    });
  }
}

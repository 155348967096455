import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
    selector: 'app-feature-1-section',
    standalone: true,
    templateUrl: './feature-1-section.component.html',
    styleUrl: './feature-1-section.component.scss',
    imports: [AppSvgIconComponent]
})
export class Feature1SectionComponent {

}

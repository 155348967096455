import {ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter, withComponentInputBinding,
  withInMemoryScrolling, withViewTransitions
} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAngularSvgIcon} from "angular-svg-icon";
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getPerformance, providePerformance} from '@angular/fire/performance';
import {environment} from "../environments/environment";
import {GlobalErrorHandler} from "./core/exception/global-error-handler";
import {httpGlobalErrorInterceptor} from "./core/base/api/interceptors/http-global-error.interceptor";
import {httpTokenInterceptor} from "./core/base/api/interceptors/http-token.interceptor";
import {provideMomentDateAdapter} from "@angular/material-moment-adapter";
import {provideNgxStripe} from "ngx-stripe";
import {provideNgxMask} from "ngx-mask";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      // inMemoryScrollingFeature,
      withComponentInputBinding(),
      withViewTransitions()),
    provideClientHydration(),
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler
    // },
    provideAngularSvgIcon(),
    provideNgxStripe(environment.stripePublishableKey),
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptors([httpTokenInterceptor, httpGlobalErrorInterceptor])),
    provideAnimationsAsync(),
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebaseConfig))),
    importProvidersFrom(provideAnalytics(() => getAnalytics())), ScreenTrackingService,
    importProvidersFrom(providePerformance(() => getPerformance())),
    provideMomentDateAdapter(undefined, {strict: false, useUtc: false}),
    provideNgxMask()
  ],

};

import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {CartService} from "../../../data/cart.service";
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {state} from "@angular/animations";
import {State} from "../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../core/models/generic-response";

@Component({
  selector: 'app-clear-cart-confirmation-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './clear-cart-confirmation-overlay.component.html',
  styleUrl: './clear-cart-confirmation-overlay.component.scss'
})
export class ClearCartConfirmationOverlayComponent extends BaseComponent {
  dialogRef = inject(MatDialogRef);

  onYesClicked() {
    this.dialogRef.close(true);
  }

  onNoClicked() {
    this.dialogRef.close(false);
  }

}

import {HttpErrorResponse, HttpInterceptorFn} from "@angular/common/http";
import {AuthService} from "../../../../features/auth/data/auth.service";
import {inject} from "@angular/core";
import {Router} from "@angular/router";
import {AuthStoreService} from "../../../services/auth-store-service/auth-store.service";
import {retry, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

export const httpTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthStoreService);
  const tokenService = inject(AuthStoreService);

  const router = inject(Router);

  const tokens = tokenService.getToken();
  if (tokens.accessToken) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    });
  }

  return next(req).pipe(
    // retry(2),
    catchError((e: HttpErrorResponse) => {
      if (e.status === 401) {
        tokenService.logout();
      }

      const error = e.error?.message || e.statusText;
      return throwError(() => error);
    })
  );

}

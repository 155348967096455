<div class="container-fluid bg-neutral-50">

  <div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">

    @if (bookingDetailsState.loading()) {
      <div class="flex flex-col gap-10 p-4 bg-white">
        <app-shimmer type="list"></app-shimmer>
        <app-shimmer type="list"></app-shimmer>
        <app-shimmer type="list"></app-shimmer>
      </div>
    }

    @if (bookingDetailsState.success()) {
      <div class="flex flex-col gap-4 py-2">
        <div class="flex flex-col gap-2">
          <div class="flex justify-center py-2">
            @switch (getBookingStatusSummary(booking()?.data?.status ?? '')) {
              @case (BookingStatusSummary.confirmed) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success bg-opacity-10 flex-shrink-0">
                  <app-svg-icon [size]="64" class="text-success" src="assets/icons/check-circle.svg"></app-svg-icon>
                </span>
              }
              @case (BookingStatusSummary.finished) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success bg-opacity-10 flex-shrink-0">
                  <app-svg-icon [size]="64" class="text-success" src="assets/icons/check-circle.svg"></app-svg-icon>
                </span>
              }
              @case (BookingStatusSummary.cancelled) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-error bg-opacity-10 flex-shrink-0">
                  <app-svg-icon [size]="30" class="text-error" src="assets/icons/close.svg"></app-svg-icon>
                </span>
              }
            }
          </div>
          <div class="flex justify-center">
            <p class="text-body-2"
               [ngClass]="getStatusColor()">{{ getBookingStatusText(booking()?.data?.status ?? '') }}</p>
          </div>
          <p class="text-body2 text-neutral-700 text-center pb-2">Booking ID -
            #{{ booking()?.data?.confirmationNumber }}</p>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div class="col-span-2 flex flex-col gap-4 px-4 py-6 bg-white">

            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium">Restaurant Details</p>
              <div class="flex gap-3">
                <app-network-image class="h-auto w-32 object-cover"
                                   [imageUrl]="booking()?.data?.restaurant?.banner?.url">
                </app-network-image>
                <div class="flex flex-col">
                  <p class="text-body2 text-neutral-700">{{ booking()?.data?.restaurant?.restaurantName }}</p>
                  <div class="flex flex-col gap-2">
                    <p class="text-body2 text-neutral-400">
                      {{
                        arrayToCSV([booking()?.data?.restaurant?.location?.address1,
                          booking()?.data?.restaurant?.location?.city,
                          booking()?.data?.restaurant?.location?.state
                        ])
                      }}
                    </p>
                    <div class="flex gap-5">
                      @if (this.booking()?.data?.restaurant?.phoneNumber) {
                        <div
                          class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-neutral-50 cursor-pointer"
                          (click)="onCallClicked()">
                          <app-svg-icon src="assets/icons/phone.svg"
                                        class="text-blue-500 cursor-pointer"></app-svg-icon>
                        </div>
                      }
                      @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
                        <app-direction-button appearance="iconButton" class="text-blue-500 cursor-pointer"
                                              [destinationLatitude]="destinationGeoPoint().latitude"
                                              [destinationLongitude]="destinationGeoPoint().longitude">
                        </app-direction-button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="border-b border-neutral-100 my-3"></div>

            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium">Booking Details</p>
              <div class="flex items-center gap-6">
                <div class="flex justify-start gap-2">
                  <app-svg-icon src="assets/icons/calendar.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
                  <p class="text-body2">{{ booking()?.data?.startTime | date:'d MMMM y' }}</p>
                </div>
                <div class="flex gap-2 items-center rounded-2xl">
                  <app-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
                  <p class="text-body2">{{ booking()?.data?.startTime | date:'h:mm a' }}</p>
                </div>
                <div class="flex gap-2 items-center rounded-2xl">
                  <app-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
                  <p class="text-body2">{{ booking()?.data?.partySize }} Guest(s)</p>
                </div>
              </div>
              @if (!isPastBooking()) {
                <div class="flex gap-8 mt-4">
                  @switch (getBookingStatusSummary(booking()?.data?.status ?? '')) {
                    @case (BookingStatusSummary.confirmed) {
                      <app-button appearance="outline" (click)="updateBookingClicked()">Modify Booking</app-button>
                      <span (click)="onCancelClicked()" class="flex gap-2 items-center cursor-pointer text-error">
                        <app-svg-icon src="assets/icons/close.svg" [size]="14"></app-svg-icon>
                        <p class="text-body2">Cancel Booking</p>
                      </span>
                    }
                    @case (BookingStatusSummary.cancelled) {

                    }
                  }

                </div>
              }
            </div>


            <div class="border-b border-neutral-100 my-3"></div>

            <div class="flex flex-col gap-2">
              <p class="text-body2">Tags</p>
              <div class="inline-flex">
                <div class="flex gap-2 items-center px-2 py-1 bg-neutral-100 rounded-xl">
                  <app-svg-icon src="assets/icons/tag.svg" [size]="20" class="text-neutral-500"></app-svg-icon>
                  <p class="text-body2 px-1">{{ booking()?.data?.tags }}</p>
                </div>
              </div>
            </div>
            <div class="flex items-center gap-4">
              <div class="flex flex-col gap-2">
                <p class="text-body2">Occasion</p>
                <div class="inline-flex">
                  <div class="flex gap-2 items-center text-body2 px-2 py-1 bg-neutral-100 rounded-xl">
                    <app-svg-icon src="assets/icons/calendar.svg" [size]="20" class="text-neutral-500"></app-svg-icon>
                    <p class="text-body2 px-1">{{ booking()?.data?.occasion }}</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <p class="text-body2">Seating Preference</p>
                <div class="inline-flex">
                  <div class="flex gap-2 items-center px-2 py-1 bg-neutral-100 rounded-xl">
                    <app-svg-icon src="assets/icons/seating-preference.svg" [size]="20"
                                  class="text-neutral-500"></app-svg-icon>
                    <p class="text-body2 px-1">{{ booking()?.data?.seatingOption }}</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="border-b border-neutral-100 my-3"></div>


            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium">Guest Details</p>
              <p
                class="text-body2">{{ booking()?.data?.customerInfo?.firstName }} {{ booking()?.data?.customerInfo?.lastName }}</p>
              <p class="text-body2">{{ booking()?.data?.customerInfo?.phoneNumber }}</p>
            </div>

            @if (booking()?.data?.status == "FINISHED" && booking()?.data?.surveyStatus == "PENDING") {
              <div class="border-t py-6 border-neutral-100">
                <app-button appearance="outline" [iconSrc]="'assets/icons/done.svg'" (click)="onRateReviewClicked()">
                  RATE YOUR BOOKING
                </app-button>
              </div>
            }

            @if (booking()?.data?.surveyStatus == "SUBMITTED") {
              <div class="border-t py-6 flex items-center border-neutral-100">
                <div class="flex items-center gap-4">
                  <app-svg-icon src="assets/icons/done.svg" class="text-success"></app-svg-icon>
                  <p class="text-body2">Thank you for submitting your survey </p>
                </div>
              </div>
            }

          </div>

          <div class="col-span-1 flex flex-col gap-4 bg-white px-4 py-6">

            @if (isPastBooking() || getBookingStatusSummary(booking()?.data?.status!) == BookingStatusSummary.cancelled) {

            } @else {
              <div class="flex flex-col gap-1">
                <p class="text-body2 text-primary-500 font-medium">Invite Guest(s)</p>
                <p class="text-caption text-neutral-400">Invite your friends to dine in with you!</p>
              </div>
              <form [formGroup]="inviteForm" class="flex gap-6 items-center w-full" (ngSubmit)="onInvitePressed()">
                <input formControlName="emailOrPhone"
                       class="rounded py-3 px-3 border-neutral-400 focus:border-primary-500 focus:outline-primary-500 border w-full"
                       placeholder="Phone (or) Email"/>
                <app-button iconSrc="assets/icons/invite-guest.svg" type="submit">Invite</app-button>
              </form>
            }

            <div class="flex flex-col gap-2">
              <p class="text-body2 text-neutral-700">Invited Guests</p>
              @if (invitedGuestsState.success() && invitationReceivers().length <= 0) {
                <div class="flex justify-center my-8">
                  <app-no-data [size]="80" message="No guests invited"></app-no-data>
                </div>
              }
              @for (receiver of invitationReceivers(); track receiver) {
                <div class="flex items-center gap-4 py-2">
                  <app-svg-icon class="text-neutral-500" src="assets/icons/account-circle.svg"></app-svg-icon>
                  <p
                    class="break-words whitespace-normal text-body2 max-w-xl min-w-xl">{{ receiver?.email ?? receiver?.phoneNumber ?? 'Unknown Guest' }}</p>
                  <div class="grow"></div>
                  <p class="px-3 py-2 text-caption rounded-full bg-error bg-opacity-10 text-error cursor-pointer"
                     (click)="onInviteCancelClicked(receiver)">Cancel</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>


import {Component, EventEmitter, Output,} from '@angular/core';


@Component({
  selector: 'app-filter-chip',
  standalone: true,
  templateUrl: './filter-chip.component.html',
  styleUrl: './filter-chip.component.scss',
  imports: []
})
export class FilterChipComponent {

  @Output()
  removeClicked = new EventEmitter();

  onChipRemoveClicked() {
    this.removeClicked.emit();
  }

}

<div class="flex flex-col overflow-hidden bg-neutral-100">

  <div class="flex flex-col gap-5 bg-white px-8 py-6">
    <div class="flex gap-4 items-center">
      <p class="text-body1 text-primary-500">Customise your food</p>
      <div class="grow"></div>
      <app-close-button (click)="onCloseClicked()"></app-close-button>
    </div>
    <div class="flex gap-3">
      <app-network-image [imageUrl]="foodItem.image?.url" class="w-20"></app-network-image>
      <div class="flex flex-col gap-2">
        <p class="text-body1">{{ foodItem.name }}</p>
        <p class="text-body2">{{ foodItem.description ?? 'N/A' }}</p>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-5 mx-6 overflow-y-auto max-h-96 my-4">
    <div class="flex gap-5 items-center">
      <p class="text-body1">{{ activeModifierGroup()?.name }}</p>
    </div>
    <div class="flex flex-col gap-3 px-6 py-6 bg-white rounded-3xl">
      @for (modifier of activeModifierGroup()?.modifiers ?? []; track modifier; let last = $last) {
        <div class="flex gap-3 justify-between  border-neutral-100 py-2" [ngClass]="last ? 'border-none' : 'border-b'">
          <div class="flex flex-col gap-3">
            <p class="text-body2">{{ modifier.name }}</p>
            <p class="text-body2 text-primary-500">$ {{ modifier.price | number: '1.2-2' }}</p>
          </div>
          @switch (activeModifierGroup()?.selection) {
            @case (ModifierGroupSelectionType.SINGLE_CHOICE) {
              <app-radio-button [value]="modifier._id"
                                [ngModel]="modifier.isSelected()"
                                (valueChanged)="activeModifierGroup()?.onSingleChoiceModifierSelected($event)"
                                [groupName]="activeModifierGroup()?._id ?? 'modifier-group'">
              </app-radio-button>
            }
            @case (ModifierGroupSelectionType.MULTI_CHOICE) {
              <app-checkbox (valueChanged)="modifier.onItemSelectedOrUnSelected($event)"
                            [ngModel]="modifier.isSelected()">
              </app-checkbox>
            }
            @case (ModifierGroupSelectionType.QUANTITY) {
              <app-counter-input (valueChanged)="modifier.onQuantityChanged($event)"
                                 [ngModel]="modifier.selectedQuantity()">
              </app-counter-input>
            }
          }
        </div>
      }
    </div>
  </div>

  <div class="relative flex gap-4 items-center py-4 px-4 bg-white shadow-1">

    @if (isPreviousAvailable()) {
      <p class="rounded-full p-4 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
         (click)="onPreviousPressed()">
        <app-svg-icon src="assets/icons/chevron-left.svg" class="text-neutral-800" [size]="12"></app-svg-icon>
      </p>
    }

    <p class="text-body px-2 py-1 bg-white">Option {{ currentGroupIndex() + 1 }}
      / {{ foodItem.modifierGroups.length }}</p>
    <div class="grow"></div>


    @if (isNextAvailable()) {
      <app-button (click)="onNextPressed()">Next</app-button>
    }

    @if (isLastOption()) {
      <div class="flex items-center gap-3">
        <app-counter-input [minValue]="1" [(ngModel)]="noOfItems"></app-counter-input>
        <app-button iconSrc="assets/icons/bag.svg" (click)="onAddItemPressed()">Add Item</app-button>
      </div>
    }

  </div>

</div>



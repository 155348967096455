@if (dineInOption() == DineInOption.pickupOrder) {
  <div class="flex items-center gap-4 md:gap-12 px-4 py-2 shadow-2 rounded-lg">
    <!--  date starts-->
    <div class="relative hover:bg-neutral-50 py-2 px-4 rounded-lg select-none cursor-pointer">
      <app-date-selector-input title="Select Date" [(ngModel)]="restaurantsFilterService.selectedDate"
                               (valueChanged)="onDateSelected($event)"></app-date-selector-input>
    </div>
    <!--  date ends-->

    <!--  time starts-->
    <form [formGroup]="timeForm">
      <app-single-item-picker-input formControlName="time" [title]="'Select Time'" [display]="'timeString'"
                                    [items]="timeSlots()"
      (valueChanged)="onTimeChanged($event)"></app-single-item-picker-input>
    </form>
    <!--  time ends-->
  </div>
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  getCurrentLocation() {
    return new Promise<GeoPoint>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            const location: GeoPoint = {
              latitude,
              longitude
            };

            resolve(location);
          }
        }, (error) => {
          reject(error.message);
        });
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }

  reverseGeocode(latitude: number, longitude: number): Promise<LocationAddress> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const latlng = {lat: latitude, lng: longitude};

      geocoder.geocode({'location': latlng}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results && results.length > 0) {
            const locationAddress = this.getAddressFromComponents(results[0], latitude, longitude);
            resolve(locationAddress);

          } else {
            reject('No results found');
          }
        } else {
          reject('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  getAddressFromComponents(geocoderResult: google.maps.GeocoderResult, latitude: number, longitude: number): LocationAddress {
    const addressComponents = geocoderResult.address_components;
    let address: LocationAddress = {
      addressLine1: '',
      streetNumber: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      county: '',
      formattedAddress: geocoderResult.formatted_address,
      latitude: latitude,
      longitude: longitude
    };

    addressComponents.forEach(component => {
      if (component.types.includes('route')) {
        address.addressLine1 = component.long_name;
      } else if (component.types.includes('street_number')) {
        address.state = component.long_name;
      } else if (component.types.includes('locality')) {
        address.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        address.state = component.short_name;
      } else if (component.types.includes('country')) {
        address.country = component.long_name;
      } else if (component.types.includes('administrative_area_level_2')) {
        address.county = component.long_name;
      } else if (component.types.includes('postal_code')) {
        address.zipcode = component.long_name;
      }
    });

    return address;
  }
}

export interface GeoPoint {
  latitude?: number;
  longitude?: number;
}

export interface LocationAddress {
  addressLine1: string,
  streetNumber: string,
  city: string;
  state: string;
  country: string;
  county: string;
  zipcode: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
}

<mat-dialog-content>
    <div class="flex flex-col gap-2 text-start">
      <p class="text-body1 text-error">Delete Address</p>
      <p class="text-body2">Are you sure, do you want to delete this address?</p>
      <div class="flex justify-end gap-4 mt-3">
        <app-button appearance="outline" (click)="onNoClicked()">Cancel
        </app-button>
        <app-button buttonColor="bg-error" [loading]="state.loading()"
                    (click)="onYesClicked()">Delete
        </app-button>
      </div>
    </div>
</mat-dialog-content>

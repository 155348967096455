<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6 flex justify-center">
  <form [formGroup]="waitListForm" class="flex flex-col gap-3">
    <div class="flex gap-4 bg-white rounded shadow-1 overflow-hidden">
      <app-network-image class="h-auto w-32 object-cover"
                         [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
      </app-network-image>
      <div class="flex flex-col gap-2 py-4">
        <p class="text-body2 text-primary-500">{{ restaurantDetailState.response()?.data?.restaurantName }}</p>
        <p class="text-body2 text-neutral-400">
          {{
            arrayToCSV([restaurantDetailState.response()?.data?.location?.address1,
              restaurantDetailState.response()?.data?.location?.city,
              restaurantDetailState.response()?.data?.location?.state])
          }}
        </p>
      </div>
    </div>

    <app-input-background [showHoverEffect]="false">
      <app-counter-input formControlName="partySize" title="Select Party"
                         (valueChanged)="onPartSizeChanged($event)"></app-counter-input>
    </app-input-background>

    <app-base-input title="Wait time">
      <div input>
        @if (waitTimesState.success()) {
          <p class="text-body2 font-bold text-primary-500">{{ waitTimesState.response()?.data?.tableWaitTime ?? 'N/A' }}
            minutes</p>
        }
        @if (waitTimesState.loading()) {
          <app-shimmer class="w-full" type="multiline"></app-shimmer>
        }
      </div>
    </app-base-input>

    <app-single-selection-field formControlName="occasion" [title]="'Occasion'"
                                [items]="occasionsState.response()?.data ?? []"
                                [display]="'name'"
                                [dynamicIconPath]="'icon'"
                                [dynamicIconColor]="'iconColor'"></app-single-selection-field>
    <app-single-selection-field formControlName="seatingPreferences" [title]="'Seating Preferences'"
                                [items]="seatingPreferenceState.response()?.data ?? []"
                                [display]="'name'"
                                [dynamicIconPath]="'icon'"
                                [dynamicIconColor]="'iconColor'"></app-single-selection-field>
    <app-multi-selection-field formControlName="tags" [title]="'Tags'" [items]="tagsState.response()?.data ?? []"
                               [display]="'name'"
                               [dynamicIconPath]="'icon'"
                               [dynamicIconColor]="'iconColor'"></app-multi-selection-field>
    <app-text-input formControlName="note" class="mt-2" [label]="'Special notes'" viewType="text"></app-text-input>
    <p class="text-body2 text-black">Cancellation Policy</p>
    <p class="text-body2">While you won’t be charge if you need cancel, we ask that you notify us at least 24 hours in
      advance</p>
    <p class="text-body2">By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and
      Cookie Policy</p>
    <app-button type="submit" class="w-full" [fullWidth]="true" [loading]="joinWaitListState.loading()"
                (click)="onJoinWaitListClicked()">Join Waitlist
    </app-button>
  </form>
</div>

import { Component, input } from '@angular/core';
import { AppSvgIconComponent } from "../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
    selector: 'app-rating-count',
    standalone: true,
    templateUrl: './rating-count.component.html',
    styleUrl: './rating-count.component.scss',
    imports: [AppSvgIconComponent]
})
export class RatingCountComponent {

  rating = input(0);
  count = input(0);

}

<app-base-dialog [title]="'Booking Survey'">

  <div body>
    <form [formGroup]="surveyForm">
      <div class="flex flex-col gap-2 text-center justify-center items-center">
        <div>
          <p class="text-body2 text-neutral-900">{{ bookingSurveyQuestionsResponseState.response()?.data?.description }}</p>
        </div>
      </div>
      <div formArrayName="surveyAnswers">
          <div class="flex flex-col py-10 items-center gap-3">
            @for (surveyQuestion of bookingSurveyQuestionsResponseState.response()?.data?.surveyQuestions; track surveyQuestion; let index = $index) {
              <ng-container [formGroupName]="index">
                <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
                  <div class="col-span-2 flex items-center gap-3">
                    <app-svg-icon src="assets/icons/question-mark-circle.svg" [tooltip]="surveyQuestion.surveyQuestion" [size]="20"></app-svg-icon>
                    <p class="text-body2"> {{ surveyQuestion.surveyName }} </p>
                  </div>
                  <div class="col-span-2">
                    <app-rating-input formControlName="surveyRating"></app-rating-input>
                  </div>
                </div>
              </ng-container>
            }
          </div>
      </div>

      <app-text-area-input class="w-full" formControlName="comments" label="Review" [fullWidth]="true"
                           placeholder="Please write your feedback."></app-text-area-input>
    </form>
  </div>
  <div actions>
    <div class="flex gap-4 items-center justify-end">
      <app-button appearance="outline" (click)="onCancelClicked()">CANCEL</app-button>
      <app-button (click)="onSubmitPressed()" [loading]="createSurveyState.loading()">SUBMIT SURVEY</app-button>
    </div>
  </div>
</app-base-dialog>








import {Component, computed, inject, input, Input, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BookingTypeComponent} from "../../../../booking/presentation/components/booking-type/booking-type.component";
import {DatePipe, NgClass} from "@angular/common";
import {DirectionButtonComponent} from "../../../../../core/components/direction-button/direction-button.component";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {arrayToCSV, arrayToSpaceSeparator} from "../../../../../shared/utils/string-utils";
import {
  getBookingStatusBgColor,
  getBookingStatusText,
  getBookingStatusTextColor
} from "../../../../booking/utilities/booking-status-utils";
import {InvitationsService} from "../../../data/invitations.service";
import {State} from "../../../../../shared/base/base-state";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {Constants} from "../../../../../core/constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {AcceptInvitationRequest} from "../../../data/accept-invitation/accept-invitation-request";
import {DeclineInvitationRequest} from "../../../data/decline-invitation/decline-invitation-request";
import {ReceivedInvitationResponseData} from "../../../data/invitations/received-invitation-response";
import {
  DeclineInvitationOverlayComponent, InvitationDeclineAction
} from "../../overlay/decline-invitation-overlay/decline-invitation-overlay.component";

@Component({
  selector: 'app-received-invitation-card',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BookingTypeComponent,
    DatePipe,
    DirectionButtonComponent,
    NetworkImageComponent,
    NgClass
  ],
  templateUrl: './received-invitation-card.component.html',
  styleUrl: './received-invitation-card.component.scss'
})
export class ReceivedInvitationCardComponent extends BaseComponent implements OnInit {

  isSentInvitation = input(false);
  isReceivedInvitation = input(false);

  invitationsService = inject(InvitationsService);
  authStoreService = inject(AuthStoreService);
  private dialog = inject(MatDialog);


  invitation = input<ReceivedInvitationResponseData>();

  restaurantCoordinates = computed(() => {
    return {
      latitude: this.invitation()?.restaurant.location?.location?.coordinates[1],
      longitude: this.invitation()?.restaurant.location?.location?.coordinates[0],
    };
  })


  ngOnInit(): void {

  }

  protected readonly arrayToCSV = arrayToCSV;

  getBookingColor(status: string) {
    return `${getBookingStatusTextColor(status)} ${getBookingStatusBgColor(status)}`;
  }

  protected readonly getBookingStatusText = getBookingStatusText;
  protected readonly arrayToSpaceSeparator = arrayToSpaceSeparator;

  onAcceptInvitationClicked() {
    const appUser = this.authStoreService.getAppUser();
    const request: AcceptInvitationRequest = {
      bookingId: this.invitation()?._id,
      inviteId: this.invitation()?.receivers[0]._id
    };

    this.executeRequest({
      state: new State<any>(),
      request: this.invitationsService.acceptInvite(request),
      showLoader: true,
      successMessage: 'Accepted invitation!',
      onSuccess: response => {

      }
    });
  }

  onDeclineInvitationClicked() {
    const dialogRef = this.dialog.open(DeclineInvitationOverlayComponent, {
      ...Constants.defaultDialogConfig,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == InvitationDeclineAction.declineInvitation) {
        const appUser = this.authStoreService.getAppUser();
        const request: DeclineInvitationRequest = {
          inviteId: this.invitation()?.receivers[0]._id,
          bookingId: this.invitation()?._id
        };
        this.executeRequest({
          state: new State<any>(),
          request: this.invitationsService.declineInvite(request),
          showLoader: true,
          successMessage: 'Declined invitation!',
          onSuccess: response => {
          }
        });
      }
    });
  }

  whoInvited() {
    const firstName = this.invitation()?.sender?.firstName;
    const lastName = this.invitation()?.sender?.lastName;
    const email = this.invitation()?.sender?.email;
    const phoneNumber = this.invitation()?.sender?.phoneNumber;
    if (firstName != null || lastName != null) {
      return arrayToSpaceSeparator([firstName, lastName]);
    }

    if (email != null) {
      return email;
    }

    if (phoneNumber != null) {
      return phoneNumber;
    }

    return 'Unknown Guest';
  }

  inviter() {
    const firstName = this.invitation()?.receivers[0].firstName;
    const lastName = this.invitation()?.receivers[0].lastName;
    const email = this.invitation()?.receivers[0].email;
    const phoneNumber = this.invitation()?.receivers[0].phoneNumber;
    if (firstName != null || lastName != null) {
      return arrayToSpaceSeparator([firstName, lastName]);
    }

    if (email != null) {
      return email;
    }

    if (phoneNumber != null) {
      return phoneNumber;
    }

    return 'Unknown Guest';
  }

}

<div class="container mx-auto py-10 bg-white sm:pt-16 md:pt-24">
  <div class="px-4 mx-auto sm:px-6 md:px-8">
    <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-y-12 gap-x-8 xl:gap-x-12">

      <div class="col-span-2 md:col-span-4 xl:pr-8 flex flex-col gap-2">
        <div class="flex items-center gap-3">
          <img class="w-auto h-9" src="assets/images/home/logo-white.png" alt="">
          <p class="text-h6 text-neutral-800">Smart Dining</p>
        </div>

        <p class="text-body2 text-neutral-500 mt-2">Save Big on Every Dining Experience with A
          Technology You Will Love</p>
      </div>


      <div class="lg:col-span-2">
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500">
          <p class="font-semibold text-neutral-900">Company</p>
          <div class="mt-6 gap-5 flex flex-col">
<!--             <app-footer-link>About</app-footer-link> -->
            <div routerLink="/contact-us">
              <app-footer-link>Contact</app-footer-link>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:col-span-2">
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500">
          <p class="font-semibold text-neutral-900">Help</p>
          <div class="mt-6 gap-5 flex flex-col">
            <app-footer-link>Customer Support</app-footer-link>
            <app-footer-link routeUrl="/terms-and-conditions">Terms & Conditions</app-footer-link>
            <div routerLink="/privacy-policy">
              <app-footer-link>Privacy Policy</app-footer-link>
            </div>
          </div>
        </div>
      </div>


      <div class="lg:col-span-2">
        <p class="font-semibold text-neutral-900">For Restaurants</p>
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500">
          <div class="mt-6 gap-5 flex flex-col">
            <app-footer-link [openInNewTab]="true" url="https://restaurant.gosmartdining.com/request-a-demo">Request a
              Demo
            </app-footer-link>
            <app-footer-link>Knowledge Base</app-footer-link>
          </div>
        </div>
      </div>

      <div class="lg:col-span-2">
        <p class="font-semibold text-neutral-900">Download</p>
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500 focus:text-primary-500">
          <div class="mt-6 gap-5 flex flex-col">
            <a [href]="Constants.userAppAndroidDownloadLink" target="_blank">
              <img src="assets/images/home/google.png" alt="" class="w-28">
            </a>
            <a [href]="Constants.userAppIOSDownloadLink" target="_blank">
              <img src="assets/images/home/apple.png" alt="" class="w-28">
            </a>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-16 mb-10 border-neutral-200"/>
    <div class="sm:flex sm:items-center sm:justify-between">
      <p class="text-subtitle2 text-neutral-600">© Copyright, All Rights Reserved by Smart Dining</p>

      <div class="flex items-center gap-5 pt-2">
        <a href="https://www.facebook.com/mysmartdining/" target="_blank">
          <app-svg-icon src="assets/images/home/facebook.svg" [size]="18" alt="facebook"/>
        </a>
        <a href="https://www.instagram.com/gosmartdining/" target="_blank">
          <app-svg-icon src="assets/images/home/instagram.svg" [size]="18" alt="instagram"/>
        </a>
        <a href="https://twitter.com/smartdiningapp/" target="_blank">
          <app-svg-icon src="assets/images/home/twitter.svg" [size]="18" alt="twitter"/>
        </a>
        <a href="https://www.youtube.com/@gosmartdining" target="_blank">
          <app-svg-icon src="assets/images/home/youtube.svg" [size]="18" alt="youtube"/>
        </a>
        <!--        <a href="https://www.tiktok.com/@smartdining" target="_blank">-->
        <!--          <app-svg-icon src="assets/images/home/tiktok.svg" [size]="18" alt="tiktok"/>-->
        <!--        </a>-->
      </div>
    </div>
  </div>
</div>

import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";

import {Constants} from "../../../../../core/constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  AddPaymentMethodComponent,
  AddPaymentMethodStatus
} from "../../overlays/add-payment-method/add-payment-method.component";
import {CreditCardImageUtils} from "../../../utilites/credit-card-image-utils";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {NgClass} from "@angular/common";
import {SavedCardsRequest} from "../../../data/saved-cards/saved-cards-request";
import {SavedCard, SavedCardsResponse} from "../../../data/saved-cards/saved-cards-response";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {State} from "../../../../../shared/base/base-state";
import {PaymentService} from "../../../data/payment.service";

import {DeletePaymentComponent} from "../../overlays/delete-payment/delete-payment.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {GenericResponse} from "../../../../../core/models/generic-response";

@Component({
  selector: 'app-payment-methods',
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    NgClass,
    ShimmerComponent
  ],
  templateUrl: './saved-payment-methods.component.html',
  styleUrl: './saved-payment-methods.component.scss'
})
export class SavedPaymentMethodsComponent extends BaseComponent implements OnInit {
  dialog = inject(MatDialog);
  authStoreService = inject(AuthStoreService);
  paymentService = inject(PaymentService);

  savedCardsState = new State<SavedCardsResponse>();
  defaultCardState = new State<GenericResponse>();

  protected readonly CreditCardImageUtils = CreditCardImageUtils;

  ngOnInit() {
    this.getSavedCards();
  }

  onAddPaymentClicked() {
    const dialogRef = this.dialog.open(AddPaymentMethodComponent, {
      ...Constants.defaultDialogConfig
    });
  }

  private getSavedCards() {
    let request: SavedCardsRequest = {
      userId: this.authStoreService.getAppUser()?.data?.user
    };
    this.executeRequest<SavedCardsResponse>({
      state: this.savedCardsState,
      request: this.paymentService.getSavedCards(request),
    })
  }

  onDeleteClicked(card: SavedCard) {
    const dialogRef = this.dialog.open(DeletePaymentComponent, {
      ...Constants.defaultDialogConfig,
      data: card.id,
    });
    dialogRef.afterClosed().subscribe((result: AddPaymentMethodStatus) => {
      if (result == AddPaymentMethodStatus.success) {
        this.getSavedCards();
      }
    });
  }

  onSetAsDefaultClicked(card: SavedCard) {
    this.executeRequest({
      state: this.defaultCardState,
      request: this.paymentService.setDefaultPaymentMethod(card.id),
      successMessage: 'Card successfully set as default',
      handleSuccess: true,
      onSuccess: response => {
        this.getSavedCards();
      }
    });
  }
}

import {Component, EventEmitter, input, Output, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-location-selector',
  standalone: true,
    imports: [
        AppSvgIconComponent
    ],
  templateUrl: './location-selector.component.html',
  styleUrl: './location-selector.component.scss'
})
export class LocationSelectorComponent {

  location = input('');

  @Output()
  clickEvent = new EventEmitter<void>();

  onLocationSelectorClick() {
    this.clickEvent.emit();
  }
}

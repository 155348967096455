import {Component, input} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-input-background',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './input-background.component.html',
  styleUrl: './input-background.component.scss'
})
export class InputBackgroundComponent {
  showHoverEffect = input(true);
}

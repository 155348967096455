<form [formGroup]="form" class="flex flex-col gap-4">
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
    <app-text-input formControlName="firstName" type="text" placeholder="First Name"
                    label="First Name"></app-text-input>
    <app-text-input formControlName="lastName" type="text" placeholder="Last Name" label="Last Name"></app-text-input>
    <app-text-input formControlName="email" type="email" placeholder="you@company.com" label="Email"></app-text-input>
    <app-text-input formControlName="phoneNumber" placeholder="Phone Number" label="Phone Number"></app-text-input>
    <app-date-picker formControlName="birthday" [maxDate]="dobMaxDate" label="Birthday"
                     placeholder="Birthday"></app-date-picker>
  </div>
  <div class="flex flex-col items-center sm:items-end">
    <app-button (click)="onUpdateButtonClicked()">Update</app-button>
  </div>
</form>

import {Component, inject, OnInit} from '@angular/core';


import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {OrdersTabComponent} from "../../components/orders-tab/orders-tab.component";





import {BaseComponent} from "../../../../../shared/base/base-component";
import {OrdersCardComponent} from "../../components/orders-card/orders-card.component";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {State} from "../../../../../shared/base/base-state";
import {ActiveOrdersRequest} from "../../../data/active-orders/active-orders-request";
import {
  ActiveOrdersResponse,
  UpcomingOrdersResponseData
} from "../../../data/active-orders/active-orders-response";

@Component({
  selector: 'app-active-orders',
  standalone: true,
  imports: [
    NoDataComponent,
    ShimmerComponent,
    OrdersTabComponent,
    OrdersCardComponent
],
  templateUrl: './active-orders.component.html',
  styleUrl: './active-orders.component.scss'
})
export class ActiveOrdersComponent extends BaseComponent implements OnInit {
  pickupOrderService = inject(PickupOrderService);

  orderState = new State<ActiveOrdersResponse>();


  ngOnInit() {
    this.getUpcomingOrders();
  }

  getUpcomingOrders() {
    const request: ActiveOrdersRequest = {
      phoneNumber: "+919489792349",
      email: "nandhakumargdr@gmail.com"
    };
    this.executeRequest({
      state: this.orderState,
      request: this.pickupOrderService.getUpcomingOrders(request)
    });
  }

  onCompletedOrdersClicked(order: UpcomingOrdersResponseData) {
    this.router.navigate([`/order/${order._id}`])
  }
}

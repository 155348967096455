<div class="container-fluid mx-auto bg-amber-50 py-20" style="background-image: url('assets/images/home/bg-line.svg')">
  <div class="container mx-auto px-8 sm:px-16 py-8">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 items-center">
      <img src="assets/images/home/feature-1-group.png" alt="">
      <div class="flex justify-center items-center">
        <div class="flex flex-col gap-5">
          <p class="text-h6 font-bold text-neutral-500">Easy and Fast</p>
          <p class="text-h4 text-neutral-800 font-bold">Book your next Table in 3 easy steps</p>

          <div class="flex gap-5">
            <img src="assets/images/home/calendar.svg" alt="" class="w-15">
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500 font-bold">Reserve a table</p>
              <p class="text-body1 text-neutral-500">Reserves a table for X guests at your favorite local restaurants.</p>
            </div>
          </div>
          <div class="flex gap-5">
            <img src="assets/images/home/pre-order.svg" alt="" class="w-15">
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500 font-bold">Pre-Order your meals</p>
              <p class="text-body1 text-neutral-500">You and your guests can pre-order online before reservation date and
                time.</p>
            </div>
          </div>
          <div class="flex gap-5">
            <img src="assets/images/home/guest.svg" alt="" class="w-15">
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500 font-bold">Invite guests</p>
              <p class="text-body1 text-neutral-500">Easily invite your guests to join the table reservation through the
                SMART DINING mobile app or web app.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, OnDestroy, OnInit, signal} from '@angular/core';
import {
  SettingMenuItemComponent
} from "../components/setting-menu-item/setting-menu-item.component";
import {NavigationEnd, RouterLink, RouterOutlet} from "@angular/router";
import {TopNavComponent} from "../../../layout/top-nav/top-nav.component";
import {FooterComponent} from "../../../layout/footer/footer.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";

import {MatTabLink, MatTabNav, MatTabNavPanel} from "@angular/material/tabs";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    SettingMenuItemComponent,
    RouterOutlet,
    TopNavComponent,
    FooterComponent,
    RouterLink,
    MatTabNavPanel,
    MatTabNav,
    MatTabLink
],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  activeRoute = signal(this.router.url);
  private routeSubscription!: Subscription;
  lotsOfTabs = new Array(30).fill(0).map((_, index) => `Tab ${index}`);

  ngOnInit(): void {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.activeRoute.set(this.router.url);
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }


}

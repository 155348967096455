import {Component, computed, input, signal} from '@angular/core';


@Component({
  selector: 'app-network-image',
  standalone: true,
  imports: [],
  templateUrl: './network-image.component.html',
  styleUrl: './network-image.component.scss'
})
export class NetworkImageComponent {

  placeholderImage = input<string | undefined>();
  imageUrl = input<string | undefined>();

  placeHolderUrl = computed(() => {
    return this.placeholderImage() ?? 'assets/images/background/restaurant-placeholder.png';
  })

  sourceUrl = computed(() => {
    let url = this.imageUrl() ?? this.placeholderImage() ?? 'assets/images/background/restaurant-placeholder.png';
    return this.imageUrl() ?? this.placeholderImage() ?? 'assets/images/background/restaurant-placeholder.png';
  })

  isError = signal(false);

  handleImageError() {
    this.isError.update((c) => true);
  }
}

import {Component, inject, OnInit, signal} from '@angular/core';
import {RestaurantsFilterService} from "../../../data/restaurants-filter.service";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  DateSelectorInputComponent
} from "../../../../../shared/inputs/date-selector-input/date-selector-input.component";
import {
  SingleItemPickerInputComponent
} from "../../../../../shared/inputs/single-item-picker-input/single-item-picker-input.component";
import {isToday} from "date-fns";
import {generateTimeList, roundToNextFifteenMinutes, TimeObject} from "../../../../../shared/utils/date-utils";

@Component({
  selector: 'app-pickup-order-restaurant-details-global-filter',
  standalone: true,
  imports: [
    FormsModule,
    DateSelectorInputComponent,
    ReactiveFormsModule,
    SingleItemPickerInputComponent
  ],
  templateUrl: './pickup-order-global-filter.component.html',
  styleUrl: './pickup-order-global-filter.component.scss'
})
export class PickupOrderGlobalFilterComponent implements OnInit {

  fb = inject(FormBuilder);
  restaurantsFilterService = inject(RestaurantsFilterService);

  dineInOption = this.restaurantsFilterService.dineInOption;
  currentDate!: Date;

  timeForm!: FormGroup;

  protected readonly DineInOption = DineInOption;

  timeSlots = signal<TimeObject[]>([]);

  ngOnInit(): void {
    this.initPickupOrderFilterForm();
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);
    this.updateTimeSlot();
  }

  initPickupOrderFilterForm() {
    this.timeForm = this.fb.group({
      time: ['']
    });
  }

  private updateTimeSlot() {
    if(isToday(this.restaurantsFilterService.selectedDate())) {
      let times = generateTimeList(roundToNextFifteenMinutes(new Date()), null);
      this.timeSlots.set(times.map(x => x));
    } else {
      let times = generateTimeList(this.restaurantsFilterService.selectedDate(), null);
      this.timeSlots.set(times.map(x => x));
    }
  }

  generateTimeSlotsForDate(date: Date): TimeForView[] {
    const slots: TimeForView[] = [];

    let startDate = new Date(date.setHours(0,0,0,0)); // Start of the day
    const endDate = new Date(date.setHours(23, 45, 0, 0)); // Set to 11:45 PM

    if(isToday(date)) {
      startDate = new Date();
    }

    // Check if the given date is today and add "NOW" at the beginning if true
    if (isToday(startDate)) {
      slots.push({ formattedTime: 'NOW', dateTime: new Date() });
    }

    for (let dt = new Date(startDate); dt <= endDate; dt.setMinutes(dt.getMinutes() + 15)) {
      const hh = dt.getHours().toString().padStart(2, '0');
      const mm = dt.getMinutes().toString().padStart(2, '0');
      let timeForView: TimeForView = {
        formattedTime: `${hh}:${mm}`,
        dateTime: new Date(dt)
      };
      slots.push(timeForView);
    }

    return slots;
  }

  onDateSelected(event: Date) {
    const selectedDate = new Date(event);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate >= this.currentDate) {
      this.restaurantsFilterService.setDateFilter(event);
    }
    this.updateTimeSlot();
  }

  onTimeChanged($event: any) {
    this.restaurantsFilterService.setTimeFilter($event);
  }
}

export interface TimeForView {
  formattedTime: string;
  dateTime: Date;
}

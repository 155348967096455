import { Injectable } from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {MakeReservationRequest} from "./make-reservation/make-reservation-request";
import {URLs} from "../../../core/base/urls";
import {MakeReservationResponse} from "./make-reservation/make-reservation-response";
import {TableAvailabilitiesRequest} from "./table-availabilities/table-availabilities-request";
import {TableAvailabilitiesResponse} from "./table-availabilities/table-availabilities-response";
import {WaitTimesRequest} from "./wait-times/wait-times-request";
import {WaitTimesResponse} from "./wait-times/wait-times-response";
import {JoinWaitListRequest} from "./join-wait-list/join-wait-list-request";
import {GenericResponse} from "../../../core/models/generic-response";
import {RestaurantSurveyRequest} from "./bookings/survey/restaurant-survey-request";
import {SubmitCustomerSurveyRequest} from "./bookings/survey/submit-customer-survey-request";
import {
  GetBookingSurveyQuestionsResponse
} from "../../../core/services/common-data/get-survey-questions/get-booking-survey-questions-response";
import {BookingsRequest} from "./bookings/common/bookings-request";
import {BookingsResponse} from "./bookings/bookings/bookings-response";

@Injectable({
  providedIn: 'root'
})
export class BookingsService extends BaseApiService {

  getTableAvailabilities(request: TableAvailabilitiesRequest) {
    return this.post<TableAvailabilitiesResponse>(URLs.getTimeAvailabilities, request);
  }

  getWaitTimes(request: WaitTimesRequest) {
    return this.post<WaitTimesResponse>(URLs.getTableWaitTimes, request);
  }

  makeReservation(request: MakeReservationRequest) {
    return this.post<MakeReservationResponse>(URLs.makeReservation, request);
  }

  joinWaitList(request: JoinWaitListRequest) {
    return this.post<GenericResponse>(URLs.joinWaitList, request);
  }
  getPastReservation(request: BookingsRequest) {
    return this.get<BookingsResponse>(URLs.getPastReservation, request);
  }


  getBookingSurveyQuestions() {
    return this.get<GetBookingSurveyQuestionsResponse>(URLs.getBookingSurveyQuestions)
  }

  submitCustomerSurvey(request: SubmitCustomerSurveyRequest) {
    return this.post<GenericResponse>(URLs.submitCustomerSurvey, request);
  }
}

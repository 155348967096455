<div class="container mx-auto p-4">
  <div class="relative">
    <input type="text"
           [formControl]="searchControl"
           [matAutocomplete]="auto"
           class="w-full pl-4 pr-10 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500
           focus:border-transparent"
           placeholder="Search for Restaurant...">
    <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
      <app-svg-icon src="assets/icons/search-icon.svg" class="text-neutral-400" [size]="20"></app-svg-icon>
    </div>
  </div>
  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="onRestaurantSelected($event.option.value)">
    @if (filteredRestaurants().length > 0) {
      @for (restaurant of filteredRestaurants(); track restaurant) {
        <mat-option [value]="restaurant" class="p-2">
          <div class="flex items-center space-x-2">
            @if (restaurant.logo.url) {
              <img [src]="restaurant.logo.url"
                   class="w-10 h-10 object-cover rounded-full"
                   alt="{{ restaurant.restaurantName }}">
            }
            <span class="text-body2">{{ restaurant.restaurantName }}</span>
          </div>
        </mat-option>
      }
    } @else {
      <mat-option disabled class="p-2 text-neutral-500">No restaurants found</mat-option>
    }
  </mat-autocomplete>
</div>

import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MakeReservationResponse} from "../../../data/make-reservation/make-reservation-response";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {InvitationsService} from "../../../../invitations/data/invitations.service";
import {State} from "../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {
  BookingInvitationsResponse
} from "../../../../invitations/data/booking-invitations/booking-invitations-response";
import {
  ReservationInviteGuestRequest
} from "../../../../invitations/data/reservation-invite-guest/reservation-invite-guest-request";
import {BaseComponent} from "../../../../../shared/base/base-component";

@Component({
  selector: 'app-waitlist-confirmation-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    AppSvgIconComponent,
    ReactiveFormsModule,
    ButtonComponent
  ],
  templateUrl: './waitlist-confirmation-overlay.component.html',
  styleUrl: './waitlist-confirmation-overlay.component.scss'
})
export class WaitlistConfirmationOverlayComponent extends BaseComponent implements OnInit {
  data: MakeReservationResponse = inject(MAT_DIALOG_DATA);
  formBuilder = inject(FormBuilder);
  invitationService = inject(InvitationsService);

  inviteForm!: FormGroup;
  sendInvitationState = new State<GenericResponse>();
  invitedGuestsState = new State<BookingInvitationsResponse>();
  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      emailOrPhone: ['', Validators.required]
    });
  }

  onInvitePressed() {
    if(this.inviteForm.invalid) {
      return;
    }
    const emailOrPhone = this.inviteForm.value.emailOrPhone;
    const reservationDetails = this.data.data;
    const request: ReservationInviteGuestRequest = {
      restaurantId: reservationDetails.restaurant,
      bookingId: reservationDetails._id,
      email: this.isValidEmail(emailOrPhone) ? emailOrPhone : null,
      phoneNumber: !this.isValidEmail(emailOrPhone) ? emailOrPhone : null,
      isUser: false
    };

    this.executeRequest<GenericResponse>({
      state: this.sendInvitationState,
      request: this.invitationService.inviteGuest(request),
      handleSuccess: true,
      successMessage: 'Guest invited successfully!',
      onSuccess: response => {
        this.getInvitedGuests();
      }
    });
  }

  getInvitedGuests() {
    const bookingDetails = this.data.data;
    this.executeRequest({
      state: this.invitedGuestsState,
      request: this.invitationService.getBookingInvitations(bookingDetails._id),
    });
  }

  isValidEmail(email: string): boolean {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

}

import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {ForgotPasswordResponse} from "../../../data/forgot-password/forgot-password-response";
import {AuthService} from "../../../data/auth.service";
import {State} from "../../../../../shared/base/base-state";
import {Subscription} from "rxjs";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    TextInputComponent
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {

  form!: FormGroup;

  state = new State<ForgotPasswordResponse>();

  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  verifyEmailClicked() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<ForgotPasswordResponse>({
      state: this.state,
      request: this.authService.forgotPassword(this.form.value),
      handleSuccess: true,
      onSuccess: response => {
        this.router.navigate(['/reset-password'], {queryParams: {email: this.form.value.email}});
      }
    });
  }
}

<button
  (click)="onClick()"
  [type]="type()"
  class="relative whitespace-nowrap"
  [ngClass]="getButtonClass()">

  @if (iconSrc()) {
    <app-svg-icon [src]="iconSrc()!" [size]="20" class="mr-3"
                  [ngClass]="loading() ? 'text-transparent' : getIconClass()"></app-svg-icon>
  }
  @if (loading()) {
    <app-spinner class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                 [borderColor]="getLoaderColor()"></app-spinner>
  }
  <div [ngClass]="loading() ? 'text-transparent' : 'text-body2'">
    <ng-content></ng-content>
  </div>

</button>

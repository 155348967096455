<div class="flex flex-col h-full px-4 sm:px-0 bg-sky-100">
  <div class="grow relative mx-1 sm:mx-0 py-24 md:py-44 lg:py-52 flex justify-center items-center">

    <img class="hidden md:block absolute top-0 right-0" src="assets/images/home/food-1.png" alt="food-1" width="30%">
    <img class="hidden md:block absolute bottom-0 right-0" src="assets/images/home/food-2.png" alt="food-2" width="30%">
    <img class="hidden md:block absolute bottom-0 left-0" src="assets/images/home/food-3.png" alt="food-3" width="18%">

    <div class="flex flex-col items-center gap-4 mx-10 md:mx-20 lg:mx-80 relative">

      <p class="text-center text-h4 md:text-h3 max-w-3xl">Discover the best restaurants, cafes, and bars in your
        area</p>
      <p class="text-neutral-500 text-body1 text-center max-w-3xl">Smart Dining makes it easy to find the best
        restaurants
        for any
        occasion, book tables
        or order online with a click of a button, and take advantage of exclusive deals.</p>

      <div class="flex items-center rounded-full w-full bg-white px-3">
        <app-svg-icon src="assets/icons/location.svg" class="text-orange-500 px-3"></app-svg-icon>
        <input #addressInputField type="text" name="" placeholder="Type address or zipcode"
               class="block border-transparent focus:outline-0 focus:border-transparent focus:ring-0 flex-grow py-6 pr-6 placeholder-neutral-500 bg-white rounded-full sm:py-5"/>

        <div class="flex right-1.5 inset-y-1.5 items-center gap-3">
          <div class="flex items-center justify-center gap-2 cursor-pointer" (click)="onGpsClicked()">
            <app-svg-icon src="assets/icons/gps.svg" class="text-neutral-400" [size]="16"></app-svg-icon>
            <p class="hidden md:inline text-neutral-400">Locate</p>
          </div>

          <p (click)="onFindRestaurantsClicked()"
             class="hidden md:inline-flex uppercase py-4 px-4 text-caption text-white text-center rounded-full bg-primary-500 cursor-pointer">
            Find restaurants</p>

          <div class="md:hidden flex justify-center items-center rounded-full p-3 mx-2 bg-primary-500">
            <app-svg-icon src="assets/icons/search-icon.svg" class="text-white cursor-pointer" [size]="18"
                          (click)="onFindRestaurantsClicked()"></app-svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-5 md:flex-row items-center justify-between py-5 bg-white">
    <img src="assets/icons/deco-left.svg" alt="">
    <p class="text-base md:text-base lg:text-lg">Your local favorite restaurants are on Smart Dining</p>
    <img src="assets/images/home/stuff.png" alt="">
    <img src="assets/images/home/austin.png" alt="">
    <img src="assets/icons/deco-left.svg" alt="">
  </div>
</div>



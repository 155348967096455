<div class="flex flex-col">
  <p class="text-body2 text-primary-700">Security</p>
  <div class="flex flex-col py-4 text-body2 gap-2 cursor-pointer" (click)="onChangePasswordClicked()">
    <p class="text-neutral-800 cursor-pointer">Change password</p>
    <p class="text-neutral-400">Update your current password</p>
  </div>
  <hr>
  <div class="flex flex-col py-4 text-body2 gap-2 cursor-pointer" (click)="onEnable2FAClicked()">
    <div class="flex items-center justify-between">
      <p class="text-neutral-800">Enable 2FA</p>
      @if (isMfaEnabled()) {
        <span class="px-2 py-1 text-white rounded-full bg-green-500">Enabled</span>
      } @else {
        <span class="px-2 py-1 text-white rounded-full bg-red-500">Disabled</span>
      }
    </div>
    <p class="text-neutral-400">Enable two factor authentication</p>
  </div>
  <hr>
</div>

<app-base-dialog [title]="'Write Review'">

  <div body>
    <form [formGroup]="reviewForm">

      <div class="flex flex-col gap-2 text-center justify-center items-center">
        <div>
          <p class="text-body2 text-neutral-900">Would you please tell us about your experience with this restaurant</p>
          <div class="flex py-3 justify-center">
            <app-rating-input formControlName="recentBooking" appearance="outline"></app-rating-input>
          </div>
        </div>
      </div>
      <div class="flex flex-col py-10 items-center gap-3">
        <div class="grid grid-cols-4  border-t border-b border-neutral-100 py-3">
          <div class="col-span-2 flex items-center gap-3">
            <app-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></app-svg-icon>
            <p class="text-body2 text-neutral-500">Atmosphere</p>
          </div>
          <div class="col-span-2">
            <app-rating-input formControlName="atmosphereRating"></app-rating-input>
          </div>
        </div>
        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <app-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></app-svg-icon>
            <p class="text-body2 text-neutral-500">Food</p>
          </div>
          <div class="col-span-2">
            <app-rating-input formControlName="foodRating"></app-rating-input>
          </div>
        </div>
        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <app-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></app-svg-icon>
            <p class="text-body2 text-neutral-500">Quality</p>
          </div>
          <div class="col-span-2">
            <app-rating-input formControlName="qualityRating"></app-rating-input>
          </div>
        </div>
        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <app-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></app-svg-icon>
            <p class="text-body2 text-neutral-500">Wait time</p>
          </div>
          <div class="col-span-2">
            <app-rating-input formControlName="waitTimeRating"></app-rating-input>
          </div>
        </div>
        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <app-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></app-svg-icon>
            <p class="text-body2 text-neutral-500">Service</p>
          </div>
          <div class="col-span-2">
            <app-rating-input formControlName="serviceRating"></app-rating-input>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <p class="text-neutral-600 text-body2">Feedback</p>
        <app-text-area-input formControlName="feedback" class="w-full" label="Review" [fullWidth]="true"
                             placeholder="Please write your feedback."></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex gap-4 items-center justify-end">
      <app-button appearance="outline" (click)="onCancelClicked()">CANCEL</app-button>
      <app-button (click)="onSubmitPressed()" [loading]="writeReviewState.loading()">SUBMIT REVIEW</app-button>
    </div>
  </div>
</app-base-dialog>








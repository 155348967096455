import {Component, inject, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {MatDatepicker, MatDatepickerInput} from "@angular/material/datepicker";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {ClickOutsideDirective} from "../../../../../shared/directives/click-outside.directive";
import {Constants} from "../../../../../core/constants/constants";
import {generateTimeList, TimeObject} from "../../../../../shared/utils/date-utils";
import {DatePipe, NgClass} from "@angular/common";
import {RestaurantsFilterService} from "../../../data/restaurants-filter.service";
import {
  DateSelectorInputComponent
} from "../../../../../shared/inputs/date-selector-input/date-selector-input.component";
import {DatePickerComponent} from "../../../../../shared/inputs/date-picker/date-picker.component";
import { FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {
  SingleItemPickerInputComponent
} from "../../../../../shared/inputs/single-item-picker-input/single-item-picker-input.component";


@Component({
  selector: ' app-dine-in-global-filter',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    MatDatepicker,
    MatFormField,
    MatDatepickerInput,
    MatInput,
    MatButton,
    ClickOutsideDirective,
    DatePipe,
    DateSelectorInputComponent,
    DatePickerComponent,
    FormsModule,
    DropdownComponent,
    ReactiveFormsModule,
    SingleItemPickerInputComponent,
    NgClass
  ],
  templateUrl: './dine-in-global-filter.component.html',
  styleUrl: './dine-in-global-filter.component.scss'
})
export class DineInGlobalFilterComponent implements OnInit {

  restaurantsFilterService = inject(RestaurantsFilterService);

  currentDate: Date;

  constructor() {
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);
  }


  isTimeOpen = signal(false);
  availableTimes = signal(generateTimeList(null, null));

  isPartySizeOpen = signal(false);
  partySizeList = signal(Constants.partySizeList);
  dineInOption = this.restaurantsFilterService.dineInOption;

  ngOnInit(): void {

  }


  toggleTimePopup() {
    this.isTimeOpen.update(c => !c);
  }

  closeTimePopup() {
    this.isTimeOpen.update(c => false);
  }

  togglePartySizePopup() {
    this.isPartySizeOpen.update(c => !c);
  }

  clickOutsidePartySizePopup() {
    this.isPartySizeOpen.update(c => false);
  }

  onDateSelected(event: Date) {
    const selectedDate = new Date(event);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate >= this.currentDate) {
      this.restaurantsFilterService.setDateFilter(event);
    }
  }

  onTimeSelected(time: TimeObject) {
    this.restaurantsFilterService.setTimeFilter(time);
    this.isTimeOpen.update(c => !c);
  }

  onPartySizeSelected(partySize: number) {
    this.isPartySizeOpen.update(c => false);
    this.restaurantsFilterService.setPartSizeFilter(partySize);
  }

  onPartySizeIncreased() {
    this.restaurantsFilterService.setPartSizeFilter(this.restaurantsFilterService.selectedPartySize() + 1);
  }

  onPartySizeDecreased() {
    this.restaurantsFilterService.setPartSizeFilter(this.restaurantsFilterService.selectedPartySize() - 1);
  }


  protected readonly DineInOption = DineInOption;
}













<app-base-dialog [title]="'Modify Booking'">
  <div body>
    <form [formGroup]="updateReservationForm" (ngSubmit)="onUpdateReservationClicked()"
          class="flex flex-col gap-3">
      <div class="flex flex-col">
        <div class="flex items-start gap-6">

          <app-date-selector-input formControlName="date" [title]="'Date'"
                                   (valueChanged)="onDateSelected($event)"></app-date-selector-input>

          <app-input-background [showHoverEffect]="false">
            <app-counter-input formControlName="partySize" title="Select Party"
                               (valueChanged)="onPartSizeChanged($event)"></app-counter-input>
          </app-input-background>
        </div>

        <app-single-selection-field formControlName="time"
                                    [title]="'Select Time'"
                                    [items]="availabilities()"
                                    [display]="'timeString'"
                                    [state]="tableAvailabilitiesState"
                                    noDataMessage="No tables available">
        </app-single-selection-field>


        <app-single-selection-field formControlName="occasion" [title]="'Occasion'"
                                    [items]="occasionsState.response()?.data ?? []"
                                    [display]="'name'"
                                    [dynamicIconPath]="'icon'"
                                    [dynamicIconColor]="'iconColor'"></app-single-selection-field>
        <app-single-selection-field formControlName="seatingPreferences" [title]="'Seating Preferences'"
                                    [items]="seatingPreferenceState.response()?.data ?? []"
                                    [display]="'name'"
                                    [dynamicIconPath]="'icon'"
                                    [dynamicIconColor]="'iconColor'"></app-single-selection-field>
        <app-single-selection-field formControlName="tags" [title]="'Tags'" [items]="tagsState.response()?.data ?? []"
                                    [display]="'name'"
                                    [dynamicIconPath]="'icon'"
                                    [dynamicIconColor]="'iconColor'"></app-single-selection-field>
        <app-text-input formControlName="note" class="mt-2" [label]="'Special notes'" viewType="text"></app-text-input>
        <p class="text-body2 text-black">Cancellation Policy</p>
        <p class="text-body2">While you won’t be charge if you need cancel, we ask that you notify us at least 24 hours
          in advance</p>
        <p class="text-body2">By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy,
          and Cookie Policy</p>

      </div>

    </form>
  </div>
  <div actions>
    <div class="flex gap-3 justify-end">
      <app-button type="submit" [loading]="updateReservationState.loading()" [fullWidth]="true"
                  (click)="onUpdateReservationClicked()">Update reservation
      </app-button>
    </div>
  </div>
</app-base-dialog>

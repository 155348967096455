import {inject, Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {HttpClient} from "@angular/common/http";
import {URLs} from "../../../core/base/urls";
import {ForgotPasswordResponse} from "./forgot-password/forgot-password-response";
import {AuthenticatedUserResponse} from "./authenticated-user/authenticated-user-response";
import {SendGuestConfirmCodeRequest} from "./send-guest-confirm-code/send-guest-confirm-code-request";
import {VerifyGuestConfirmationCode} from "./verify-guest-confirmation-code/verify-guest-confirmation-code";
import {GenericResponse} from "../../../core/models/generic-response";
import {AddressListResponse} from "../../settings/data/address-list/address-list-response";
import {ChangePasswordRequest} from "./change-password/change-password-request";
import {LoginRequest} from "./login/login-request";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  signUp(request: any) {
    return this.post(URLs.signUp, request);
  }

  activateAccount(request: any) {
    return this.post(URLs.activateAccount, request);
  }

  login(request: LoginRequest) {
    return this.post(URLs.login, request);
  }

  getRefreshToken() {
    return this.post(URLs.refreshToken, null);
  }

  forgotPassword(request: any) {
    return this.post<ForgotPasswordResponse>(URLs.forgotPassword, request);
  }

  resetPassword(request: any) {
    return this.post(URLs.resetPassword, request);
  }

  getAuthenticatedUser() {
    return this.post<AuthenticatedUserResponse>(URLs.getAuthenticatedUser, {});
  }

  sendGuestConfirmCode(request: SendGuestConfirmCodeRequest) {
    return this.post<GenericResponse>(URLs.sendGuestConfirmCode, request);
  }

  verifyGuestConfirmationCode(request: VerifyGuestConfirmationCode) {
    return this.post<GenericResponse>(URLs.verifyGuestCode, request);
  }

  sendConfirmCode(request: ChangePasswordRequest) {
    return this.post<AddressListResponse>(URLs.sendConfirmCode, request);
  }


}

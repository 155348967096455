import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  inject,
  OnInit,
  PLATFORM_ID,
  signal,
  ViewChild
} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {GoogleMap} from "@angular/google-maps";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import LatLngLiteral = google.maps.LatLngLiteral;
import {
  GeoPoint,
  LocationAddress,
  LocationService
} from "../../../../../core/services/location-service/location.service";
import {State} from "../../../../../shared/base/base-state";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Address} from "../../../../settings/data/address-list/address-list-response";
import {AppDataService} from "../../../../../core/services/app-data/app-data.service";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {isPlatformBrowser} from "@angular/common";
import {SavedAddress} from "../../../../../core/services/app-data/saved-address.model";

@Component({
  selector: 'app-select-address-overlay',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    GoogleMap,
    MatDialogActions,
    MatDialogContent
  ],
  templateUrl: './select-address-overlay.component.html',
  styleUrl: './select-address-overlay.component.scss'
})
export class SelectAddressOverlayComponent extends BaseComponent implements OnInit, AfterViewInit {

  formBuilder = inject(FormBuilder);
  data: Address = inject(MAT_DIALOG_DATA);
  appDataService = inject(AppDataService);
  platformId = inject(PLATFORM_ID);
  locationService = inject(LocationService);
  changeDetectorRef = inject(ChangeDetectorRef);
  dialogRef = inject(MatDialogRef);


  addressForm!: FormGroup;


  state = new State<GenericResponse>();

  fullAddress = signal('');

  @ViewChild('addressInputField') addressInputField?: ElementRef;

  showSelectedAddress: boolean = false;


  ngAfterViewInit(): void {
    this.initAddressAutoComplete();
  }

  ngOnInit(): void {
    this.initAddressForm();
  }

  initAddressForm() {
    if (this.data != null) {
      let latitude: number | null = null;
      let longitude: number | null = null;
      if (this.data.location.coordinates.length == 2) {
        latitude = this.data.location.coordinates[1];
        longitude = this.data.location.coordinates[0];
        this.center.set({
          lat: latitude,
          lng: longitude
        });
        this.updateLocationAddress(latitude!, longitude!);
      }
      this.addressForm = this.formBuilder.group({
        fullAddress: [this.data.address, [Validators.required]],
        addressNumber: [this.data.address],
        address: [this.data.address],
        city: [this.data.city],
        country: [this.data.country],
        state: [this.data.state],
        zipCode: [this.data.zipCode],
        latitude: [latitude],
        longitude: [longitude]
      });
    } else {
      this.addressForm = this.formBuilder.group({
        fullAddress: ['', [Validators.required]],
        addressNumber: [''],
        address: [''],
        city: [''],
        country: [''],
        state: [''],
        zipCode: [''],
        latitude: [],
        longitude: []
      });
      this.gotoCurrentLocation();
    }
  }

  @ViewChild(GoogleMap) googleMap!: GoogleMap;
  center = signal<LatLngLiteral>({lat: 0, lng: 0});
  zoom = signal(20);
  mapOptions = signal<google.maps.MapOptions>({
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false
  });

  gotoCurrentLocation() {
    this.executeRequest<GeoPoint>({
      state: new State<GeoPoint>(),
      request: this.locationService.getCurrentLocation(),
      showLoader: true,
      onSuccess: (response) => {
        this.googleMap.googleMap?.panTo({
          lat: response.latitude ?? 0,
          lng: response.longitude ?? 0
        });
        this.updateLocationAddress(response.latitude ?? 0, response.longitude ?? 0);
      }
    });
  }

  onMapPositionChanged() {
    const mapCenter = this.googleMap.googleMap?.getCenter();
    // console.log(`https://www.google.com/maps?q=${mapCenter!.lat()},${mapCenter?.lng()}&z=20`);
    this.updateLocationAddress(mapCenter!.lat(), mapCenter!.lng());
  }

  updateLocationAddress(latitude: number, longitude: number) {
    this.executeRequest<LocationAddress>({
      state: new State(),
      request: this.locationService.reverseGeocode(latitude, longitude),
      handleError: false,
      onSuccess: response => {
        this.fullAddress.set(response.formattedAddress ?? 'Unknown location');
      }
    });
  }


  initAddressAutoComplete() {
    if (isPlatformBrowser(this.platformId)) {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addressInputField!.nativeElement,
        {
          componentRestrictions: {country: ['US', 'BJ', 'SN', 'IN']},
          types: ['geocode'],
        }
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place: any = autocomplete.getPlace();

        const locationAddress = this.locationService.getAddressFromComponents(place, place.geometry?.location?.lat(), place.geometry?.location?.lng());

        this.fullAddress.set(locationAddress.formattedAddress);

        this.center.set({
          lat: locationAddress.latitude,
          lng: locationAddress.longitude
        });
        this.changeDetectorRef.detectChanges();

        this.addressForm.patchValue({
          'addressNumber': locationAddress.streetNumber,
          'address': locationAddress.addressLine1,
          'country': locationAddress.country,
          'city': locationAddress.city,
          'state': locationAddress.state,
          'zipCode': locationAddress.zipcode,
          'latitude': locationAddress.latitude,
          'longitude': locationAddress.longitude
        });
      });
    }
  }

  saveAddress(address?: string, latitude?: number, longitude?: number) {
    const addressToSave: SavedAddress = {
      address: address,
      latitude: latitude,
      longitude: longitude
    };
    this.appDataService.saveAddress(addressToSave);
  }

  private getReverseGeoCodedAddress(latitude: number, longitude: number) {
    this.executeRequest<LocationAddress>({
      state: new State<LocationAddress>,
      request: this.locationService.reverseGeocode(latitude, longitude),
      showLoader: true,
      onSuccess: response => {
        this.saveAddress(response.formattedAddress, latitude, longitude);
      }
    });
  }

  onDetectLocationClicked() {
    this.executeRequest<any>({
      state: new State<any>(),
      request: this.locationService.getCurrentLocation(),
      showLoader: true,
      onSuccess: response => {
        this.getReverseGeoCodedAddress(response.latitude, response.longitude);
        this.dialogRef.close(true);
      }
    });
  }


  onSelectClicked() {
    if (this.addressInputField && this.addressInputField.nativeElement) {
      const typedAddress = this.addressInputField.nativeElement.value;
      if (typedAddress) {
        const latitude = this.addressForm.value.latitude;
        const longitude = this.addressForm.value.longitude;
        this.appDataService.saveAddress({address: typedAddress, latitude, longitude});
        this.dialogRef.close(true);
      }
    }
  }


  onCancelPressed() {
    this.dialogRef.close(false);
  }

}

@switch (appearance()) {
  @case ('button') {
    <div class="flex bg-blue-100 p-3 rounded-md gap-2 items-center cursor-pointer" (click)="onDirectionButtonClicked()">
      <app-svg-icon src="assets/icons/directions.svg" class=" text-blue-700"></app-svg-icon>
      <p class="text-body2">Direction</p>
    </div>
  }
  @case('iconButton') {
    <div class="flex justify-center items-center h-10 w-10 rounded-full hover:bg-neutral-50 cursor-pointer">
      <app-svg-icon src="assets/icons/directions.svg" class="text-blue-500"
                    (click)="onDirectionButtonClicked()"></app-svg-icon>
    </div>
  }
}



import {Component, inject, input, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {AuthService} from "../../../../auth/data/auth.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {UpdateProfileRequest} from "../../../../auth/data/update-profile-request/update-profile-request";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';


import {
  matDatepickerAnimations
} from '@angular/material/datepicker';


import {State} from "../../../../../shared/base/base-state";
import {ProfileService} from "../../../data/profile.service";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {DatePickerComponent} from "../../../../../shared/inputs/date-picker/date-picker.component";
import {AuthenticatedUserResponse} from "../../../../auth/data/authenticated-user/authenticated-user-response";


@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    TextInputComponent,
    DatePickerComponent
],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})

export class AccountComponent extends BaseComponent implements OnInit {


  form!: FormGroup;
  private fb = inject(FormBuilder);
  private authStoreService = inject(AuthStoreService);
  private authService = inject(AuthService);
  private profileService = inject(ProfileService);

  updateProfileRequestState = new State<UpdateProfileRequest>();

  dobMaxDate = new Date();


  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      phoneNumber: new FormControl({value: '', disabled: true}, [Validators.required]),
      email: new FormControl({value: '', disabled: true}, [Validators.required]),
      birthday: []
    });
    this.processUserData();
  }

  processUserData() {
    let user = this.authStoreService.getAppUser()?.data;

    this.form.controls['firstName'].setValue(user?.firstName ?? '');
    this.form.controls['lastName'].setValue(user?.lastName ?? '');
    this.form.controls['phoneNumber'].setValue(user?.phoneNumber ?? '');
    this.form.controls['email'].setValue(user?.email ?? '');
    this.form.controls['birthday'].setValue(new Date(user?.birthday ?? ''));
  }

  onUpdateButtonClicked() {
    if (this.form.invalid) {
      return;
    }
    let userId = this.authStoreService.getAppUser()?.data?.user ?? '';
    this.executeRequest<UpdateProfileRequest>(
      {
        state: this.updateProfileRequestState,
        showLoader: true,
        handleSuccess: true,
        successMessage: 'Profile updated successfully',
        request: this.profileService.updateProfileDetails(userId, this.form.value),
        onSuccess: (res) => {
          this.getAuthenticatedUser();
        }
      }
    );
  }

  private getAuthenticatedUser() {
    this.executeRequest<AuthenticatedUserResponse>({
      state: new State(),
      request: this.authService.getAuthenticatedUser(),
      onSuccess: (response) => {
        this.authStoreService.saveAppUser(response, false);
        this.processUserData();
      }
    });
  }
}

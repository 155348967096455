import { Component } from '@angular/core';


@Component({
    selector: 'app-feature-1-section',
    standalone: true,
    templateUrl: './feature-1-section.component.html',
    styleUrl: './feature-1-section.component.scss',
    imports: []
})
export class Feature1SectionComponent {

}

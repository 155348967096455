import {Component, inject, OnInit} from '@angular/core';
import {TopNavComponent} from "../../../layout/top-nav/top-nav.component";
import {FooterComponent} from "../../../layout/footer/footer.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {LocationSelectorComponent} from "../../../../core/components/location-selector/location-selector.component";
import {Document} from "postcss";
import {DOCUMENT} from "@angular/common";
import {HeroSectionComponent} from "../components/hero-section/hero-section.component";
import {Feature1SectionComponent} from "../components/feature-1-section/feature-1-section.component";
import {Feature2SectionComponent} from "../components/feature-2-section/feature-2-section.component";
import {Feature3SectionComponent} from "../components/feature-3-section/feature-3-section.component";
import {MobileAppSectionComponent} from "../components/mobile-app-section/mobile-app-section.component";
import {
  LocalFavoriteRestaurantComponent
} from "../components/local-favorite-restaurant/local-favorite-restaurant.component";

@Component({
  selector: 'app-landing-page',
  standalone: true,
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  imports: [TopNavComponent, FooterComponent, HeroSectionComponent, Feature1SectionComponent, Feature2SectionComponent, Feature3SectionComponent, MobileAppSectionComponent, AppSvgIconComponent, LocationSelectorComponent, LocalFavoriteRestaurantComponent]
})
export class LandingPageComponent {

}

import {Image} from "./food-menu/image";
import {signal} from "@angular/core";
import {Price} from "./food-menu/price";
import {MenuResponseFoodItem} from "./menu-response";
import {FoodItemPriceType} from "../../../../core/constants/food-item-constants";

export class FoodMenu {
  id?: string;
  enable?: boolean;
  name?: string;
  description?: string;
  image?: Image;
  visibility?: MenuVisibility;
  categories: MenuCategory[] = [];
}

export class MenuVisibility {
  dineIn?: boolean;
  kiosk?: boolean;
  pickup?: boolean;
  reservation?: boolean;
  partner?: boolean;
  catering?: boolean;
  waitlist?: boolean;
}

export class MenuCategory {
  category?: CategoryInfo;
  menuItems?: FoodItem[];
  // FF
  isExpanded = signal(true);
  filterMatched = signal(true);
}

export class CategoryVisibility {
  inherited?: boolean;
  dineIn?: boolean;
  pickup?: boolean;
  partner?: boolean;
  catering?: boolean;
  pos?: boolean;
  kiosk?: boolean;
  takeout?: boolean;
  reservation?: boolean;
}

export class CategoryInfo {
  visibility?: CategoryVisibility;
  guid?: string;
  _id?: string;
  enable?: boolean;
  delete?: boolean;
  createdInTestMode?: boolean;
  createdDate?: string;
  restaurant?: string;
  companyUID?: string;
  name?: string;
  menu?: string;
  posName?: string;
  description?: string;
  sort?: number;
  __v?: number;
}

export class FoodItemVisibility {
  inherited?: boolean;
  dineIn?: boolean;
  pickup?: boolean;
  partner?: boolean;
  catering?: boolean;
  pos?: boolean;
  kiosk?: boolean;
  takeout?: boolean;
  reservation?: boolean;
}


export class FoodItem {
  visibility?: FoodItemVisibility;
  image?: Image;
  price?: Price;
  appliedDiscounts?: any[];
  itemGroupGuid?: any;
  discounts?: any[];
  guid?: any;
  prepTimeMinutes?: number;
  _id?: string;
  modifierGroups: FoodItemModifierGroup[] = [];
  delete?: boolean;
  createdInTestMode?: boolean;
  enable?: boolean;
  rating?: number;
  ratingCount?: number;
  companyUID?: string;
  restaurant?: string;
  menu?: string;
  category?: string;
  name?: string;
  posName?: string;
  description?: string;
  calories?: number;
  sort?: number;
  tags?: any[];
  subcategories?: any[] = [];
  submenus?: any[] = [];
  createdDate?: string;

  // FF
  filterMatched = signal(true);

  isCustomizableItem() {

    if(this.price?.type == FoodItemPriceType.SIZE_PRICE) {
      return true;
    }

    const modifierGroups = this.modifierGroups ?? [];
    if (modifierGroups.length > 0) {
      return true;
    }
    return false;
  }


  isSimpleItem() {

    if(this.price?.type == FoodItemPriceType.SIZE_PRICE) {
      return false;
    }

    const modifierGroups = this.modifierGroups ?? [];
    if (modifierGroups.length > 0) {
      return false;
    }
    return true;
  }

  resetSelection() {
    let sizePrices = this.price?.sizePrices ?? [];
    for (const sizePrice of sizePrices) {
      sizePrice.isSelected.set(false);
    }

    for (const modifierGroup of this.modifierGroups ?? []) {
      let modifiers = modifierGroup.modifiers ?? [];
      for (const modifier of modifiers) {
        modifier.isSelected.set(false);
        modifier.selectedQuantity.set(0);
      }
    }
  }
}

export class FoodItemModifierGroup {
  groupPrepTimeMinutes?: number;
  _id?: string;
  selection?: string;
  min?: number;
  max?: number;
  delete?: boolean;
  createdInTestMode?: boolean;
  restaurant?: string;
  name?: string;
  description?: string;
  sort: any;
  isRequired?: boolean;
  modifiers: FoodItemModifier[] = [];
  companyUID?: string;

  public onSingleChoiceModifierSelected(selectedModifierId: string) {
    for (const modifier of this.modifiers) {
      if (modifier._id == selectedModifierId) {
        modifier?.isSelected.set(true);
        modifier?.selectedQuantity.set(1);
      } else {
        modifier?.isSelected.set(false);
      }
    }
  }
}

export class FoodItemModifier {
  prepTimeMinutes?: number;
  _id!: string;
  name!: string;
  price!: number;

  // FF
  isSelected = signal(false);
  selectedQuantity = signal(0);

  onItemSelectedOrUnSelected(value: boolean) {
    this.isSelected.set(value);
    if(value) {
      this.selectedQuantity.set(1);
    }
  }

  onQuantityChanged(quantity: number) {
    if(quantity > 0) {
      this.selectedQuantity.set(quantity);
    }
  }

}


import { Component } from '@angular/core';
import { Constants } from '../../../../../core/constants/constants';

@Component({
  selector: 'app-mobile-app-section',
  standalone: true,
  imports: [],
  templateUrl: './mobile-app-section.component.html',
  styleUrl: './mobile-app-section.component.scss'
})
export class MobileAppSectionComponent {

    protected readonly Constants = Constants;
}

<app-base-dialog [title]="'Share'" [showAction]="false">
  <div body>
    <div class="flex justify-between gap-3">
      <div class="flex flex-col gap-2 items-center cursor-pointer" (click)="onCopyLinkPressed()">
        <app-svg-icon src="assets/icons/copy.svg"
                      class="text-neutral-700 bg-neutral-100 rounded-full p-4"></app-svg-icon>
        <p class="text-body2 font-semibold">Copy</p>
      </div>
      <div class="flex flex-col gap-2 items-center cursor-pointer" (click)="onSmsClicked()">
        <app-svg-icon src="assets/icons/message.svg"
                      class="text-blue-500 bg-neutral-100 rounded-full p-4"></app-svg-icon>
        <p class="text-body2 font-semibold">SMS</p>
      </div>
      <a class="flex flex-col gap-2 items-center cursor-pointer" (click)="onEmailClicked()">
        <app-svg-icon src="assets/icons/mail.svg"
                      class="text-red-500 bg-neutral-100 rounded-full p-4"></app-svg-icon>
        <p class="text-body2 font-semibold">Email</p>
      </a>
      <div class="flex flex-col gap-2 items-center cursor-pointer" (click)="onWhatsAppClicked()">
        <app-svg-icon src="assets/icons/whatsapp.svg"
                      class="bg-neutral-100 rounded-full text-[#25D366] p-4"></app-svg-icon>
        <p class="text-body2 font-semibold">Whatsapp</p>
      </div>
    </div>
    <div class="flex flex-col gap-2 py-6">
      <p class="text-body2 font-bold">Page Link</p>
      <div class="flex gap-3 items-center rounded-3xl bg-neutral-50 w-full px-3 py-2">
        <p class="text-body2 whitespace-pre-wrap break-words overflow-hidden">{{ data.url }}</p>
        <app-svg-icon (click)="onCopyLinkPressed()" src="assets/icons/copy.svg" class="text-neutral-700 cursor-pointer"></app-svg-icon>
      </div>
    </div>
  </div>
  <div actions></div>
</app-base-dialog>

export function getOrderStatusTextColor(status: string): string {
  switch (status.toUpperCase()) {
    case 'OPEN':
      return 'text-[#18B53D]';
    case 'ACTIVE':
      return 'text-[#18B53D]';
    case 'SCHEDULED_OPEN':
      return 'text-[#18B53D]';
    case 'CANCELLED':
      return 'text-[#B71C1C]';
    case 'READY':
      return 'text-[#18B53D]';
    case 'CLOSED':
      return 'text-[#B71C1C]';
    default:
      return 'text-[#000000]';
  }
}

export function getOrderStatusBgColor(status: string): string {
  switch (status.toUpperCase()) {
    case 'OPEN':
      return 'bg-[#18B53D]';
    case 'ACTIVE':
      return 'bg-[#18B53D]';
    case 'SCHEDULED_OPEN':
      return 'bg-[#18B53D]';
    case 'CANCELLED':
      return 'bg-[#B71C1C]';
    case 'READY':
      return 'bg-[#18B53D]';
    case 'CLOSED':
      return 'bg-[#B71C1C]';
    default:
      return 'bg-[#000000]';
  }
}

export function getOrderStatusText(status: string): string {
  switch (status.toUpperCase()) {
    case 'OPEN':
      return 'OPEN';
    case 'ACTIVE':
      return 'ACTIVE';
    case 'SCHEDULED':
      return 'SCHEDULED';
    case 'CANCELLED':
      return 'CANCELLED';
    case 'READY':
      return 'READY';
    case 'CLOSED':
      return 'CLOSED';
    case 'POS':
      return 'POS';
    case 'COMPLETED':
      return 'COMPLETED';
    case 'NEEDS_APPROVAL' :
      return 'NEEDS APPROVAL';
    case 'PREPARING':
      return 'PREPARING';
    default:
      return 'UNKNOWN';
  }
}


export function getOrderStatusSummary(status: string) {
  switch (status.toUpperCase()) {
    case 'OPEN':
    case 'ACTIVE':
    case 'SCHEDULED':
    case 'READY':
      return OrderStatusSummary.confirmed;
    case 'CANCELLED':
      return OrderStatusSummary.cancelled;
    case 'COMPLETED':
    case 'CLOSED':
      return OrderStatusSummary.finished;
    default:
      return OrderStatusSummary.confirmed;

  }
}

export function getOrderTypes(status: string): string {
  switch (status.toUpperCase()) {
    case 'TYPE_WALK_IN' :
      return 'WALK IN';
    case 'TYPE_RESERVATION' :
      return 'RESERVATION';
    case 'TYPE_WAITLIST' :
      return 'WAIT LIST';
    default:
      return 'UNKNOWN';
  }
}

export enum OrderStatusSummary {
  confirmed,
  cancelled,
  finished
}

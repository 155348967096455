import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from "../../../core/base/urls";
import {AddressListResponse, Address} from "./address-list/address-list-response";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class AddressService extends BaseApiService {
  getSavedAddresses() {
    return this.get<AddressListResponse>(URLs.getSavedAddress);
  }

  deleteAddress(addressId: string) {
    return this.delete(URLs.deleteAddress(addressId));
  }

  saveAddress(addressDetails: any) {
    return this.post<AddressListResponse>(URLs.saveAddress, addressDetails);
  }

  editAddress(addressId: string, addressDetails: any) {
    return this.put<AddressListResponse>(URLs.editAddress(addressId), addressDetails);
  }

}






























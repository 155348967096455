import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {FooterLinkComponent} from "./footer-link/footer-link.component";
import {Constants} from "../../../core/constants/constants";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    FooterLinkComponent,
    RouterLink
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  protected readonly Constants = Constants;
}

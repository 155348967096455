import {CartItem, CartItemDetail, CartModifier, CartModifierGroup} from "../data/cart/cart";
import {Price, SizePrice} from "../data/menu/food-menu/price";
import {FoodItem, FoodItemModifier} from "../data/menu/menu";

export class MenuItemToCartItemUtil {

  static covert(menuItem: FoodItem, quantity: number): CartItem {


    if (menuItem.isSimpleItem()) {
      let cartItem = this.getSimpleCartItem(menuItem, quantity);

      return cartItem;
    }

    return this.getSimpleCartItem(menuItem, quantity);
  }

  private static getSimpleCartItem(menuItem: FoodItem, quantity: number): CartItem {
    let price = this.getPrice(menuItem.price!);
    let cartItemDetail = new CartItemDetail(
      menuItem._id,
      menuItem.menu,
      menuItem.category,
      menuItem.name,
      menuItem.description,
      price,
      this.getModifierGroups(menuItem)
    );
    let cartItem = new CartItem(cartItemDetail);
    cartItem.quantity.set(quantity);
    cartItem.isCustomizableItem = false;

    return cartItem;
  }

  private static getPrice(foodItemPrice: Price): Price {
    let sizePrices: SizePrice[] = [];
    for (const sizePrice of foodItemPrice.sizePrices ?? []) {
      let sp = new SizePrice();
      sp.size = sizePrice.size;
      sp.price = sizePrice.price;
      sp.isDefault = sizePrice.isDefault;
      sp.isSelected.set(sizePrice.isSelected());
      sizePrices.push(sp);
    }
    let price = new Price();

    price.type = foodItemPrice.type;
    price.basePrice = foodItemPrice.basePrice;
    price.sizePrices = sizePrices;

    return price;
  }

  private static getModifierGroups(menuItem: FoodItem): CartModifierGroup[] {
    let modifierGroups: CartModifierGroup[] = [];
    for (const modifierGroup of menuItem.modifierGroups) {
      let mg = new CartModifierGroup();
      mg.id = modifierGroup._id;
      mg.selection = modifierGroup.selection;
      mg.name = modifierGroup.name;
      mg.description = modifierGroup.description;
      mg.modifiers = this.getModifiers(modifierGroup.modifiers)
      modifierGroups.push(mg);
    }
    return modifierGroups;
  }

  private static getModifiers(modifiers: FoodItemModifier[]): CartModifier[] {
    let ms: CartModifier[] = [];
    for (const modifier of modifiers) {
      let m = new CartModifier();
      m.id = modifier._id;
      m.name = modifier.name;
      m.price = modifier.price;
      if(modifier.isSelected()) {
        m.selectedQuantity.set(1);
      } else {
        m.selectedQuantity.set(modifier.selectedQuantity())
      }

      ms.push(m);
    }
    return ms;
  }
}

@switch (appearance()) {
  @case ('rounded') {
    <app-base-input title="{{ title() }}">
      <div input class="flex gap-2 items-center">
        <p class="bg-neutral-50 text-primary-500 font-bold py-2 px-4 rounded-full"
           (click)="onCounterDecreased()">-</p>
        <p class="text-body2">{{ formControl.value }}</p>
        <p class="bg-neutral-50 text-primary-500 font-bold py-2 px-4 rounded-full"
           (click)="onCounterIncreased()">+</p>
      </div>
      <div error>
        @if (formControl.errors && hasErrors) {
          <mat-error class="text-red-500">
            {{ formControl.errors | humanizeFormMessages: errorMessages() }}
          </mat-error>
        }
      </div>
    </app-base-input>
  }
  @case('flat') {
    <app-base-input title="{{ title() }}">
      <div input class="flex gap-2 items-center rounded-md border border-neutral-500">
        <p class="text-primary-500 font-bold py-2 px-4"
           (click)="onCounterDecreased()">-</p>
        <p class="text-body2">{{ formControl.value }}</p>
        <p class="text-primary-500 font-bold py-2 px-4"
           (click)="onCounterIncreased()">+</p>
      </div>
      <div error>
        @if (formControl.errors && hasErrors) {
          <mat-error class="text-red-500">
            {{ formControl.errors | humanizeFormMessages: errorMessages() }}
          </mat-error>
        }
      </div>
    </app-base-input>
  }
}





import { Injectable } from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from "../../../core/base/urls";
import {ReservationInviteGuestRequest} from "./reservation-invite-guest/reservation-invite-guest-request";
import {BookingInvitationsResponse} from "./booking-invitations/booking-invitations-response";
import {GenericResponse} from "../../../core/models/generic-response";
import {AcceptInvitationRequest} from "./accept-invitation/accept-invitation-request";
import {DeclineInvitationRequest} from "./decline-invitation/decline-invitation-request";
import {ReceivedInvitationResponse} from "./invitations/received-invitation-response";

@Injectable({
  providedIn: 'root'
})
export class InvitationsService extends BaseApiService {
  getBookingInvitations(bookingId: string) {
    return this.get<BookingInvitationsResponse>(URLs.bookingInvitations(bookingId));
  }

  inviteGuest(request: ReservationInviteGuestRequest) {
    return this.post(URLs.sendInvite, request);
  }

  acceptInvite(request: AcceptInvitationRequest) {
    return this.put<GenericResponse>(URLs.acceptInvitation, request);
  }

  declineInvite(request: DeclineInvitationRequest) {
    return this.put<GenericResponse>(URLs.declineInvitation, request);
  }

  cancelInvite(request: AcceptInvitationRequest) {
    return this.put<GenericResponse>(URLs.cancelInvitation, request);
  }

  getReceivedInvitations() {
    return this.get<ReceivedInvitationResponse>(URLs.getReceiveInvitation);
  }

  getSentInvitations() {
    return this.get<ReceivedInvitationResponse>(URLs.getSentInvitation);
  }
}

import {Component, inject, input, OnInit, signal} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {
  ReservationDataForView
} from "../../../../restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {OccasionsResponse} from "../../../../../core/services/common-data/occasions/occasions-response";
import {State} from "../../../../../shared/base/base-state";
import {TagsResponse, TagsResponseData} from "../../../../../core/services/common-data/tags/tags-response";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {
  MakeReservationRequest,
  MakeReservationRequestCustomerInfo
} from "../../../data/make-reservation/make-reservation-request";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {BookingsService} from "../../../data/bookings/bookings.service";
import {DatePipe} from "@angular/common";
import {LoginUserType} from "../../../../../core/models/login-user-type";
import {
  GuestLoginData,
  GuestLoginOverlayComponent, GuestLoginStatus
} from "../../../../auth/presentation/overlays/guest-login-overlay/guest-login-overlay.component";
import {MakeReservationResponse} from "../../../data/make-reservation/make-reservation-response";
import {
  BookingConfirmationOverlayComponent
} from "../booking-confirmation-overlay/booking-confirmation-overlay.component";
import {Constants} from "../../../../../core/constants/constants";
import {
  MultiSelectionFieldComponent
} from "../../../../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {
  SeatingPreferenceResponse, SeatingPreferenceResponseData
} from "../../../../../core/services/common-data/seating-preference/seating-preference-response";
import {RestaurantDetailResponse} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {RestaurantService} from "../../../../restaurants/data/restaurant.service";
import {AppDataService} from "../../../../../core/services/app-data/app-data.service";

@Component({
  selector: 'app-new-reservation-overlays',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    SingleSelectionFieldComponent,
    ReactiveFormsModule,
    TextInputComponent,
    DatePipe,
    MultiSelectionFieldComponent,
    NetworkImageComponent
  ],
  templateUrl: './make-reservation-overlay.component.html',
  styleUrl: './make-reservation-overlay.component.scss'
})
export class MakeReservationOverlayComponent extends BaseComponent implements OnInit {


  data: ReservationDataForView = inject(MAT_DIALOG_DATA);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef<MakeReservationOverlayComponent>);
  formBuilder = inject(FormBuilder);
  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);
  bookingsService = inject(BookingsService);
  appDataService = inject(AppDataService);


  reservationForm!: FormGroup;

  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();
  reservationState = new State<MakeReservationResponse>();


  ngOnInit(): void {
    this.getOccasions();
    this.getTags();
    this.getSeatingPreference();
    this.reservationForm = this.formBuilder.group({
      tags: [[]],
      occasion: [],
      note: [''],
      seatingPreferences: []
    });
  }

  reserveClicked() {
    if (this.reservationForm.invalid) {
      return;
    }

    this.handleReservation();
  }

  handleReservation() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.reserveTable(LoginUserType.appUser, null);
      return;
    }


    this.dialog.open(GuestLoginOverlayComponent, {
      ...Constants.defaultDialogConfig
    }).afterClosed().subscribe(result => {
      if (result.status == GuestLoginStatus.success) {
        this.reserveTable(LoginUserType.guestUser, result);
      }
    });
  }

  reserveTable(userType: LoginUserType, guestUserData: GuestLoginData | null) {
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
      case LoginUserType.guestUser:
        customerInfo = {
          firstName: guestUserData?.firstName ?? '',
          phoneNumber: guestUserData?.phoneNumber ?? ''
        };
        break;
    }

    const tagsValue = (this.reservationForm.value.tags ?? []) as TagsResponseData[];
    let tags = ['NO'];
    if (tagsValue.length > 0) {
      tags = tagsValue.map(x => x.name)
    }

    const occasion = this.reservationForm.value.occasion?.name ?? 'NO';

    const seatingOption = this.reservationForm.value.seatingPreferences?.name;


    const request: MakeReservationRequest = {
      restaurantId: this.data.restaurantId,
      shiftId: this.data.availability?.shiftId,
      partySize: this.data.partySize,
      time: this.data.availability?.time,
      autoAssignTable: true,
      customerInfo: customerInfo,
      note: this.reservationForm.value.note,
      tags: tags,
      occasion: occasion,
      seatingOption: seatingOption,
      coverSource: 'GUEST_WEB_APP'
    };

    this.executeRequest<MakeReservationResponse>({
      state: this.reservationState,
      request: this.bookingsService.makeReservation(request),
      handleSuccess: true,
      successMessage: 'Table reserved successfully!',
      onSuccess: response => {
        this.dialogRef.close();
        this.dialog.open(BookingConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        });
      }
    });
  }


  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }
}

import {Component, computed, ElementRef, inject, input, OnInit} from '@angular/core';
import {
  CompletedOrdersResponse,
  GetCompletedOrdersResponseData
} from "../../../data/completed-orders/completed-orders-response";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";

import {DatePipe, DOCUMENT, NgClass} from "@angular/common";
import {DirectionButtonComponent} from "../../../../../core/components/direction-button/direction-button.component";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {openLink} from "../../../../../shared/utils/html-utils";
import {GeoPoint} from "../../../../../core/services/location-service/location.service";
import {
  getBookingStatusBgColor,
  getBookingStatusText,
  getBookingStatusTextColor
} from "../../../../booking/utilities/booking-status-utils";
import {
  getOrderStatusBgColor,
  getOrderStatusText,
  getOrderStatusTextColor
} from "../../../utilities/order-status-utils";
import {OrderTypeComponent} from "../order-type/order-type.component";

@Component({
  selector: 'app-orders-card',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe,
    DirectionButtonComponent,
    NetworkImageComponent,
    NgClass,
    OrderTypeComponent
],
  templateUrl: './orders-card.component.html',
  styleUrl: './orders-card.component.scss'
})
export class OrdersCardComponent implements OnInit {

  order = input.required<GetCompletedOrdersResponseData>();
  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);

  protected readonly getOrderStatusText = getOrderStatusText;


  ngOnInit() {

  }

  onCallClicked(phoneNumber: string | undefined) {
    openLink(`tel:${phoneNumber}`, false, this.document, this.elementRef);

  }


  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.order().restaurant?.location?.location?.coordinates[1],
      longitude: this.order().restaurant?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  getOrderColor(status: string) {
    return `${getOrderStatusTextColor(status)} ${getOrderStatusBgColor(status)}`;
  }
}













import {Component, inject, OnInit, signal} from '@angular/core';


import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {InvitationsService} from "../../../data/invitations.service";
import {State} from "../../../../../shared/base/base-state";
import {
  InvitationCardComponent
} from "../../components/invitation-card/invitation-card.component";

import {InvitationsTabComponent} from "../../components/invitations-tab/invitations-tab.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {
  ReceivedInvitationResponse,
  ReceivedInvitationResponseData
} from "../../../data/invitations/received-invitation-response";
import {BookingStatusSummary, getBookingStatusSummary} from "../../../../booking/utilities/booking-status-utils";


@Component({
  selector: 'app-received-invitations',
  standalone: true,
  imports: [
    NoDataComponent,
    ShimmerComponent,
    InvitationCardComponent,
    InvitationsTabComponent
],
  templateUrl: './received-invitations.component.html',
  styleUrl: './received-invitations.component.scss'
})
export class ReceivedInvitationsComponent extends BaseComponent implements OnInit {

  authStoreService = inject(AuthStoreService);
  invitationsService = inject(InvitationsService);

  receivedInvitationsState = new State<ReceivedInvitationResponse>();
  receivedInvitations = signal<ReceivedInvitationResponseData[]>([]);

  ngOnInit(): void {
    this.getReceivedInvitations();
  }

  getReceivedInvitations() {
    const appUser = this.authStoreService.getAppUser();
    this.executeRequest<ReceivedInvitationResponse>({
      state: this.receivedInvitationsState,
      request: this.invitationsService.getReceivedInvitations(),
      onSuccess: response => {
        const invitations = response.data.filter(booking => {
          const bookingSummary = getBookingStatusSummary(booking.status);
          return bookingSummary == BookingStatusSummary.confirmed;
        })
        this.receivedInvitations.set(invitations);
      }
    });
  }

}

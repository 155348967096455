import {AfterContentInit, Component, inject, input} from '@angular/core';
import {ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule} from "@angular/forms";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-date-picker-input',
  standalone: true,
  imports: [
    FormsModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    HumanizeFormMessagesPipe,
    MatError,
    NgClass
  ],
  templateUrl: './date-picker-input.component.html',
  styleUrl: './date-picker-input.component.scss'
})
export class DatePickerInputComponent extends BaseControlValueAccessor implements AfterContentInit {
  appearance = input<'fill' | 'outline'>('outline');
  label = input<string>('');
  fullWidth = input<boolean>(true);
  placeholder = input<string>('');
  errorMessages = input<{ [key: string]: string }>({});

  ngControl = inject(NgControl, {optional: true, self: true});

  constructor() {
    super();
    if(this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if(formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  onDateSelected($event: MatDatepickerInputEvent<any, any>) {
    this.markAsTouched();
    this.onChange($event.value);
  }
}

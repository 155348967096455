import {Component, input} from '@angular/core';

@Component({
  selector: 'app-booking-type',
  standalone: true,
  imports: [],
  templateUrl: './booking-type.component.html',
  styleUrl: './booking-type.component.scss'
})
export class BookingTypeComponent {
  type = input.required<string>();
}

import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  AddOrUpdateAddressComponent
} from "../../../../settings/presentation/pages/addresses/add-or-update-address/add-or-update-address.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  LocationAddress,
  LocationService
} from "../../../../../core/services/location-service/location.service";
import {SavedAddress} from "../../../../../core/services/app-data/saved-address.model";
import {AppDataService} from "../../../../../core/services/app-data/app-data.service";
import {State} from "../../../../../shared/base/base-state";
import {AddressListResponse, Address} from "../../../../settings/data/address-list/address-list-response";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {AddressService} from "../../../../settings/data/address.service";
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {Constants} from "../../../../../core/constants/constants";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {RouterLink} from "@angular/router";
import {arrayToCSV} from "../../../../../shared/utils/string-utils";
import {GoogleMap} from "@angular/google-maps";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-location-selection-overlay',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    AddOrUpdateAddressComponent,
    ShimmerComponent,
    BaseDialogComponent,
    ButtonComponent,
    RouterLink,
    GoogleMap,
    MatDialogContent
  ],
  templateUrl: './location-selection-overlay.component.html',
  styleUrl: './location-selection-overlay.component.scss'
})
export class LocationSelectionOverlayComponent extends BaseComponent implements OnInit {
  dialog = inject(MatDialog);
  locationService = inject(LocationService);
  addressService = inject(AddressService);
  appDataService = inject(AppDataService);
  dialogRef = inject(MatDialogRef<LocationSelectionOverlayComponent>);
  authStoreService = inject(AuthStoreService);
  data: Address = inject(MAT_DIALOG_DATA);
  formBuilder = inject(FormBuilder);

  addressState = new State<AddressListResponse>();

  protected readonly arrayToCSV = arrayToCSV;

  @ViewChild('addressInputField') addressInputField?: ElementRef;

  ngOnInit() {
    this.getSavedAddresses();
  }

  getSavedAddresses() {
    if (this.authStoreService.isAuthenticated()) {
      this.executeRequest({
        state: this.addressState,
        request: this.addressService.getSavedAddresses()
      });
    }
  }

  onDetectLocationClicked() {
    this.executeRequest<any>({
      state: new State<any>(),
      request: this.locationService.getCurrentLocation(),
      showLoader: true,
      onSuccess: response => {
        this.getReverseGeoCodedAddress(response.latitude, response.longitude);
        this.dialogRef.close(true);
      }
    });
  }

  private getReverseGeoCodedAddress(latitude: number, longitude: number) {
    this.executeRequest<LocationAddress>({
      state: new State<LocationAddress>,
      request: this.locationService.reverseGeocode(latitude, longitude),
      showLoader: true,
      onSuccess: response => {
        this.saveAddress(response.formattedAddress, latitude, longitude);
      }
    });
  }

  saveAddress(address?: string, latitude?: number, longitude?: number) {
    const addressToSave: SavedAddress = {
      address: address,
      latitude: latitude,
      longitude: longitude
    };
    this.appDataService.saveAddress(addressToSave);
  }

  onAddAddressClicked() {
    const dialogRef = this.dialog.open(AddOrUpdateAddressComponent, {
      ...Constants.defaultDialogConfig
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSavedAddresses();
      }
    });
  }

  onAddressSelected(address: Address) {
    const fullAddress = arrayToCSV([address.address, address.city, address.state, address.country]);
    this.saveAddress(fullAddress, address.location.coordinates[1], address.location.coordinates[0]);
    this.dialogRef.close(true);
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {State} from "../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {PaymentService} from "../../../data/payment.service";

@Component({
  selector: 'app-delete-payment',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './delete-payment.component.html',
  styleUrl: './delete-payment.component.scss'
})
export class DeletePaymentComponent extends BaseComponent implements OnInit {
  dialogRef = inject(MatDialogRef<DeletePaymentComponent>);
  paymentService = inject(PaymentService);
  data: string = inject(MAT_DIALOG_DATA) ;

  state = new State<GenericResponse>();

  ngOnInit(): void {

  }

  onYesClicked() {
    this.executeRequest({
      state: this.state,
      request: this.paymentService.deletePaymentMethod(this.data),
      successMessage: 'Payment Method deleted successfully!',
      handleSuccess: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }

    })

  }

  onNoClicked() {
    this.dialogRef.close();
  }

}

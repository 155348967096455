import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {State} from "../../../../../shared/base/base-state";
import {
  ConfirmMFAUserCodeRequest
} from "../../../../../core/services/common-data/MFA/confirm-mfa-user-code/confirm-MFA-user-code-request";
import {
  ConfirmMFAUserCodeResponse
} from "../../../../../core/services/common-data/MFA/confirm-mfa-user-code/confirm-MFA-user-code-response";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {AuthenticatedUserResponse} from "../../../data/authenticated-user/authenticated-user-response";
import {AuthService} from "../../../data/auth.service";


@Component({
  selector: 'app-mfa-confirmation',
  standalone: true,
  imports: [
    ButtonComponent,
    DropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent
  ],
  templateUrl: './mfa-confirmation.component.html',
  styleUrl: './mfa-confirmation.component.scss'
})
export class MfaConfirmationComponent extends BaseComponent implements OnInit {

  userInfo = input<string>();
  codeSentTo = input<string>();

  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);
  private authService = inject(AuthService);

  confirmMfaState = new State<ConfirmMFAUserCodeResponse>();
  authenticatedUserState = new State<AuthenticatedUserResponse>();

  code = '';

  ngOnInit(): void {
  }

  onVerifyClicked() {
    const request: ConfirmMFAUserCodeRequest = {
      userInfo: this.userInfo(),
      code: this.code
    }
    this.executeRequest({
      state: this.confirmMfaState,
      request: this.commonDataService.confirmMFAUserCode(request),
      showLoader: true,
      onSuccess: response => {
        this.authStoreService.saveToken(response.data.refreshToken, response.data.token);
        this.handleLogin();
      }
    });
  }

  handleLogin() {
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        if (response.data.userType == 'USER_TYPE_USER') {
          this.authStoreService.saveAppUser(response);
        } else {
          this.authStoreService.logout();
          this.toasterService.error('Invalid credentials, kindly use a valid credentials');
        }
      }
    });
  }

  onResendCodeClicked() {
    this.router.navigate(['/mfa']);
  }
}

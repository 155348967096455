import {Component, input} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-individual-rating',
  standalone: true,
  imports: [
    DatePipe,
    AppSvgIconComponent,
    NgClass
  ],
  templateUrl: './individual-rating.component.html',
  styleUrl: './individual-rating.component.scss'
})
export class IndividualRatingComponent {
  // rating = input<Rating>();
  readonly = input<boolean>(false);
  rating = input<number | null>(0);
}

import {Component, inject, input, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../../shared/base/base-component";
import {BookingsService} from "../../../data/bookings/bookings.service";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {BookingsRequest} from "../../../data/bookings/common/bookings-request";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {State} from '../../../../../shared/base/base-state';
import {BookingsResponse, BookingsResponseData} from "../../../data/bookings/bookings/bookings-response";
import {BookingsTabComponent} from "../../components/bookings-tab/bookings-tab.component";
import {BookingCardComponent} from "../../components/booking-card/booking-card.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-upcoming-reservations',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NetworkImageComponent,
    NoDataComponent,
    ShimmerComponent,
    BookingsTabComponent,
    BookingCardComponent
  ],
  templateUrl: './upcoming-reservations.component.html',
  styleUrl: './upcoming-reservations.component..scss'
})
export class UpcomingReservationsComponent extends BaseComponent implements OnInit {

  authStoreService = inject(AuthStoreService);
  bookingsService = inject(BookingsService);
  dialog = inject(MatDialog);


  bookingState = new State<BookingsResponse>();

  ngOnInit(): void {
    this.getUpcomingReservations();
  }

  getUpcomingReservations() {
    const appUser = this.authStoreService.getAppUser()?.data;
    const request: BookingsRequest = {
      phoneNumber: appUser?.phoneNumber,
      email: appUser?.email
    };
    this.executeRequest({
      state: this.bookingState,
      request: this.bookingsService.getUpcomingReservations(request)
    });
  }

  onUpcomingReservationClicked(booking: BookingsResponseData) {
    this.router.navigate([`/booking/${booking._id}`]);
  }

}

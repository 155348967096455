import { Component, Input, OnInit, inject } from '@angular/core';
import { BaseComponent } from '../../../../../shared/base/base-component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GenericResponse } from '../../../../../core/models/generic-response';
import { AuthService } from '../../../data/auth.service';
import { ButtonComponent } from "../../../../../shared/components/button/button.component";
import {State} from "../../../../../shared/base/base-state";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";

@Component({
    selector: 'app-activate-account',
    standalone: true,
    templateUrl: './activate-account.component.html',
    styleUrl: './activate-account.component.scss',
  imports: [ReactiveFormsModule, ButtonComponent, TextInputComponent]
})
export class ActivateAccountComponent extends BaseComponent implements OnInit {

  @Input()
  email?: string;

  form!: FormGroup;
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      code: ['', Validators.required]
    });
  }


  activateAccount() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<GenericResponse>({
      state: new State<GenericResponse>(),
      request: this.authService.activateAccount(this.form.value),
      handleSuccess: true,
      onSuccess: response => {
        this.router.navigate(["/login"]);
      }
    })
  }

}

import { DOCUMENT } from '@angular/common';
import {AfterViewInit, Directive, ElementRef, EventEmitter, inject, Inject, OnDestroy, Output} from '@angular/core';
import { filter, fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  @Output() clickOutside = new EventEmitter<void>();

  documentClickSubscription: Subscription | undefined;
  document = inject(DOCUMENT);
  element = inject(ElementRef);

  ngAfterViewInit(): void {
    this.documentClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event) => {
          return !this.isInside(event.target as HTMLElement);
        }),
      )
      .subscribe(() => {
        this.clickOutside.emit();
      });
  }

  ngOnDestroy(): void {
    this.documentClickSubscription?.unsubscribe();
  }

  isInside(elementToCheck: HTMLElement): boolean {
    let isInside = elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
    return isInside;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-upcoming-reservations',
  standalone: true,
  imports: [],
  templateUrl: './reservations.component.html',
  styleUrl: './reservations.component.scss'
})
export class ReservationsComponent {

}

import {Component, effect, Input, input, OnInit} from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {DatePipe, JsonPipe, NgClass} from "@angular/common";
import {percentageToWidthCSS} from "./css-utils";
import {IndividualRatingComponent} from "../individual-rating/individual-rating.component";

@Component({
  selector: 'app-review-summary',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe,
    JsonPipe,
    NgClass,
    IndividualRatingComponent
  ],
  templateUrl: './review-summary.component.html',
  styleUrl: './review-summary.component.scss'
})
export class ReviewSummaryComponent implements OnInit {

  review = input<OverallReview>();

  fiveStarPercentage = 0;
  fourStarPercentage = 0;
  threeStarPercentage = 0;
  twoStarPercentage = 0;
  oneStarPercentage = 0;

  ngOnInit(): void {
    this.oneStarPercentage = this.calculatePercentage(this.review()?.oneStarRatings, this.review()?.ratingCount);
    this.twoStarPercentage = this.calculatePercentage(this.review()?.twoStarRatings, this.review()?.ratingCount);
    this.threeStarPercentage = this.calculatePercentage(this.review()?.threeStarRatings, this.review()?.ratingCount);
    this.fourStarPercentage = this.calculatePercentage(this.review()?.fourStarRatings, this.review()?.ratingCount);
    this.fiveStarPercentage = this.calculatePercentage(this.review()?.fiveStarRatings, this.review()?.ratingCount);
  }

  calculatePercentage(oneStarRatings: number | undefined, ratingCount: number | undefined) {
    return ((oneStarRatings ?? 0) / (ratingCount ?? 0)) * 100;
  }

  getCss(percentage: number) {
    return percentageToWidthCSS(percentage);
  }

}


export interface OverallReview {
  rating: number;
  ratingCount: number;
  fiveStarRatings: number;
  fourStarRatings: number;
  threeStarRatings: number;
  twoStarRatings: number;
  oneStarRatings: number;
}


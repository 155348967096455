<div class="header flex justify-center items-center px-3 py-6 border-b border-neutral-200">
    <p class="text-body1 font-bold">Change Password</p>
    <p class="absolute right-3 rounded-lg p-3 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
       (click)="onCloseClicked()">
        <app-svg-icon src="assets/icons/close.svg" class="text-neutral-800" [size]="12"></app-svg-icon>
    </p>
</div>

<mat-dialog-content class="body overflow-y-auto max-h-60vh py-6 px-8 h-50 my-12">
    <form [formGroup]="changePasswordForm">
        <app-text-input type="password" formControlName="currentPassword" [label]="'Current Password'"></app-text-input>
        <app-text-input type="password" formControlName="newPassword" [label]="'New Password'"></app-text-input>
        <app-text-input type="password" formControlName="confirmPassword" [label]="'Confirm New Password'"></app-text-input>
    </form>
</mat-dialog-content>

<div class="border-t">
    <mat-dialog-actions>
        <div class="action flex w-full justify-end gap-4 items-center px-3 py-2 border-neutral-200">
            <app-button appearance="outline" (click)="onClearClicked()">Clear All</app-button>
            <app-button (click)="onChangePasswordClicked()">Change Password</app-button>
        </div>
    </mat-dialog-actions>
</div>

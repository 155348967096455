import {Component, inject, input} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {State} from "../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {InvitationsService} from "../../../data/invitations.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";

@Component({
  selector: 'app-cancel-invitation',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './cancel-invitation-overlay.component.html',
  styleUrl: './cancel-invitation-overlay.component.scss'
})
export class CancelInvitationOverlayComponent extends BaseComponent {
  state = new State<GenericResponse>();

  dialogRef = inject(MatDialogRef<CancelInvitationOverlayComponent>);


  onNoClicked() {
    this.dialogRef.close(InvitationCancelAction.noAction);

  }

  onYesClicked() {
    this.dialogRef.close(InvitationCancelAction.cancelInvitation);
  }
}

export enum InvitationCancelAction {
  cancelInvitation,
  noAction
}

import {AfterContentInit, Component, EventEmitter, inject, input, output, Output} from '@angular/core';
import {AppSvgIconComponent} from "../../components/app-svg-icon/app-svg-icon.component";
import {NgClass} from "@angular/common";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {FormControl, NgControl} from "@angular/forms";
import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {MatError} from "@angular/material/form-field";

@Component({
  selector: 'app-rating-input',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass,
    HumanizeFormMessagesPipe,
    MatError
  ],
  templateUrl: './rating-input.component.html',
  styleUrl: './rating-input.component.scss'
})
export class RatingInputComponent extends BaseControlValueAccessor implements AfterContentInit {

  appearance = input<'simple' | 'outline'>('simple');
  valueChanged = output<number>();

  ngControl = inject(NgControl, {optional: true, self: true});

  errorMessages = input<{ [key: string]: string }>({});

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  numberOfStarsClicked = 0;

  stars = [1, 2, 3, 4, 5];

  onStarClicked(star: number) {
    if (!this.disabled()) {
      this.markAsTouched();
      this.numberOfStarsClicked = star;
      this.onChange(star);
      this.valueChanged.emit(this.formControl.value);
    }
  }

  getRatingStyle() {
    let ratingClasses = '';
    switch (this.appearance()) {
      case 'simple':
        ratingClasses = 'p-1';
        break;
      case 'outline':
        ratingClasses = 'p-2 border border-neutral-200 rounded';
        break;
    }
    return ratingClasses;
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {RatingInputComponent} from "../../../../../shared/inputs/rating-input/rating-input.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextAreaInputComponent} from "../../../../../shared/inputs/text-area-input/text-area-input.component";
import {RestaurantDetailsResponseData} from "../../../data/restaurant-details/restaurant-detail-response";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WriteRestaurantReviewRequest} from "../../../data/write-restaurant-review/write-restaurant-review-request";
import {State} from "../../../../../shared/base/base-state";
import {WriteRestaurantReviewResponse} from "../../../data/write-restaurant-review/write-restaurant-review-response";
import {ReviewsService} from "../../../data/reviews.service";

@Component({
  selector: 'app-review-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    AppSvgIconComponent,
    ButtonComponent,
    RatingInputComponent,
    ReactiveFormsModule,
    TextAreaInputComponent
  ],
  templateUrl: './write-review-overlay.component.html',
  styleUrl: './write-review-overlay.component.scss'
})
export class WriteReviewOverlayComponent extends BaseComponent implements OnInit {

  restaurantDetails: RestaurantDetailsResponseData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  formBuilder = inject(FormBuilder);
  reviewsService = inject(ReviewsService);

  reviewForm!: FormGroup;

  writeReviewState = new State<WriteRestaurantReviewResponse>();

  ngOnInit(): void {
    this.initReviewForm();
  }

  private initReviewForm() {
    this.reviewForm = this.formBuilder.group({
      atmosphereRating: [0],
      serviceRating: [0],
      foodRating: [0],
      qualityRating: [0],
      waitTimeRating: [0],
      feedback: [null, [Validators.required]]
    });
  }

  onSubmitPressed() {
    this.validateForm(this.reviewForm);
    if (this.reviewForm.invalid) {
      return;
    }

    const reviewFormValue = this.reviewForm.value;
    const request: WriteRestaurantReviewRequest = {
      restaurantId: this.restaurantDetails._id,
      rating: {
        atmosphereRating: reviewFormValue.atmosphereRating,
        serviceRating: reviewFormValue.serviceRating,
        foodRating: reviewFormValue.foodRating,
        qualityRating: reviewFormValue.qualityRating,
        waitTimeRating: reviewFormValue.waitTimeRating,
      },
      feedback: reviewFormValue.feedback,
      followUp: false
    };

    this.executeRequest({
      state: this.writeReviewState,
      request: this.reviewsService.writeRestaurantReview(request),
      successMessage: 'Thank you for your review! We appreciate your feedback and value your opinion.',
      onSuccess: response => {
        this.dialogRef.close(RatingActionStatus.success);
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }
}

export enum RatingActionStatus {
  success,
  failed
}

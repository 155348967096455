<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6 flex justify-center">
  <form [formGroup]="reservationForm" (ngSubmit)="onReserveClicked()"
        class="flex flex-col p-4 gap-4">
    <div class="flex gap-4 bg-white rounded shadow-1 overflow-hidden">
      <app-network-image class="h-auto w-32 object-cover"
                         [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
      </app-network-image>
      <div class="flex flex-col gap-2 py-4">
        <p class="text-body2 text-primary-500">{{ restaurantDetailState.response()?.data?.restaurantName }}</p>
        <p class="text-body2 text-neutral-400">
          {{
            arrayToCSV([restaurantDetailState.response()?.data?.location?.address1,
              restaurantDetailState.response()?.data?.location?.city,
              restaurantDetailState.response()?.data?.location?.state])
          }}
        </p>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex items-start gap-10">

        <app-date-selector-input formControlName="date" [title]="'Date'"
                                 (valueChanged)="onDateSelected($event)"></app-date-selector-input>

        <app-counter-input formControlName="partySize" title="Select Party"
                           (valueChanged)="onPartSizeChanged($event)"></app-counter-input>
      </div>
      <app-single-selection-field formControlName="time"
                                  [title]="'Select Time'"
                                  [items]="availabilities()"
                                  [display]="'timeString'"
                                  [state]="tableAvailabilitiesState"
                                  noDataMessage="No tables available">
      </app-single-selection-field>

      <app-single-selection-field formControlName="occasion" [title]="'Occasion'"
                                  [items]="occasionsState.response()?.data ?? []"
                                  [display]="'name'"
                                  [dynamicIconPath]="'icon'"
                                  [dynamicIconColor]="'iconColor'"></app-single-selection-field>
      <app-single-selection-field formControlName="seatingPreferences" [title]="'Seating Preferences'"
                                  [items]="seatingPreferenceState.response()?.data ?? []"
                                  [display]="'name'"
                                  [dynamicIconPath]="'icon'"
                                  [dynamicIconColor]="'iconColor'"></app-single-selection-field>
      <app-multi-selection-field formControlName="tags" [title]="'Tags'" [items]="tagsState.response()?.data ?? []"
                                 [display]="'name'"
                                 [dynamicIconPath]="'icon'"
                                 [dynamicIconColor]="'iconColor'"></app-multi-selection-field>
      <app-text-input formControlName="note" class="mt-2" [label]="'Special notes'" viewType="text"></app-text-input>

    </div>

    <p class="text-body2 text-black">Cancellation Policy</p>
    <p class="text-body2">While you won’t be charge if you need cancel, we ask that you notify us at least 24 hours in
      advance</p>
    <p class="text-body2">By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and
      Cookie Policy</p>
    <app-button type="submit" class="w-full" [fullWidth]="true" [loading]="reservationState.loading()">Reserve
    </app-button>
  </form>
</div>

import {Component, inject, input, OnInit, signal} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  SendMFAConfirmCodeRequest
} from "../../../../../core/services/common-data/MFA/send-mfa-confirm-code/send-MFA-confirm-code-request";
import {State} from "../../../../../shared/base/base-state";
import {
  SendMFAConfirmCodeResponse
} from "../../../../../core/services/common-data/MFA/send-mfa-confirm-code/send-MFA-confirm-code-response";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";

@Component({
  selector: 'app-mfa-verification',
  standalone: true,
  imports: [
    ButtonComponent,
    DropdownComponent,
    ReactiveFormsModule
  ],
  templateUrl: './mfa-verification.component.html',
  styleUrl: './mfa-verification.component.scss'
})
export class MfaVerificationComponent extends BaseComponent implements OnInit {
  userInfo = input<string>();
  formatPhoneNumber = input<string>();
  emailFormat = input<string>();

  private fb = inject(FormBuilder);
  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);

  sendCodeState = new State<SendMFAConfirmCodeResponse>();

  verificationForm!: FormGroup;

  preferredMethods = signal<any[]>([]);

  ngOnInit(): void {
    this.initVerificationForm();
    this.preferredMethods.set([
      {id: 'SMS_MFA', name: `SMS (${this.formatPhoneNumber()})`},
      {id: 'EMAIL_MFA', name: `Email (${this.emailFormat()})`}
    ]);
  }

  initVerificationForm() {
    this.verificationForm = this.fb.group({
      preferredMethod: ['', Validators.required]
    });
  }

  onSendCodeClicked() {
    const request: SendMFAConfirmCodeRequest = {
      preferredMFType: this.verificationForm.value.preferredMethod,
      userInfo: this.userInfo()
    };

    this.executeRequest({
      state: this.sendCodeState,
      request: this.commonDataService.sendMFAConfirmCode(request),
      showLoader: true,
      onSuccess: response => {
        let codeSentTo: string | undefined;
        switch (request.preferredMFType) {
          case 'SMS_MFA':
            codeSentTo = this.formatPhoneNumber();
            break;
          case 'EMAIL_MFA':
            codeSentTo = this.emailFormat();
            break;
        }

        this.router.navigate(['/mfa-confirmation'], {
          queryParams: {
            userInfo: request.userInfo,
            codeSentTo: codeSentTo,
            preferredMethod: request.preferredMFType
          }
        });
      }
    });
  }

}


<app-base-dialog [title]="'Join Waitlist'">
  <div body>
    <form [formGroup]="waitListForm" class="flex flex-col gap-3">

      <div class="flex gap-12">
        <app-counter-input formControlName="partySize" title="Select Party"
                           (valueChanged)="onPartSizeChanged($event)"></app-counter-input>
      </div>

      <app-base-input title="Wait time">
        <div input>
          @if (waitTimesState.success()) {
            <p
              class="text-body2 font-bold text-primary-500">{{ waitTimesState.response()?.data?.tableWaitTime ?? 'N/A' }}
              minutes</p>
          }
          @if (waitTimesState.loading()) {
            <app-shimmer class="w-full" type="multiline"></app-shimmer>
          }
        </div>
      </app-base-input>

      <app-single-selection-field formControlName="occasion" [title]="'Occasion'"
                                  [items]="occasionsState.response()?.data ?? []"
                                  [display]="'name'"
                                  [dynamicIconPath]="'icon'"
                                  [dynamicIconColor]="'iconColor'"></app-single-selection-field>
      <app-single-selection-field formControlName="seatingPreferences" [title]="'Seating Preferences'"
                                  [items]="seatingPreferenceState.response()?.data ?? []"
                                  [display]="'name'"
                                  [dynamicIconPath]="'icon'"
                                  [dynamicIconColor]="'iconColor'"></app-single-selection-field>
      <app-multi-selection-field formControlName="tags" [title]="'Tags'" [items]="tagsState.response()?.data ?? []"
                                 [display]="'name'"
                                 [dynamicIconPath]="'icon'"
                                 [dynamicIconColor]="'iconColor'"></app-multi-selection-field>
      <app-text-input formControlName="note" class="mt-2" [label]="'Special notes'" viewType="text"></app-text-input>
      <p class="text-body2 text-black">Cancellation Policy</p>
      <p class="text-body2">While you won’t be charge if you need cancel, we ask that you notify us at least 24 hours in
        advance</p>
      <p class="text-body2">By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy,
        and Cookie Policy</p>
    </form>
  </div>
  <div actions>
    <div class="flex">
      <div class="flex-1"></div>
      <app-button [loading]="joinWaitListState.loading()" (click)="onJoinWaitListClicked()">Join Waitlist</app-button>
    </div>
  </div>
</app-base-dialog>

import {
  AfterContentInit,
  Component,
  inject,
  input,
  output,
  signal
} from '@angular/core';
import {BaseInputComponent} from "../_base/base-input/base-input.component";
import {NgClass, NgStyle} from "@angular/common";
import {FormControl, NgControl, ReactiveFormsModule} from "@angular/forms";

import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {State} from "../../base/base-state";
import {ShimmerComponent} from "../../components/shimmer/shimmer.component";
import {AppSvgIconComponent} from "../../components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-single-selection-field',
  standalone: true,
  imports: [
    BaseInputComponent,
    NgClass,
    ReactiveFormsModule,
    HumanizeFormMessagesPipe,
    ShimmerComponent,
    AppSvgIconComponent,
    NgStyle
],
  templateUrl: './single-selection-field.component.html',
  styleUrl: './single-selection-field.component.scss'
})
export class SingleSelectionFieldComponent<T> extends BaseControlValueAccessor implements AfterContentInit {
  title = input<string | null>();
  items = input<T[]>([]);
  display = input.required<string>();
  iconSrc = input<string | null>();
  dynamicIconPath = input<string>();
  imageUrl = input<string | null>();
  dynamicImageUrlPath = input<string>();
  iconColor = input<string>();
  dynamicIconColor = input<string>();
  value = input<string>();
  noDataMessage = input<string>();
  state = input<State<any>>();
  customActionText = input<string>();
  fullWidth = input(false);

  valueChanged = output<T | null>();
  onCustomActionClicked = output<void>();

  errorMessages = signal<{ [key: string]: string }>({});
  ngControl = inject(NgControl, {optional: true, self: true});

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  getDisplayString(item: T): any {
    if (this.display() == null || this.display() == '') {
      return item;
    }
    let object = item as any;
    return this.display().split('.').reduce((acc, part) => acc && acc[part], object);
  }

  getPropertyId(item: T): any {
    if (this.value() == null || this.value() == '') {
      return item;
    }
    let object = item as any;
    return this.value()!.split('.').reduce((acc, part) => acc && acc[part], object);
  }

  getImageType(item: T): 'svg' | 'url' | null {

    if (this.iconSrc() != null && this.iconSrc() != '') {
      return 'svg';
    }

    if (this.dynamicIconPath() != null && this.dynamicIconPath() != '') {
      return 'svg';
    }

    if (this.imageUrl() != null && this.imageUrl() != '') {
      return 'url';
    }

    if (this.dynamicImageUrlPath() != null && this.dynamicImageUrlPath() != '') {
      return 'url';
    }

    return null;
  }

  getDynamicIcon(item: T): string | null | undefined {
    if (this.iconSrc() != null && this.iconSrc() != '') {
      return this.iconSrc();
    }

    if (this.dynamicIconPath() != null && this.dynamicIconPath() != '') {
      let object = item as any;
      return this.dynamicIconPath()!.split('.').reduce((acc, part) => acc && acc[part], object);
    }

    if (this.imageUrl() != null && this.imageUrl() != '') {
      return this.imageUrl();
    }

    if (this.dynamicImageUrlPath() != null && this.dynamicImageUrlPath() != '') {
      let object = item as any;
      return this.dynamicImageUrlPath()!.split('.').reduce((acc, part) => acc && acc[part], object);
    }
    return null;
  }

  getDynamicIconColor(item: T): string | null | undefined {
    if (this.iconColor()) {
      return this.iconColor();
    }

    if (this.dynamicIconColor() == null || this.dynamicIconColor() == '') {
      return this.iconColor();
    }
    let object = item as any;
    const color = this.dynamicIconColor()!.split('.').reduce((acc, part) => acc && acc[part], object);
    return color;
  }

  onItemClicked(item: T) {
    this.markAsTouched();
    const value = this.getPropertyId(item);
    if (value == this.formControl.value) {
      this.onChange(null);
      this.valueChanged.emit(null);
    } else {
      this.onChange(value);
      this.valueChanged.emit(item);
    }
  }

  customActionClicked() {
    this.onCustomActionClicked.emit();
  }
}

<div class="flex flex-col gap-3">
  <div class="flex gap-2">
    @for (star of stars; track star) {
      <div class="flex items-center justify-center" [ngClass]="getRatingStyle()">
        <app-svg-icon src="assets/icons/star.svg" [size]="24"
                      [ngClass]="formControl.value < star ? 'text-neutral-400' : 'text-warning'"
                      class="text-neutral-400 cursor-pointer" alt="" (click)="onStarClicked(star)"></app-svg-icon>
      </div>
    }
  </div>

  @if (formControl.errors && hasErrors) {
    <mat-error class="text-red-500">
      {{ formControl.errors | humanizeFormMessages: errorMessages() }}
    </mat-error>
  }
</div>



<div class="flex flex-col items-center space-y-2">
  <h1 class="text-h5 font-bold mb-2">Verify your identity</h1>
  <p class="text-body2 text-neutral-400 text-center pb-4">Please select an authentication method to proceed</p>

  <form [formGroup]="verificationForm" class="w-full">
    <app-dropdown
      formControlName="preferredMethod"
      [items]="preferredMethods()"
      [display]="'name'"
      [value]="'id'"
      [placeholder]="'Ex: SMS'"
      [fullWidth]="true"
      class="mb-6"></app-dropdown>
  </form>

  <div class="space-y-4 w-full">
    <app-button
      [fullWidth]="true"
      (click)="onSendCodeClicked()">Send Code
    </app-button>
  </div>
</div>

<app-base-dialog [title]="'Waitlist Confirmation'" [showAction]="false">
  <div body class="flex flex-col justify-center items-center gap-4">

    <span class="flex justify-center items-center self-center rounded-full h-12 w-12 bg-success bg-opacity-10 flex-shrink-0">
      <app-svg-icon [size]="96" class="text-success" src="assets/icons/check-circle.svg"></app-svg-icon>
    </span>

    <p class="text-subtitle1 text-neutral-500">You're on the waitlist!</p>

    <span class="flex gap-2 items-center">
      <p class="text-body2 text-black">Booking ID - </p>
      <p class="text-body2 text-info ">#{{ data.data.confirmationNumber }}</p>
    </span>

    <div class="flex items-center justify-center gap-6 w-full px-4 py-3 bg-neutral-50 rounded-2xl">

      <div class="flex gap-2 items-center">
        <app-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
        <p class="text-body2">{{ data.data.quotedWaitTime }} minutes</p>
      </div>
      <div class="flex gap-2 items-center">
        <app-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
        <p class="text-body2">{{data.data.partySize }} Guest(s)</p>
      </div>
    </div>

    <p class="text-body1 text-primary-500 mt-6 self-start">Invite Guest(s)</p>

    <form [formGroup]="inviteForm" class="flex gap-6 items-center w-full" (ngSubmit)="onInvitePressed()">
      <input formControlName="emailOrPhone" class="rounded py-3 px-3 border-neutral-400 focus:border-primary-500 focus:outline-primary-500 border w-full" placeholder="Phone (or) Email"/>
      <app-button [loading]="invitedGuestsState.loading()" iconSrc="assets/icons/invite-guest.svg" type="submit">Invite</app-button>
    </form>

    @for (receiver of invitedGuestsState.response()?.data?.receivers; track receiver) {
      <div class="self-start flex justify-start item-center py-2">
        <p class="text-body1">{{ receiver?.email ?? receiver?.phoneNumber ?? 'Unknown Guest' }}</p>
      </div>
    }

  </div>
</app-base-dialog>

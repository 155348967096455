<!--  dine in and pickup Order for mobile view-->
<div class="fixed bottom-0 z-50 lg:hidden py-3 bg-white shadow-1 w-full">
  <div class="flex justify-around">
    <div class="flex items-center gap-3 cursor-pointer px-6 py-4 rounded-full"
         [ngClass]="selectedDineInOption() == DineInOption.dineIn ? 'bg-neutral-100 text-primary-500' : 'bg-white text-primary-500'"
         (click)="onDineOptionSelected(DineInOption.dineIn)">
      <app-svg-icon src="assets/icons/dine-in.svg"
                    [ngClass]="selectedDineInOption() == DineInOption.dineIn ? 'text-primary-500' : 'text-neutral-500'">
      </app-svg-icon>
      <p class="text-body2">Dine In</p>
    </div>
<!--    <div class="flex items-center gap-3 cursor-pointer px-6 py-4 rounded-full"-->
<!--         [ngClass]="selectedDineInOption() == DineInOption.pickupOrder ? 'bg-neutral-100 text-primary-500' : 'bg-white text-neutral-500'"-->
<!--         (click)="onDineOptionSelected(DineInOption.pickupOrder)">-->
<!--      <app-svg-icon src="assets/icons/food-orders.svg"-->
<!--                    [ngClass]="selectedDineInOption() == DineInOption.pickupOrder ? 'text-primary-500' : 'text-neutral-500'">-->
<!--      </app-svg-icon>-->
<!--      <p class="text-body2">Pickup Order</p>-->
<!--    </div>-->
  </div>
</div>


@if (!isLocationSelected()) {
  <div class="flex flex-col items-center justify-center min-h-screen">
    <div class="text-center">
      <h2 class="text-h5 font-bold py-4">Find Nearby Restaurants</h2>
      <p class="text-body2 pb-4">Please select your location to find the best nearby restaurants</p>
    </div>
    <div class="mt-4">
      <button
        (click)="onSelectLocationClicked()"
        class="flex items-center justify-center px-4 py-2 bg-primary-500
         text-white rounded-md hover:bg-primary-600 transition-colors duration-300">
        <div class="flex items-center gap-2">
          <app-svg-icon src="assets/icons/location.svg" [size]="20"></app-svg-icon>
          <span>Select your location</span>
        </div>
      </button>
    </div>
  </div>
} @else {
  <div class="container mx-auto px-3 py-8">
  <div class="flex justify-center  whitespace-nowrap">
    @switch (selectedDineInOption()) {
      @case (DineInOption.dineIn) {
          <app-dine-in-global-filter></app-dine-in-global-filter>
      }
      @case (DineInOption.pickupOrder) {
        <div class="relative w-full">
          @if (showLeftArrow()) {
            <button
              (click)="scroll('left')"
              class="absolute left-0 top-1/2 -translate-y-1/2 bg-white rounded-full shadow-2 p-2 z-10">
              <app-svg-icon src="assets/icons/left-arrow.svg" class="w-6 h-6 text-neutral-600"></app-svg-icon>
            </button>
          }
          <div #cuisinesScrollContainer class="flex overflow-x-auto space-x-4 scrollbar-hide">
            @for (cuisine of cuisinesState.response()?.data || []; track cuisine) {
              <div
                class="flex-none flex items-center px-4 py-2 rounded-full text-sm whitespace-nowrap cursor-pointer"
                [ngClass]="{'bg-primary-500 text-white': isCuisineSelected(cuisine.name), 'bg-neutral-100 text-black': !isCuisineSelected(cuisine.name)}"
                (click)="onCuisineClicked(cuisine.name)">
                <img [src]="cuisine.image.url"
                     class="w-6 h-6 rounded-full mr-2 object-cover"/>
                {{ cuisine.name }}
              </div>
              }
            </div>

            @if (showRightArrow()) {
              <button
                (click)="scroll('right')"
                class="absolute right-0 top-1/2 -translate-y-1/2 bg-white rounded-full shadow-2 p-2 z-10"
              >
                <app-svg-icon src="assets/icons/right-arrow.svg" class="w-6 h-6 text-neutral-600"></app-svg-icon>
              </button>
            }
          </div>
        }
      }
    </div>

    <div class="flex justify-between items-center gap-3 py-4">
      <div class="flex gap-2 items-center">
        <app-filter-button (click)="onFilterClicked()"></app-filter-button>
        @if (restaurantsFilterService.selectedCuisines()) {
          @for (cuisineFilter of restaurantsFilterService.selectedCuisines(); track cuisineFilter) {
            <app-filter-chip
              (removeClicked)="removedCuisineFilter(cuisineFilter)">{{ cuisineFilter }}
            </app-filter-chip>
          }
        }

        @if (restaurantsFilterService.selectedPricePoint()) {
          <app-filter-chip
            (removeClicked)="removedPricePointFilter()">{{ numberToPricePoint(restaurantsFilterService.selectedPricePoint()) }}
          </app-filter-chip>
        }

        @if (restaurantsFilterService.selectedRating()) {
          <app-filter-chip (removeClicked)="removedRatingFilter()">
            {{ restaurantsFilterService.selectedRating() }}
          </app-filter-chip>
        }
      </div>
      <app-clear-filter (click)="clearFilter()"></app-clear-filter>
    </div>

    @if (restaurants().length > 0) {
      <div infiniteScroll
           [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="50"
           (scrolled)="getRestaurants(false)">

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-8">
          @for (restaurant of restaurants(); track restaurant) {
            <app-dine-in-restaurant-card [restaurant]="restaurant"
                                         [dineInOption]="restaurantsFilterService.dineInOption()"
                                         (restaurantSelected)="onRestaurantSelected($event)">
            </app-dine-in-restaurant-card>
          }
        </div>
      </div>
    }

    @if (restaurantsState.loading() || restaurantAvailabilities.loading()) {
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-8">
        <app-shimmer type="grid"></app-shimmer>
        <app-shimmer type="grid"></app-shimmer>
        <app-shimmer type="grid"></app-shimmer>
        <app-shimmer type="grid"></app-shimmer>
        <app-shimmer type="grid"></app-shimmer>
        <app-shimmer type="grid"></app-shimmer>
      </div>
    }

    @if (restaurants().length <= 0) {
      <div class="flex justify-center items-center p-28 col-span-full">
        <app-no-data message="No restaurant found"></app-no-data>
      </div>
    }
  </div>

  @if (pastReservation() && pastOrder()) {
    <div
      class="fixed bottom-4 border border-neutral-300 rounded-xl flex justify-center transform -translate-x-1/2 left-1/2 mx-auto bg-white gap-4 shadow-2 px-3 py-4 whitespace-nowrap">
      <div class="h-20 w-20 rounded-xl overflow-hidden relative">
        <app-network-image [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
                           [imageUrl]="pastReservation()?.restaurant?.banner?.url"
                           class="absolute inset-0 w-full h-full object-cover">
        </app-network-image>
      </div>
      <div class="flex items-center">
        <p class="text-neutral-500 text-body2">Enjoyed your meal at</p>
        <p class="text-primary-500 text-body2 font-semibold px-1">{{ pastReservation()?.restaurant?.restaurantName }}
          ?</p>
        <app-button (click)="onWriteReviewClicked()" appearance="outline" class="px-2">WRITE REVIEW</app-button>
        <app-svg-icon src="assets/icons/close-border.svg" class="text-neutral-400 px-1 cursor-pointer"
                      (click)="onNotInterestedClicked()"></app-svg-icon>
      </div>
    </div>
  }
}

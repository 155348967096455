import {Component, inject, input, OnInit} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CheckInResponse} from "../../../data/check-in/check-in-response";
import {
  OrderInformationResponse,
  OrderInformationResponseData
} from "../../../data/order-information/order-information-response";
import {DatePipe} from "@angular/common";
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";

@Component({
  selector: 'app-check-in-confirmation-overlay',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe,
    BaseDialogComponent,
  ],
  templateUrl: './check-in-confirmation-overlay.component.html',
  styleUrl: './check-in-confirmation-overlay.component.scss'
})
export class CheckInConfirmationOverlayComponent extends BaseComponent implements OnInit {
  data: OrderInformationResponseData = inject(MAT_DIALOG_DATA);


  dialogRef = inject(MatDialogRef<CheckInConfirmationOverlayComponent>);


  ngOnInit(): void {
  }


}

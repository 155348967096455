<app-base-dialog [title]="'Booking Confirmed'" [showAction]="false">
  <div body class="flex flex-col justify-center items-center gap-3">

    <span
      class="flex justify-center items-center self-center rounded-full h-12 w-12 bg-success bg-opacity-10 flex-shrink-0">
      <app-svg-icon [size]="36" class="text-success" src="assets/icons/check-circle.svg"></app-svg-icon>
    </span>

    <p class="text-subtitle1 text-neutral-500">Booking confirmed!</p>

    <span class="flex gap-2 items-center">
      <p class="text-body2 text-black">Booking ID - </p>
      <p class="text-body2 text-info ">#{{ data.data.confirmationNumber }}</p>
    </span>

    <div class="flex items-center justify-center gap-6 w-full px-4 py-3 bg-neutral-50 rounded-2xl">
      <div class="flex gap-2 items-center">
        <app-svg-icon src="assets/icons/calendar.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
        <p class="text-body2">{{ data.data.startTime | date:'d MMMM y' }}</p>
      </div>
      <div class="flex gap-2 items-center">
        <app-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
        <p class="text-body2">{{ data.data.startTime | date:'h:mm a' }}</p>
      </div>
      <div class="flex gap-2 items-center">
        <app-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
        <p class="text-body2">{{ data.data.partySize }} Guest(s)</p>
      </div>
    </div>


    <p class="text-body1 text-primary-500 mt-6 self-start">Invite Guest(s)</p>

    <form [formGroup]="inviteForm" class="flex gap-6 items-center w-full" (ngSubmit)="onInvitePressed()">
      <input formControlName="emailOrPhone"
             class="rounded py-3 px-3 border-neutral-400 focus:border-primary-500 focus:outline-primary-500 border w-full"
             placeholder="Phone (or) Email"/>
      <app-button [loading]="invitedGuestsState.loading()" iconSrc="assets/icons/invite-guest.svg" type="submit">
        Invite
      </app-button>
    </form>

    <div class="flex flex-col gap-4 self-start w-full">
      <p class="text-body2 text-primary-500">Invited Guests</p>
      @for (receiver of invitationReceivers(); track receiver) {
        <div class="flex items-center gap-4">
          <app-svg-icon class="text-neutral-500" src="assets/icons/account-circle.svg"></app-svg-icon>
          <p
            class="break-words whitespace-normal text-body2 max-w-xl min-w-xl">{{ receiver?.email ?? receiver?.phoneNumber ?? 'Unknown Guest' }}</p>
          <div class="grow"></div>
          <p class="px-3 py-1 text-caption rounded-full bg-error bg-opacity-10 text-error cursor-pointer" (click)="onCancelInvitationPressed(receiver)">Cancel</p>
        </div>
      }
      <div class="flex self-center w-full justify-center items-center">
        @if (invitedGuestsState.success() && invitationReceivers().length <= 0) {
          <app-no-data [size]="56" [message]="'No guests invited'"></app-no-data>
        }
      </div>
    </div>

  </div>
</app-base-dialog>

import {
  AfterContentInit,
  Component,
  EventEmitter,
  inject,
  input, output,
  Output,
} from '@angular/core';
import {BaseInputComponent} from "../_base/base-input/base-input.component";
import {
  FormControl, NgControl,
} from "@angular/forms";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {MatError} from "@angular/material/form-field";

@Component({
  selector: 'app-counter-input',
  standalone: true,
  imports: [
    BaseInputComponent,
    HumanizeFormMessagesPipe,
    MatError
  ],
  templateUrl: './counter-input.component.html',
  styleUrl: './counter-input.component.scss',
  providers: []
})
export class CounterInputComponent extends BaseControlValueAccessor implements AfterContentInit {

  title = input<string | null>();
  minValue = input<number | null>();
  maxValue = input<number | null>();
  appearance = input<'rounded' | 'flat'>('rounded');

  @Output()
  valueChanged = new EventEmitter<number>();

  ngControl = inject(NgControl, {optional: true, self: true});

  errorMessages = input<{ [key: string]: string }>({});

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  onCounterDecreased() {
    let value = Number(this.formControl.value);
    if(this.minValue() != null) {
      if(value <= this.minValue()!) {
        return;
      }
    }

    if (!this.disabled()) {
      this.markAsTouched();
      this.onChange(value - 1);
      this.valueChanged.emit(value - 1);
    }
  }

  onCounterIncreased() {
    let value = Number(this.formControl.value);
    if(this.maxValue() != null) {
      if(value >= this.maxValue()!) {
        return;
      }
    }

    if (!this.disabled()) {
      this.markAsTouched();
      this.onChange(value + 1);
      this.valueChanged.emit(value + 1);
    }
  }
}

import {Component, HostListener, inject, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {ToasterComponent} from "./shared/components/toast/components/toaster/toaster.component";
import {FooterComponent} from "./features/layout/footer/footer.component";
import {ResponsiveHelperComponent} from "./shared/components/responsive-helper/responsive-helper.component";
import {LoaderComponent} from "./shared/components/loader/loader.component";
import {Analytics} from "@angular/fire/analytics";
import {environment} from "../environments/environment";
import {EnvBannerComponent} from "./shared/components/env-banner/env-banner.component";
import {HeroSectionComponent} from "./features/landing/presentation/components/hero-section/hero-section.component";
import {
  Feature1SectionComponent
} from "./features/landing/presentation/components/feature-1-section/feature-1-section.component";
import {
  Feature2SectionComponent
} from './features/landing/presentation/components/feature-2-section/feature-2-section.component';
import {
  Feature3SectionComponent
} from './features/landing/presentation/components/feature-3-section/feature-3-section.component';
import {
  MobileAppSectionComponent
} from './features/landing/presentation/components/mobile-app-section/mobile-app-section.component';
import {Platform} from "@angular/cdk/platform";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {
  MobileAppDownloadDialogComponent
} from "./core/components/mobile-app-download-dialog/mobile-app-download-dialog.component";
import {DOCUMENT} from "@angular/common";
import {AppDataService} from "./core/services/app-data/app-data.service";
import {TopNavService} from "./features/layout/top-nav/top-nav.service";


@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, ToasterComponent, HeroSectionComponent, Feature1SectionComponent, Feature2SectionComponent, Feature3SectionComponent, MobileAppSectionComponent, FooterComponent, ResponsiveHelperComponent, LoaderComponent, EnvBannerComponent]
})
export class AppComponent implements OnInit {
  analytics: Analytics = inject(Analytics);
  protected readonly environment = environment;
  private router = inject(Router);
  private platform = inject(Platform);
  private bottomSheet = inject(MatBottomSheet);
  private document = inject(DOCUMENT);
  private appDataService = inject(AppDataService);
  private topNavService = inject(TopNavService);

  private previousUrl?: string;

  ngOnInit(): void {
    this.handleMobileDownloadOptions();
    this.handleRouteChange();
    this.initFavoriteRestaurants();
  }

  private handleMobileDownloadOptions() {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.bottomSheet.open(MobileAppDownloadDialogComponent);
    }
  }

  private handleRouteChange() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url.split('?')[0];

        if (currentUrl !== this.previousUrl) {
          this.previousUrl = currentUrl;
          this.document.documentElement.scrollTop = 0;
        }

        if (currentUrl === '/restaurants' ||
          currentUrl.startsWith('/pickup-restaurant-details') ||
          currentUrl.startsWith('/dine-in-restaurant-details')) {
          this.topNavService.showBookingOption.update(() => true);
        } else {
          this.topNavService.showBookingOption.update(() => false);
        }

        if (currentUrl === '/home') {
          this.topNavService.showRestaurantButton.update(() => true);
          this.topNavService.showRestaurantSuggestion.update(() => false);
        } else {
          this.topNavService.showRestaurantButton.update(() => false);
          this.topNavService.showRestaurantSuggestion.update(() => true);
        }

      }
    });
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.key === 'e' && event.metaKey) || (event.key === 'e' && event.ctrlKey)) {
      this.router.navigate(['/test']);
    }
    if ((event.key === 'j' && event.metaKey) || (event.key === 'j' && event.ctrlKey)) {
      this.router.navigate(['/restaurants']);
    }
  }

  private initFavoriteRestaurants() {
    this.appDataService.saveFavoriteRestaurants();
  }
}

import {Component, ElementRef, inject, input, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {CdkCopyToClipboard, Clipboard} from "@angular/cdk/clipboard";
import {ClipboardModule} from '@angular/cdk/clipboard';
import {FormsModule} from '@angular/forms';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {ToastService} from "../../../../../shared/components/toast/toast.service";
import {DOCUMENT} from "@angular/common";
import {openLink} from "../../../../../shared/utils/html-utils";

@Component({
  selector: 'app-share-link',
  standalone: true,
  imports: [
    BaseDialogComponent,
    AppSvgIconComponent,
    CdkCopyToClipboard
  ],
  templateUrl: './share-link.component.html',
  styleUrl: './share-link.component.scss'
})
export class ShareLinkComponent extends BaseComponent implements OnInit {

  data = inject(MAT_DIALOG_DATA);
  clipboard = inject(Clipboard);
  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);

  ngOnInit() {
  }

  onCopyLinkPressed() {
    this.clipboard.copy(this.data.url);
    this.toasterService.info('Link copied!');
  }

  onSmsClicked() {
    const smsLink = `sms:?body=${encodeURIComponent(this.data.message)}`;
    this.openLink(smsLink);
  }

  onEmailClicked() {
    const mailToLink = `mailto:?subject=${this.data.mailSubject}&body=${encodeURIComponent(this.data.message)}`;
    this.openLink(mailToLink);
  }


  onWhatsAppClicked() {
    const whatsAppLink = `https://wa.me/?text=${encodeURIComponent(this.data.message)}`;
    this.openLink(whatsAppLink, true);
  }

  private openLink(link: string, openInNewTab: boolean = false) {
    const anchorElement = this.document.createElement('a');
    anchorElement.setAttribute('href', link);
    if(openInNewTab) {
      anchorElement.setAttribute('target', '_blank');
    }
    anchorElement.style.display = 'none';
    this.elementRef.nativeElement.appendChild(anchorElement);
    anchorElement.click();
    this.elementRef.nativeElement.removeChild(anchorElement);
  }
}

import {Component, inject, OnInit, signal} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {CounterInputComponent} from "../../../../../shared/inputs/counter-input/counter-input.component";
import {
  DateSelectorInputComponent
} from "../../../../../shared/inputs/date-selector-input/date-selector-input.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TableAvailabilitiesRequest} from "../../../data/table-availabilities/table-availabilities-request";
import {TableAvailabilitiesResponse} from "../../../data/table-availabilities/table-availabilities-response";
import {
  TimeAvailabilityForView
} from "../../../../restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {State} from "../../../../../shared/base/base-state";
import {BookingsService} from "../../../data/bookings/bookings.service";
import {DatePipe} from "@angular/common";
import {
  BookingDetailsResponse,
  BookingDetailsResponseData
} from "../../../data/bookings/bookings/booking-details-response";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {OccasionsResponse} from "../../../../../core/services/common-data/occasions/occasions-response";
import {TagsResponse} from "../../../../../core/services/common-data/tags/tags-response";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {
  UpdateReservationRequest,
  UpdateReservationRequestParams
} from "../../../data/update-reservation/update-reservation-request";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {response} from "express";
import {
  MultiSelectionFieldComponent
} from "../../../../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {
  SeatingPreferenceResponse
} from "../../../../../core/services/common-data/seating-preference/seating-preference-response";
import {
  InputBackgroundComponent
} from "../../../../../shared/inputs/_base/input-background/input-background.component";

@Component({
  selector: 'app-update-reservation-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    CounterInputComponent,
    DateSelectorInputComponent,
    FormsModule,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    TextInputComponent,
    MultiSelectionFieldComponent,
    InputBackgroundComponent
  ],
  providers: [
    DatePipe
  ],
  templateUrl: './update-reservation-overlay.component.html',
  styleUrl: './update-reservation-overlay.component.scss'
})
export class UpdateReservationOverlayComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialogRef = inject(MatDialogRef);
  dialog = inject(MatDialog);
  datePipe = inject(DatePipe);
  bookingId: string = inject(MAT_DIALOG_DATA);
  bookingService = inject(BookingsService);
  bookingsService = inject(BookingsService);
  commonDataService = inject(CommonDataService);

  bookingDetailsState = new State<BookingDetailsResponse>();
  tableAvailabilitiesState = new State<TableAvailabilitiesResponse>();
  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  updateReservationState = new State<GenericResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();


  booking = signal<BookingDetailsResponseData | null>(null);
  availabilities = signal<TimeAvailabilityForView[]>([]);

  updateReservationForm!: FormGroup;

  ngOnInit(): void {
    this.getOccasions();
    this.getTags();
    this.getSeatingPreference();
    this.updateReservationForm = this.formBuilder.group({
      date: [new Date(), [Validators.required]],
      partySize: [0, [Validators.required]],
      time: [null, [Validators.required]],
      tags: [['NO']],
      occasion: 'NO',
      note: [''],
      seatingPreferences: []
    });
    this.getBookingDetails();
  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }
  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  getBookingDetails() {
    this.executeRequest<BookingDetailsResponse>({
      state: this.bookingDetailsState,
      request: this.bookingsService.getBookingDetails(this.bookingId),
      onSuccess: response => {
        this.booking.set(response.data);
        this.updateReservationForm.patchValue({
          date: new Date(response.data.startTime),
          partySize: response.data.partySize
        });

        this.getTableAvailabilities();
      }
    });
  }

  getTableAvailabilities() {
    let date = this.updateReservationForm.get('date')?.value;
    const booking = this.bookingDetailsState.response()?.data;
    const partySize = Number(this.updateReservationForm.get('partySize')?.value);
    let request: TableAvailabilitiesRequest = {
      restaurantId: this.booking()?.restaurant._id ?? '',
      year: date?.getFullYear(),
      month: date!.getMonth() + 1,
      day: date?.getDate(),
      partySize: partySize,
      tableIds: []
    };
    this.executeRequest<TableAvailabilitiesResponse>({
      state: this.tableAvailabilitiesState,
      request: this.bookingService.getTableAvailabilities(request),
      onSuccess: response => {
        let availabilities: TimeAvailabilityForView[] = [];
        response.data.forEach(shift => {
          shift.availabilities.forEach(time => {
            availabilities.push({
              shiftId: shift.shiftId,
              partySize: time.partySize,
              time: time.time,
              timeString: this.datePipe.transform(time.time, 'HH:mm'),
            });
          });
        });
        this.availabilities.set(availabilities);
      }
    });
  }

  onDateSelected($event: Date) {
    this.getTableAvailabilities();
  }

  onPartSizeChanged(event: number) {
    this.getTableAvailabilities();
  }

  onUpdateReservationClicked() {
    this.validateForm(this.updateReservationForm);
    if (this.updateReservationForm.invalid) {
      return;
    }

    const formValue = this.updateReservationForm.value;
    const selectedAvailability: TimeAvailabilityForView = formValue.time;
    const seatingOption = this.updateReservationForm.value.seatingPreferences?.name;
    const request: UpdateReservationRequest = {
      bookingId: this.booking()?._id,
      updateTypes: [
        "changePartySize",
        "changeOccasion",
        "changeTable",
        "changeNote",
        "changeTime",
        "changeTags",
        "changeStatus",
        "changeSeatingOption"
      ],
      params: {
        tableIds: [],
        shiftId: selectedAvailability.shiftId,
        partySize: formValue.partySize.toString(),
        time: selectedAvailability.time,
        tags: [formValue.tags.name],
        occasion: formValue.occasion.name,
        note: this.updateReservationForm.value.note,
        seatingOption: seatingOption
      }
    };

    this.executeRequest({
      state: this.updateReservationState,
      request: this.bookingsService.updateReservation(request),
      onSuccess: response => {
        this.dialogRef.close(UpdateReservationStatus.success);
      }
    });

  }
}

export enum UpdateReservationStatus {
  success,
  failed
}

import {Component, inject, OnInit} from '@angular/core';
import {InvitationsTabComponent} from "../../components/invitations-tab/invitations-tab.component";
import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {
    InvitationCardComponent
} from "../../components/invitation-card/invitation-card.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {InvitationsService} from "../../../data/invitations.service";
import {State} from "../../../../../shared/base/base-state";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  ReceivedInvitationResponse,
  ReceivedInvitationResponseData
} from "../../../data/invitations/received-invitation-response";

@Component({
  selector: 'app-sent-invitations',
  standalone: true,
    imports: [
        InvitationsTabComponent,
        NoDataComponent,
        InvitationCardComponent,
        ShimmerComponent
    ],
  templateUrl: './sent-invitations.component.html',
  styleUrl: './sent-invitations.component.scss'
})
export class SentInvitationsComponent extends BaseComponent implements OnInit{
  authStoreService = inject(AuthStoreService);
  invitationsService = inject(InvitationsService);

  receivedInvitationsState = new State<ReceivedInvitationResponse>();

  ngOnInit(): void {
    this.getSentInvitations();
  }

  private getSentInvitations() {
    const appUser = this.authStoreService.getAppUser();
    this.executeRequest<ReceivedInvitationResponse>({
      state: this.receivedInvitationsState,
      request: this.invitationsService.getSentInvitations(),
    });
  }

  onSentInvitationsClicked(receivedInvitation: ReceivedInvitationResponseData) {

  }
}

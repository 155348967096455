import {Component, inject, OnInit, signal} from '@angular/core';
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {LoginResponse} from "../../../data/login/login-response";
import {AuthService} from "../../../data/auth.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {RouterLink} from "@angular/router";
import {State} from "../../../../../shared/base/base-state";
import {AuthenticatedUserResponse} from "../../../data/authenticated-user/authenticated-user-response";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {LoginRequest} from "../../../data/login/login-request";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatHint,
    MatLabel,
    ButtonComponent,
    ReactiveFormsModule,
    JsonPipe,
    RouterLink,
    AsyncPipe,
    MatError,
    TextInputComponent,
    AppSvgIconComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseComponent implements OnInit {
  showPassword = signal(false);

  form!: FormGroup;

  loginState = new State<LoginResponse>();
  authenticatedUserState = new State<AuthenticatedUserResponse>();

  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private authStoreService = inject(AuthStoreService);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', Validators.required]
    });
  }

  loginClicked() {
    if (this.form.invalid) {
      return;
    }
    const request: LoginRequest = {
      email: this.form.value.email,
      password: this.form.value.password,
    };
    this.executeRequest<LoginResponse>({
      state: this.loginState,
      request: this.authService.login(request),
      onSuccess: response => {
        let isMfaEnabled = response.data.enabledMFA ?? false;
        if (isMfaEnabled) {
          this.router.navigate(['/mfa'], {
            queryParams: {
              userInfo: request.email,
              formatPhoneNumber: response.data.formatPhoneNumber,
              emailFormat: response.data.emailFormat
            }
          });
        } else {
          this.authStoreService.saveToken(response.data.refreshToken, response.data.token);
          this.handleLogin();
        }
      }
    });
  }

  handleLogin() {
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        if (response.data.userType == 'USER_TYPE_USER') {
          this.authStoreService.saveAppUser(response);
        } else {
          this.authStoreService.logout();
          this.toasterService.error('Invalid credentials, kindly use a valid credentials');
        }
      }
    });
  }

  togglePasswordVisibility() {
    this.showPassword.update(value => !value);
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../../shared/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {OccasionsResponse} from "../../../../../core/services/common-data/occasions/occasions-response";
import {TagsResponse, TagsResponseData} from "../../../../../core/services/common-data/tags/tags-response";
import {State} from "../../../../../shared/base/base-state";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {CounterInputComponent} from "../../../../../shared/inputs/counter-input/counter-input.component";
import {BookingsService} from "../../../data/bookings.service";
import {WaitTimesRequest} from "../../../data/wait-times/wait-times-request";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {WaitTimesResponse} from "../../../data/wait-times/wait-times-response";
import {BaseInputComponent} from "../../../../../shared/inputs/_base/base-input/base-input.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {
  MakeReservationRequestCustomerInfo} from "../../../data/make-reservation/make-reservation-request";
import {LoginUserType} from "../../../../../core/models/login-user-type";
import {
  GuestLoginData,
  GuestLoginOverlayComponent,
  GuestLoginStatus
} from "../../../../auth/presentation/overlays/guest-login-overlay/guest-login-overlay.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {
  JoinWaitListRequest
} from "../../../data/join-wait-list/join-wait-list-request";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {
  WaitlistConfirmationOverlayComponent
} from "../waitlist-confirmation-overlay/waitlist-confirmation-overlay.component";
import {Constants} from "../../../../../core/constants/constants";
import {
  MultiSelectionFieldComponent
} from "../../../../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {
  SeatingPreferenceResponse, SeatingPreferenceResponseData
} from "../../../../../core/services/common-data/seating-preference/seating-preference-response";

@Component({
  selector: 'app-join-wait-list-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    TextInputComponent,
    ButtonComponent,
    CounterInputComponent,
    BaseInputComponent,
    ShimmerComponent,
    MultiSelectionFieldComponent
  ],
  templateUrl: './join-wait-list-overlay.component.html',
  styleUrl: './join-wait-list-overlay.component.scss'
})
export class JoinWaitListOverlayComponent extends BaseComponent implements OnInit {

  data = inject(MAT_DIALOG_DATA);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef);
  authStoreService = inject(AuthStoreService);
  formBuilder = inject(FormBuilder);
  commonDataService = inject(CommonDataService);
  bookingService = inject(BookingsService);

  waitListForm!: FormGroup;

  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  waitTimesState = new State<WaitTimesResponse>();
  joinWaitListState = new State<GenericResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();


  ngOnInit(): void {
    this.waitListForm = this.formBuilder.group({
      partySize: [4, [Validators.required]],
      tags: [[]],
      occasion: [],
      note: [''],
      seatingPreferences: []

    });
    this.getOccasions();
    this.getTags();
    this.getWaitTimes();
    this.getSeatingPreference();

  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  onPartSizeChanged($event: number) {
    this.getWaitTimes();
  }

  private getWaitTimes() {
    const request: WaitTimesRequest = {
      restaurantId: this.data.restaurantId,
      partySize: this.waitListForm.value.partySize
    };
    this.executeRequest<WaitTimesResponse>({
      state: this.waitTimesState,
      request: this.bookingService.getWaitTimes(request)
    });
  }

  onJoinWaitListClicked() {
    this.validateForm(this.waitListForm);
    if (this.waitListForm.invalid) {
      return;
    }


    this.handleJoinWaitList();

  }

  handleJoinWaitList() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.joinWaitList(LoginUserType.appUser, null);
      return;
    }

    this.dialog.open(GuestLoginOverlayComponent, {
      ...Constants.defaultDialogConfig
    }).afterClosed().subscribe((result: GuestLoginData) => {
      if (result.status == GuestLoginStatus.success) {
        this.joinWaitList(LoginUserType.guestUser, result);
      }
    });
  }

  private joinWaitList(userType: LoginUserType, guestUserData: GuestLoginData | null) {
    const tableWaitTime = this.waitTimesState.response()?.data.tableWaitTime;
    if (tableWaitTime == null) {
      this.toasterService.error('Tables are not available at this moment, kindly contact the restaurant host.');
      return;
    }
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
      case LoginUserType.guestUser:
        customerInfo = {
          firstName: guestUserData?.firstName ?? '',
          phoneNumber: guestUserData?.phoneNumber ?? ''
        };
        break;
    }
    const waitTime = this.waitTimesState.response()?.data;

    const tagsValue = (this.waitListForm.value.tags ?? []) as TagsResponseData[];
    let tags = ['NO'];
    if(tagsValue.length > 0) {
      tags = tagsValue.map(x => x.name)
    }

    const occasion = this.waitListForm.value.occasion?.name ?? 'NO';

    const seatingOption = this.waitListForm.value.seatingPreferences?.name;


    const request: JoinWaitListRequest = {
      restaurantId: waitTime?.restaurantId,
      shiftId: waitTime?.shiftId,
      partySize: waitTime?.availabilities?.partySize,
      autoAssignTable: true,
      customerInfo: customerInfo,
      note: this.waitListForm.value.note,
      tags: tags,
      occasion: occasion,
      quotedWaitTime: tableWaitTime,
      time: waitTime?.availabilities?.time,
      seatingOption: seatingOption,
      coverSource: 'GUEST_WEB_APP'
    };

    this.executeRequest({
      state: this.joinWaitListState,
      request: this.bookingService.joinWaitList(request),
      onSuccess: response => {
        this.dialogRef.close();
        this.dialog.open(WaitlistConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        });
      }
    });
  }
}

import {inject, Injectable} from '@angular/core';
import {State} from "../base/base-state";
import {Observable} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastService} from "../components/toast/toast.service";
import {LoaderService} from "../components/loader/loader.service";

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  toasterService = inject(ToastService);
  loaderService = inject(LoaderService);
  executeRequest<T>({
                      handleSuccess = false,
                      handleError = true,
                      state,
                      request,
                      onLoading,
                      onSuccess,
                      onFailed,
                      successMessage,
                      errorMessage,
                      showLoader = false
                    }: {
    handleSuccess?: boolean,
    handleError?: boolean,
    state: State<T>;
    request: Observable<T> | Promise<T>;
    onLoading?: () => void,
    onSuccess?: (response: T) => void;
    onFailed?: (error: HttpErrorResponse) => void;
    successMessage?: string;
    errorMessage?: string;
    showLoader?: boolean
  }): void {

    /**
     * Observable Handler
     */

    if (successMessage) {
      handleSuccess = true;
    }

    if (this.isObservable(request)) {
      if (onLoading != null) {
        onLoading();
      }

      if (showLoader) {
        this.loaderService.show();
      }
      state.notifyLoading();

      request?.subscribe({
        next: (res: any) => {
          if (handleSuccess) {
            this.toasterService.success(successMessage ?? res?.data?.message ?? res?.data?.data?.message ?? 'Request successful');
          }
          state.notifySuccess(res);
          if (onSuccess != null) {
            onSuccess(res);
          }
        },
        error: (err: any) => {
          if (handleError) {
            this.toasterService.error(errorMessage ?? err ?? 'Unknown error, please contact administrator.');
          }
          if (showLoader) {
            this.loaderService.hide();
          }
          state.notifyError(err);
          if (onFailed != null) {
            onFailed(err);
          }
        },
        complete: () => {
          if (showLoader) {
            this.loaderService.hide();
          }
        },
      });

    }


    /**
     * Promise Handler
     */
    if (this.isPromise(request)) {
      if (onLoading != null) {
        onLoading();
      }

      if (showLoader) {
        this.loaderService.show();
      }
      state.notifyLoading();

      request.then((value) => {
        if (handleSuccess) {
          this.toasterService.success(successMessage ?? 'Request successful');
        }

        state.notifySuccess(value);
        if (onSuccess != null) {
          onSuccess(value);
        }
      }).catch((error) => {
        if (handleError) {
          this.toasterService.error(errorMessage ?? error ?? 'Unknown error, please contact administrator.');
        }
        state.notifyError(error);
        if (onFailed != null) {
          onFailed(error);
        }
      }).finally(() => {
        if (showLoader) {
          this.loaderService.hide();
        }
      });
    }
  }

  isObservable<T>(input: any): input is Observable<T> {
    return input && typeof input.subscribe === 'function';
  }


  isPromise<T>(input: any): input is Promise<T> {
    return input && typeof input.then === 'function';
  }
}

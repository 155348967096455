import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-env-banner',
  standalone: true,
  imports: [],
  templateUrl: './env-banner.component.html',
  styleUrl: './env-banner.component.scss'
})
export class EnvBannerComponent {

  protected readonly environment = environment;
}

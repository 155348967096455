<div class="flex flex-col sm:flex-row gap-5 shadow-1 bg-white border border-neutral-200 rounded-xl overflow-hidden cursor-pointer">
  <app-network-image class="hidden sm:block h-auto w-32 object-cover"
                     [imageUrl]="booking().restaurant.banner.url">
  </app-network-image>

  <div class="flex flex-grow p-4 sm:py-3 sm:pr-4">
    <div class="flex flex-col flex-grow gap-2">
      <div class="flex flex-row justify-between items-center gap-2">
        <p class="text-body2 text-primary-500">{{ booking().restaurant.restaurantName }}</p>
        <app-booking-type [type]="booking().type" class="sm:hidden"></app-booking-type>
        <app-booking-type [type]="booking().type" class="hidden sm:block"></app-booking-type>
      </div>
      <p class="text-body2 text-neutral-400">
        {{ booking().restaurant.location.address1 }}, {{ booking().restaurant.location.city }}
        , {{ booking().restaurant.location.state }}
      </p>
      <div class="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
        <div class="flex items-center gap-2">
          <app-svg-icon src="assets/icons/calendar.svg" [size]="16" class="text-primary-500"></app-svg-icon>
          <p class="text-caption text-neutral-600">{{ booking().startTime | date:'d MMM y - h:mm a' }}</p>
        </div>
        <div class="flex items-center gap-2">
          <app-svg-icon src="assets/icons/guest.svg" [size]="16" class="text-primary-500"></app-svg-icon>
          <p class="text-caption text-neutral-600">{{ booking().partySize }} Guests</p>
        </div>
      </div>

      <div class="flex flex-row items-center justify-between sm:justify-start gap-4">
        <p class="sm:hidden rounded-md px-2 py-1 text-caption bg-opacity-5"
           [ngClass]="getBookingColor(booking().status)">{{ getBookingStatusText(booking().status) }}</p>
        <p class="hidden sm:block rounded-md px-3 py-2 text-caption bg-opacity-5"
           [ngClass]="getBookingColor(booking().status)">{{ getBookingStatusText(booking().status) }}</p>
        <div class="hidden sm:block flex-grow"></div>
        <div class="flex items-center gap-4">
          <div class="flex justify-center items-center h-10 w-10 rounded-full hover:bg-neutral-50 cursor-pointer"
               (click)="onCallClicked(booking().restaurant.phoneNumber)">
            <app-svg-icon src="assets/icons/phone.svg" class="text-blue-500"></app-svg-icon>
          </div>
          @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
            <app-direction-button appearance="iconButton"
                                  [destinationLatitude]="destinationGeoPoint().latitude"
                                  [destinationLongitude]="destinationGeoPoint().longitude">
            </app-direction-button>
          }
        </div>
      </div>
    </div>
  </div>
</div>

<div class="w-full flex flex-col items-center space-y-6">
  <h1 class="text-2xl font-bold">Verification</h1>
  <p class="text-sm text-neutral-500 text-center">
    Please enter the code that we sent to {{ codeSentTo() }} verify your identity.
  </p>
  <div class="w-full">
    <app-text-input
      label="Enter code"
      [fullWidth]="true"
      [(ngModel)]="code">
    </app-text-input>

    <app-button
      [fullWidth]="true"
      (click)="onVerifyClicked()">Verify
    </app-button>
  </div>

  <p class="text-sm text-neutral-500">Didn't receive your code?</p>
  <button class="text-sm text-primary-600 hover:underline" (click)="onResendCodeClicked()">
    Resend it
  </button>
</div>

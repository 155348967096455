<div class="bg-white h-screen overflow-hidden">

  <div class="grid grid-cols-1 lg:grid-cols-2 h-dvh w-screen">
    <div class="flex justify-center bg-white h-dvh overflow-y-scroll">

      <div
        class="fixed top-4 left-4 flex items-center gap-3
        cursor-pointer hover:bg-neutral-100 py-2 p-4
        rounded-full transition duration-300 ease-in-out"
        routerLink="/home">
        <app-svg-icon src="assets/icons/chevron-left.svg" class="text-neutral-400" [size]="8"></app-svg-icon>
        <p class="text-body2 text-neutral-500">Back to Home</p>
      </div>

      <div class="grow mx-8 sm:mx-16 py-6 my-auto">
        <div class="flex flex-col gap-3 text-primary-700 items-center justify-center">
          <img src="assets/images/branding/app-logo.png" class="w-16" alt="Logo">
          <!--<p class="text-body uppercase">Smart Dining</p>-->
        </div>
        <router-outlet/>
      </div>


    </div>
    <div class="relative hidden flex-1 items-center justify-center lg:flex bg-black bg-opacity-55">
      <div class="absolute inset-0 auth-bg"></div>
      <div class="absolute inset-0 bg-black bg-opacity-50"></div>
      <div class="z-10 max-w-2xl space-y-4 p-8 text-center text-white">
        <h1 class="text-h4">Discover the best restaurants, cafés, and bars in your area</h1>
        <p class="text-body2">
          Smart Dining makes it easy to find the best restaurants for any occasion, book tables or order online with a
          click of a button, and take advantage of exclusive deals.
        </p>
      </div>
    </div>
  </div>
</div>

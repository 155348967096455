import {AuthService} from '../../../data/auth.service';
import {Component, OnInit, inject} from '@angular/core';
import {BaseComponent} from '../../../../../shared/base/base-component';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {GenericResponse} from '../../../../../core/models/generic-response';
import {State} from "../../../../../shared/base/base-state";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";

@Component({
  selector: 'app-signup',
  standalone: true,
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
  imports: [ButtonComponent, ReactiveFormsModule, TextInputComponent]
})
export class SignupComponent extends BaseComponent implements OnInit {
  form!: FormGroup;
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  signup() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<GenericResponse>({
      state: new State<GenericResponse>(),
      request: this.authService.signUp(this.form.value),
      handleSuccess: true,
      onSuccess: response => {
        this.router.navigate(["/activate-account"], {queryParams: {email: this.form.value.email}});
      }
    })
  }
}

<form [formGroup]="checkInForm" class="flex flex-col space-y-4 w-full max-w-xl mx-auto p-4 sm:p-6">

  <p class="sm:text-body2 text-center text-primary-800 uppercase pb-4">Curb side check in</p>

  <app-text-input formControlName="vehicleType" type="text"
                  placeholder="Volvo" label="Vehicle Name"
                  class="w-full"></app-text-input>
  <app-text-input formControlName="vehicleColor" type="text"
                  placeholder="Pink" label="Vehicle Color"
                  class="w-full"></app-text-input>
  <app-text-input formControlName="parkingNumber" type="text"
                  placeholder="Number 4" label="Parking Slot Number"
                  class="w-full"></app-text-input>

  <app-button type="submit" [fullWidth]="true" appearance="primary"
              class="sm:py-3 text-body2" (click)="onConfirmClicked()">Confirm
  </app-button>

</form>

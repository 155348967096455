import {Component, inject, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "../../../../auth/data/auth.service";
import {AddressService} from "../../../data/address.service";
import {AddressListResponse, Address} from "../../../data/address-list/address-list-response";
import {State} from "../../../../../shared/base/base-state";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {NgClass} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatDialog} from "@angular/material/dialog";
import {AddOrUpdateAddressComponent} from "./add-or-update-address/add-or-update-address.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {DeleteAddressComponent} from "./delete-address/delete-address.component";
import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {arrayToCSV} from "../../../../../shared/utils/string-utils";
import {Constants} from "../../../../../core/constants/constants";

@Component({
  selector: 'app-addresses',
  standalone: true,
  imports: [
    ButtonComponent,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    NgClass,
    MatProgressSpinner,
    AppSvgIconComponent,
    NoDataComponent,
    ShimmerComponent
  ],
  templateUrl: './addresses.component.html',
  styleUrl: './addresses.component.scss'
})
export class AddressesComponent extends BaseComponent implements OnInit {

  private dialog = inject(MatDialog);
  private tokenService = inject(AuthService);
  private authService = inject(AuthService);
  private addressService = inject(AddressService);
  form!: FormGroup
  addressState = new State<AddressListResponse>();

  ngOnInit(): void {
    this.getAddressDetails();
  }

  onAddAddressClicked() {
    const dialogRef = this.dialog.open(AddOrUpdateAddressComponent, {
      ...Constants.defaultDialogConfig
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAddressDetails();
      }
    });
  }

  onEditClicked(address: Address) {
    const dialogRef = this.dialog.open(AddOrUpdateAddressComponent, {
      ...Constants.defaultDialogConfig,
      data: address
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAddressDetails();
      }
    });
  }

  getAddressDetails() {
    this.executeRequest({
      state: this.addressState,
      request: this.addressService.getSavedAddresses()
    });
  }

  onDeleteClicked(address: Address) {
    const dialogRef = this.dialog.open(DeleteAddressComponent, {
      ...Constants.defaultDialogConfig,
      data: address._id,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAddressDetails();
      }
    });
  }


  protected readonly arrayToCSV = arrayToCSV;
}

import {Component, input, signal} from '@angular/core';
import {BaseComponent} from "../../../../../shared/base/base-component";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-invitations-tab',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './invitations-tab.component.html',
  styleUrl: './invitations-tab.component.scss'
})
export class InvitationsTabComponent extends BaseComponent {

  isSentInvitation = input(false);
  isReceivedInvitation = input(false);

  onReceivedInvitationClicked() {
    this.router.navigate(['/invitations/received-invitations']);
  }

  onSenInvitationClicked() {
    this.router.navigate(['/invitations/sent-invitations']);
  }
}

@switch (type()) {
  @case ('TYPE_RESERVATION') {
    <p class="booking-type"> Reservation </p>
  }
  @case ('TYPE_WAITLIST') {
    <p class="booking-type"> Waitlist </p>
  }
  @case ('TYPE_WALK_IN') {
    <p class="booking-type"> Walk-in </p>
  }
  @default {
    <p class="booking-type"> Unknown </p>
  }
}

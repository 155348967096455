import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {OccasionsResponse} from "./occasions/occasions-response";
import {TagsResponse} from "./tags/tags-response";
import {PricePointResponse} from "./price-point/price-point-response";
import {GetBookingSurveyQuestionsResponse} from "./get-survey-questions/get-booking-survey-questions-response";
import {SeatingPreferenceResponse} from "./seating-preference/seating-preference-response";
import {URLs} from "../../base/urls";
import {ContactUsComponent} from "../../../features/landing/presentation/pages/contact-us/contact-us.component";
import {ContactUsResponse} from "./contact-us/contact-us-response";
import {ContactUsRequest} from "./contact-us/contact-us-request";
import {WaiterTipsResponse} from "./waiter-tips/waiter-tips-response";
import {OrderDetailsResponse} from "./order-details/order-details-response";
import {CurbsideVehicleTypesResponse} from "./curbside-vehicle-types/curbside-vehicle-types-response";
import {VehicleColorsResponse} from "./vehicle-colors/vehicle-colors-response";
import {ApplyPromoCodeRequest} from "./apply-promo-code/apply-promo-code-request";
import {ApplyPromoCodeResponse} from "./apply-promo-code/apply-promo-code-response";
import {RemovePromoCodeRequest} from "./remove-promo-code/remove-promo-code-request";
import {RemovePromoCodeResponse} from "./remove-promo-code/remove-promo-code-response";
import {EnableUserMFARequest} from "./MFA/enable-mfa/enable-user-MFA-request";
import {EnableUserMFAResponse} from "./MFA/enable-mfa/enable-user-MFA-response";
import {DisableUserMFARequest} from "./MFA/disable-mfa/disable-user-MFA-request";
import {DisableUserMFAResponse} from "./MFA/disable-mfa/disable-user-MFA-response";
import {SendMFAConfirmCodeResponse} from "./MFA/send-mfa-confirm-code/send-MFA-confirm-code-response";
import {SendMFAConfirmCodeRequest} from "./MFA/send-mfa-confirm-code/send-MFA-confirm-code-request";
import {ConfirmMFAUserCodeRequest} from "./MFA/confirm-mfa-user-code/confirm-MFA-user-code-request";

@Injectable({
  providedIn: 'root'
})
export class CommonDataService extends BaseApiService {

  getOccasions() {
    return this.fromFile<OccasionsResponse>('assets/json/app-data/occasions.json');
  }

  getTags() {
    return this.fromFile<TagsResponse>('assets/json/app-data/tags.json');
  }

  getPricePoints() {
    return this.fromFile<PricePointResponse>('assets/json/app-data/price-points.json');
  }

  getSeatingPreference() {
    return this.fromFile<SeatingPreferenceResponse>('assets/json/app-data/seating-preferences.json');
  }


  contactUs(request: ContactUsRequest) {
    return this.post<ContactUsResponse>(URLs.contactUs, request);
  }

  getWaiterTips() {
    return this.fromFile<WaiterTipsResponse>('assets/json/app-data/waiter-tips.json');
  }

  getOrderDetails() {
    return this.fromFile<OrderDetailsResponse>('assets/json/app-data/order-details.json');
  }

  getCurbsideVehicleTypes() {
    return this.fromFile<CurbsideVehicleTypesResponse>('assets/json/app-data/curbside-vehicle-types.json');
  }

  getVehicleColors() {
    return this.fromFile<VehicleColorsResponse>('assets/json/app-data/vehicle-colors.json');
  }

  applyPromoCode(request: ApplyPromoCodeRequest) {
    return this.post<ApplyPromoCodeResponse>(URLs.applyPromoCode, request);
  }

  removePromoCode(request: RemovePromoCodeRequest) {
    return this.post<RemovePromoCodeResponse>(URLs.removePromoCode, request);
  }

  enableUserMFA(userId: null, request: EnableUserMFARequest) {
    return this.put<EnableUserMFAResponse>(URLs.enableUserMFA(userId), request)
  }

  disableUserMFA(userId: null, request: DisableUserMFARequest) {
    return this.put<DisableUserMFAResponse>(URLs.disableUserMFA(userId), request)
  }

  sendMFAConfirmCode(request: SendMFAConfirmCodeRequest) {
    return this.post<SendMFAConfirmCodeResponse>(URLs.sendMFAConfirmCode, request)
  }

  confirmMFAUserCode(request: ConfirmMFAUserCodeRequest) {
    return this.post<SendMFAConfirmCodeResponse>(URLs.confirmMFAUserCode, request)
  }

}

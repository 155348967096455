import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";

import {BaseComponent} from "../../../../../../shared/base/base-component";
import {deleteObject} from "@angular/fire/storage";
import {AddressService} from "../../../../data/address.service";
import {response} from "express";
import {ToastService} from "../../../../../../shared/components/toast/toast.service";
import {ButtonComponent} from "../../../../../../shared/components/button/button.component";
import {State} from "../../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../../core/models/generic-response";



@Component({
  selector: 'app-delete-address',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    ButtonComponent
],
  templateUrl: './delete-address.component.html',
  styleUrl: './delete-address.component.scss'
})
export class DeleteAddressComponent extends BaseComponent implements OnInit {
  private addressService = inject(AddressService);
  dialogRef = inject(MatDialogRef<DeleteAddressComponent>);
  data: string = inject(MAT_DIALOG_DATA);
  state = new State<GenericResponse>();

  ngOnInit(): void {

  }

  onYesClicked() {
    this.executeRequest({
      state: this.state,
      request: this.addressService.deleteAddress(this.data),
      successMessage: 'Address deleted successfully!',
      handleSuccess: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }
    });
  }

  onCloseClicked() {
    this.dialogRef.close();
  }

  onNoClicked() {
    this.dialogRef.close();
  }

}

export interface TimeObject {
  timeString: string;
  time: Date;
  minutes: number;
  hour24: number;
  minute: number;
}

export function generateTimeList(startTime: Date | null, endTime: Date | null): TimeObject[] {
  const timeList: TimeObject[] = [];
  const st = new Date();
  if(startTime == null) {
    st.setHours(0, 0, 0, 0);
  }

  const et = new Date();
  if(endTime == null) {
    et.setHours(23, 45, 0, 0);
  }

  let currentTime = new Date(st);

  while (currentTime <= et) {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;

    const timeString = `${displayHours < 10 ? '0' + displayHours : displayHours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

    timeList.push({
      timeString,
      time: new Date(currentTime),
      minutes: hours * 60 + minutes,
      hour24: hours,
      minute: minutes
    });

    currentTime.setMinutes(currentTime.getMinutes() + 30); // Increment by 30 minutes
  }

  return timeList;
}



export  function roundToNextFifteenMinutes(date: Date): Date {
  // Create a new Date object to avoid modifying the original date
  const result = new Date(date.getTime());

  // Get the minutes of the given date
  const minutes = result.getMinutes();

  // Calculate how many minutes to add to reach the next quarter-hour
  const minutesToAdd = 15 - (minutes % 15);

  // Add the calculated minutes to the date
  result.setMinutes(minutes + minutesToAdd, 0, 0);

  // If the minutes to add are 15, it means we need to round to the next hour
  if (minutesToAdd === 15) {
    result.setHours(result.getHours() + 1, 0, 0);
  }

  return result;
}

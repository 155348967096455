import {Component, input} from '@angular/core';
import {type} from "node:os";

@Component({
  selector: 'app-order-type',
  standalone: true,
  imports: [],
  templateUrl: './order-type.component.html',
  styleUrl: './order-type.component.scss'
})
export class OrderTypeComponent {

  type = input.required<string>();
}

<app-base-input [title]="title()" [fullWidth]="fullWidth()" [isRequiredField]="hasRequiredValidator()">
    <div input class="flex flex-wrap justify-start gap-3">
        @for (item of items(); track item) {
            <div class="flex items-center rounded-lg px-4 py-2 gap-3 cursor-pointer"
                 (click)="onItemClicked(item)"
                 [ngClass]="isItemSelected(item) ? 'border-2 border-primary-600' : 'border-2 border-neutral-300'">
                @if (getDynamicIcon(item)) {
                    @switch (getImageType(item)) {
                        @case ('url') {
                            <img class="object-contain" height="20px" width="20px" [src]="getDynamicIcon(item)" alt="">
                        }
                        @case ('svg') {
                            <app-svg-icon [src]="getDynamicIcon(item)!" [size]="18"
                                          [ngStyle]="{'color': getDynamicIconColor(item)}"></app-svg-icon>
                        }
                    }
                }
                <p class="text-body2 text-black">{{ getDisplayString(item) }}</p>
            </div>
        }

        @if (customActionText()) {
            <div (click)="customActionClicked()"
                 class="flex items-center rounded-lg px-3 py-3 gap-4 cursor-pointer bg-primary-500">
                <p class="text-caption text-white">{{ customActionText() }}</p>
            </div>
        }

        @if (state()?.success() && items().length <= 0) {
            <p class="text-caption text-red-700">{{ noDataMessage() ?? 'No data found' }}</p>
        }

        @if ((state()?.failed()) && items().length <= 0) {
            <p
                    class="text-caption text-red-700">{{ (state()?.error() != null || state()?.error() != '') ? state()?.error() : noDataMessage() ?? 'No data found' }}</p>
        }

        @if (state()?.loading()) {
            <app-shimmer class="w-full" type="multiline"></app-shimmer>
        }
    </div>

    <div error>
        @if (formControl.errors && hasErrors) {
            <p class="text-caption text-red-500">
                {{ formControl.errors | humanizeFormMessages: errorMessages() }}
            </p>
        }
    </div>
</app-base-input>

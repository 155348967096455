export class URLs {

  // Auth
  static signUp = '/auth/v1/signUp';
  static activateAccount = '/auth/v1/confirmEmailSignUp';
  static login = '/auth/v1/signIn';
  static refreshToken: '/auth/v1/refreshToken';
  static forgotPassword = '/auth/v1/forgetPassword';
  static resetPassword = '/auth/v1/resetPassword';
  static getAuthenticatedUser = '/auth/v1/getAuthenticatedUser';
  static sendGuestConfirmCode = '/auth/v1/sendGuestConfirmCode';
  static verifyGuestCode = '/auth/v1/confirmGuestCode';

  // MFA
  static enableUserMFA(userId: string | null) {
    return `/api/v1/users/enableUserMFA/${userId}`;
  }

  static disableUserMFA(userId: string | null) {
    return `/api/v1/users/disableUserMFA/${userId}`;
  }

  static sendMFAConfirmCode = '/auth/v1/sendMFAConfirmCode';
  static confirmMFAUserCode = '/auth/v1/confirmMfaUserCode';

  // Restaurant
  static getRestaurants = '/api/v1/restaurants/search';
  static getCuisines = '/api/v1/cuisines/getCuisines';
  static getRestaurantAvailabilities = '/api/v1/bookings/getTimeAvailabilitiesForRestaurants';
  static getTimeAvailabilities = '/api/v1/bookings/getTimeAvailabilities';
  static getTableWaitTimes = '/api/v1/bookings/getWaitTimes';

  static getPhotoGallery(restaurantId: string) {
    return `/api/v1/restaurants/getPhotoGallery/${restaurantId}`;
  }

  // Restaurant reviews
  static writeRestaurantReview = '/api/v1/reviews/createReview';
  static getReviewByRestaurant = '/api/v1/reviews/getReviewByRestaurant';

  static getRestaurantDetails(restaurantId: string) {
    return `/api/v1/restaurants/getRestaurantDetails/${restaurantId}`;
  }

  // Profile
  static updateProfileDetails(userId: string) {
    return `/api/v1/users/updateUser/${userId}`;
  }

  // Booking
  static makeReservation = '/api/v1/reservations/makeReservation';
  static joinWaitList = '/api/v1/waitList/addGuestToWaitList';
  static updateReservation = '/api/v1/reservations/updateReservation';

  static getBookingSurveyQuestions = '/api/v1/surveys/getBookingSurveyQuestions';

  static submitCustomerSurvey = '/api/v1/surveys/submitCustomerSurvey';

  // Address
  static getSavedAddress = '/api/v1/users/getAddress';

  static deleteAddress(addressId: string) {
    return `/api/v1/users/deleteAddress/${addressId}`;
  }

  static saveAddress = '/api/v1/users/addAddress';

  static sendConfirmCode = '/auth/v1/sendConfirmCode';

  static editAddress(addressId: string) {
    return `/api/v1/users/updateAddress/${addressId}`;
  }

  //Favorites

  static getFavoriteRestaurants(userId: string) {
    return `/api/v1/favorites/getListFavorite/${userId}`;
  }

  static deleteFavoriteRestaurant(favoriteId: string) {
    return `/api/v1/favorites/deleteFavorite/${favoriteId}`;
  }

  static createFavorite = '/api/v1/favorites/createFavorite';


  // Invitations
  static sendInvite = '/api/v1/invitations/sendInvite';
  static acceptInvitation = '/api/v1/invitations/acceptInvite';
  static declineInvitation = '/api/v1/invitations/declineInvite';
  static cancelInvitation = '/api/v1/invitations/cancelInvite';
  static getReceiveInvitation = '/api/v1/invitations/getInvitesReceived';
  static getSentInvitation = '/api/v1/invitations/getInvitesSent';


  static bookingInvitations(bookingId: string) {
    return `/api/v1/invitations/getBookingInvites/${bookingId}`;
  }

  //bookings
  static getUpcomingReservation = '/api/v1/reservations/user/getUpcomingReservationsV2';
  static getPastReservation = '/api/v1/reservations/user/pastReservationsV2';

  static setBookingStatusNotInterested = '/api/v1/surveys/setBookingSurveyNotInterested';


  //cancelReservation
  static cancelBookings(reservationId: string) {
    return `/api/v1/reservations/cancelReservation/${reservationId}`;
  }


  //booking details
  static getBookingDetails(bookingId: string) {
    return `/api/v1/bookings/getBookingDetails/${bookingId}`;
  }


  //Orders
  static getPickupSchedule = '/api/v1/orderPickup/getPickupSchedule';

  static getPickupMenus(restaurantId: string) {
    return `/api/v1/menus/getPickupMenus/${restaurantId}`;
  }


  static contactUs = '/api/v1/contactUs/addContactUs';


  static getDiningMenus(restaurantId: string) {
    return `/api/v1/menus/getDiningMenus/${restaurantId}`;
  }

  static getOrderPrice = '/api/v1/orders/getOrderPrice';
  static placeOrder = '/api/v1/orders/placeOrder';

  static getCompletedOrdersByCustomerId = '/api/v1/orders/getCompletedCustomerOrders';
  static getUpcomingOrders = '/api/v1/orders/getActiveCustomerOrders';

  static getOrderInformation(orderId: string) {
    return `/api/v1/orders/getOrder/${orderId}`;
  }

  static getOrderSurveyQuestions = '/api/v1/surveys/getOrderSurveyQuestions';

  static cancelOrder(orderId: string) {
    return `/api/v1/orders/cancelOrder/${orderId}`;
  }

  static checkInPickup(orderId: string) {
    return `/api/v1/orders/checkInPickup/${orderId}`;
  }

  // Payments

  static createPaymentIntent = '/api/payments/createPaymentIntentForOrder';
  static createSetupPaymentIntent = '/api/v1/payments/createSetupPaymentIntent';
  static confirmPaymentIntent = '/api/v1/payments/confirmPaymentIntent';

  static deletePaymentMethod(paymentMethodId: string) {
    return `/api/v1/payments/detachPaymentMethod/${paymentMethodId}`;
  }

  static getSavedCards = '/api/v1/payments/getPaymentMethods';
  static createPaymentIntentForOrder = '/api/v1/payments/createPaymentIntentForOrder';
  static capturePaymentIntent = '/api/v1/payments/capturepaymentintent';

  static setDefaultPaymentMethod(paymentMethodId: string) {
    return `/api/v1/payments/setDefaultPaymentMethod/${paymentMethodId}`;
  }

  static suggestRestaurants = '/api/v1/restaurants/suggest';

  static applyPromoCode = '/api/v1/promotionalCodes/applyPromotionalCode';
  static removePromoCode = '/api/v1/promotionalCodes/removePromotionalCode';


}


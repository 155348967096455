<form [formGroup]="form" (ngSubmit)="loginClicked()" class="flex flex-col gap-4">
  <p class="text-subtitle1 text-center text-primary-800 uppercase">Welcome Back</p>
  <p class="text-body2 text-center text-neutral-400">Sign in with email and password that you entered during your
    registration</p>

  <app-text-input formControlName="email" type="email" placeholder="you@company.com" label="Email"></app-text-input>

  <div class="relative">
    <app-text-input formControlName="password"  [type]="showPassword() ? 'text' : 'password'"
                    placeholder="Password" label="Password"></app-text-input>
    <button
      type="button"
      class="absolute right-2 top-4"
      (click)="togglePasswordVisibility()">
      @if(showPassword()) {
        <app-svg-icon src="assets/icons/hide-password.svg" class="text-neutral-400"></app-svg-icon>
      } @else {
        <app-svg-icon src="assets/icons/show-password.svg" class="text-neutral-400"></app-svg-icon>
      }
    </button>
  </div>

  <app-button type="submit" [fullWidth]="true" appearance="primary"
              [loading]="loginState.loading() || authenticatedUserState.loading()">Login
  </app-button>

  <p class="text-body2 text-neutral-500 self-end cursor-pointer" routerLink="/forgot-password">Forgot Password?</p>

  <div class="flex item-center justify-center gap-2">
    <p class="text-subtitle1 text-neutral-600">Don't have an account?</p>
    <p class="text-subtitle1 text-primary-500 font-bold cursor-pointer" routerLink="/signup">Sign up</p>
  </div>

</form>

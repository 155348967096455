export class ModifierGroupSelectionType {
  static MULTI_CHOICE = 'MULTI CHOICE';
  static SINGLE_CHOICE = 'SINGLE CHOICE';
  static QUANTITY = 'QUANTITY';
}

export class FoodItemPriceType {
  static BASE_PRICE = 'BASE_PRICE';
  static SIZE_PRICE = 'SIZE_PRICE';
}

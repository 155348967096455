export function getBookingStatusTextColor(status: string): string {
  switch (status.toUpperCase()) {
    case 'BOOKED':
      return 'text-[#18B53D]';
    case 'CONFIRMED':
      return 'text-[#18B53D]';
    case 'ARRIVED':
      return 'text-[#16C098]';
    case 'PARTIAL ARRIVED':
      return 'text-[#C075D1]';
    case 'SEATED':
      return 'text-[#2F79F5]';
    case 'APPETIZER':
      return 'text-[#CD5C5C]';
    case 'ENTREE':
      return 'text-[#00B0FF]';
    case 'DESSERT':
      return 'text-[#455A64]';
    case 'RUNNING LATE':
      return 'text-[#E29E43]';
    case 'CANCEL':
      return 'text-[#B71C1C]';
    case 'FINISHED':
      return 'text-[#16C098]';
    case 'CHECK PAID':
      return 'text-[#E29E43]';
    case 'CHECK DROPPED':
      return 'text-[#18B53D]';
    case 'DONE':
      return 'text-[#16C098]';
    case 'NO SHOW':
      return 'text-[#868686]';
    default:
      return 'text-[#000000]';
  }
}

export function getBookingStatusBgColor(status: string): string {
  switch (status.toUpperCase()) {
    case 'BOOKED':
      return 'bg-[#18B53D]';
    case 'CONFIRMED':
      return 'bg-[#18B53D]';
    case 'ARRIVED':
      return 'bg-[#16C098]';
    case 'PARTIAL ARRIVED':
      return 'bg-[#C075D1]';
    case 'SEATED':
      return 'bg-[#2F79F5]';
    case 'APPETIZER':
      return 'bg-[#CD5C5C]';
    case 'ENTREE':
      return 'bg-[#00B0FF]';
    case 'DESSERT':
      return 'bg-[#455A64]';
    case 'RUNNING LATE':
      return 'bg-[#E29E43]';
    case 'CANCEL':
      return 'bg-[#B71C1C]';
    case 'FINISHED':
      return 'bg-[#16C098]';
    case 'CHECK PAID':
      return 'bg-[#E29E43]';
    case 'CHECK DROPPED':
      return 'bg-[#18B53D]';
    case 'DONE':
      return 'bg-[#16C098]';
    case 'NO SHOW':
      return 'bg-[#868686]';
    default:
      return 'bg-[#000000]';
  }
}


export function getBookingStatusText(status: string): string {
  switch (status.toUpperCase()) {
    case 'BOOKED':
      return 'BOOKED';
    case 'CONFIRMED':
      return 'CONFIRMED';
    case 'ARRIVED':
      return 'ARRIVED';
    case 'PARTIAL ARRIVED':
      return 'PARTIAL ARRIVED';
    case 'SEATED':
      return 'SEATED';
    case 'APPETIZER':
      return 'APPETIZER';
    case 'ENTREE':
      return 'ENTREE';
    case 'DESSERT':
      return 'DESSERT';
    case 'RUNNING LATE':
      return 'RUNNING LATE';
    case 'CANCEL':
      return 'CANCELLED';
    case 'FINISHED':
      return 'FINISHED';
    case 'CHECK PAID':
      return 'CHECK PAID';
    case 'CHECK DROPPED':
      return 'CHECK DROPPED';
    case 'DONE':
      return 'DONE';
    case 'NO SHOW':
      return 'NO SHOW';
    default:
      return 'UNKNOWN';
  }
}

export function getBookingStatusSummary(status: string) {
  switch (status.toUpperCase()) {
    case 'BOOKED':
    case 'CONFIRMED':
    case 'RUNNING LATE':
    case 'PARTIAL ARRIVED':
    case 'ARRIVED':
    case 'SEATED':
    case 'APPETIZER':
    case 'ENTREE':
    case 'DESSERT':
    case 'CHECK DROPPED':
    case 'CHECK PAID':
      return BookingStatusSummary.confirmed;
    case 'FINISHED':
    case 'DONE':
      return BookingStatusSummary.finished;
    case 'NO SHOW':
    case 'CANCEL':
      return BookingStatusSummary.cancelled;
    default:
      return BookingStatusSummary.confirmed;
  }
}

export enum BookingStatusSummary {
  confirmed,
  cancelled,
  finished
}

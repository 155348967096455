<div class="container mx-auto px-4 py-8 sm:py-14">
  <form [formGroup]="contactUsForm" class="flex flex-col justify-center gap-4 max-w-[900px] mx-auto">
    <p class="text-subtitle1 text-center text-primary-800 uppercase">Get in touch</p>
    <p class="text-body2 text-center text-neutral-400 max-w-2xl mx-auto">We'd love to hear from you! Whether you have a question, feedback, or need assistance, our team is here to help.</p>
    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
      <app-text-input [fullWidth]="true" formControlName="firstName" type="text" placeholder="First Name" label="First Name"
                      class="flex-1"></app-text-input>
      <app-text-input [fullWidth]="true" formControlName="lastName" type="text" placeholder="Last Name" label="Last Name"
                      class="flex-1"></app-text-input>
    </div>
    <app-text-input [fullWidth]="true" formControlName="email" type="email" placeholder="user@company.com"
                    label="Email"></app-text-input>
    <app-text-input [fullWidth]="true" formControlName="phoneNumber" type="tel" placeholder="Ex: +1 123 4567 XXX"
                    label="Phone Number"></app-text-input>
    <app-text-area-input [fullWidth]="true" formControlName="feedback" class="w-full" label="Feedback / issue"
                         placeholder="Please write your feedback / issue."></app-text-area-input>
    <app-button (click)="onSendMessageClicked()" [loading]="contactUsState.loading()" [fullWidth]="true" class="mt-4">Send Message</app-button>
  </form>
</div>

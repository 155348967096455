import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopNavService {
  showBookingOption = signal(false);
  showRestaurantButton = signal(false);
  showRestaurantSuggestion = signal(false);
}

import {Component, inject, OnInit} from '@angular/core';

import {BaseComponent} from "../../../../../shared/base/base-component";
import {State} from "../../../../../shared/base/base-state";
import {
  BookingsResponse, BookingsResponseData
} from "../../../data/bookings/bookings/bookings-response";
import {BookingsService} from "../../../data/bookings/bookings.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {BookingsRequest} from "../../../data/bookings/common/bookings-request";

import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";

import {BookingsTabComponent} from "../../components/bookings-tab/bookings-tab.component";

import {BookingCardComponent} from "../../components/booking-card/booking-card.component";

@Component({
  selector: 'app-past-reservations',
  standalone: true,
  imports: [
    NoDataComponent,
    ShimmerComponent,
    BookingsTabComponent,
    BookingCardComponent
],
  templateUrl: './past-reservations.component.html',
  styleUrl: './past-reservations.component.scss'
})
export class PastReservationsComponent extends BaseComponent implements OnInit {

  bookingsService = inject(BookingsService);
  authStoreService = inject(AuthStoreService);

  bookingState = new State<BookingsResponse>();

  ngOnInit(): void {
    this.getPastBookings();
  }

  getPastBookings() {
    const appUser = this.authStoreService.getAppUser()?.data;
    const request: BookingsRequest = {
      phoneNumber: appUser?.phoneNumber,
      email: appUser?.email
    };
    this.executeRequest({
      state: this.bookingState,
      request: this.bookingsService.getPastReservation(request)
    });
  }

  onPastReservationClicked(booking: BookingsResponseData) {
    this.router.navigate([`/booking/${booking._id}`]);
  }
}

import {inject, Injectable, PLATFORM_ID, signal} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {ServerStorage} from "./server-storage";
import {AuthenticatedUserResponse} from "../../../features/auth/data/authenticated-user/authenticated-user-response";
import {Router} from "@angular/router";
import {AppDataService} from "../app-data/app-data.service";
import {BehaviorSubject} from "rxjs";
import {
  LoginStatus
} from "../../../features/auth/presentation/overlays/user-login-overlay/user-login-overlay.component";

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {

  private platformId = inject(PLATFORM_ID);
  storage!: Storage;

  private static REFRESH_TOKEN_KEY = 'refresh_token';
  private static ACCESS_TOKEN_KEY = 'access_token';
  private static USER_DATA = 'app_user_data';

  private isAuthenticatedSignal = signal<boolean | null>(false);
  isAuthenticated = this.isAuthenticatedSignal.asReadonly();

  $loginStatus = new BehaviorSubject<LoginStatus>(LoginStatus.loggedOut);

  private redirectUrl: string = '/';
  private router = inject(Router);

  constructor() {
    this.storage = isPlatformBrowser(this.platformId) ? localStorage : new ServerStorage();
    const user = this.getAppUser();
    const token = this.getToken();
    if (user != null && token != null) {
      this.isAuthenticatedSignal.update((c) => true);
    }
  }

  setRedirectUrl(url: string | null) {
    this.redirectUrl = url ?? '/restaurants';
  }


  saveToken(refreshToken: string, accessToken: string) {
    this.storage.setItem(AuthStoreService.REFRESH_TOKEN_KEY, refreshToken);
    this.storage.setItem(AuthStoreService.ACCESS_TOKEN_KEY, accessToken);
  }

  getToken() {
    return {
      refreshToken: this.storage.getItem(AuthStoreService.REFRESH_TOKEN_KEY),
      accessToken: this.storage.getItem(AuthStoreService.ACCESS_TOKEN_KEY)
    };
  }

  removeUserData() {
    this.storage.removeItem(AuthStoreService.REFRESH_TOKEN_KEY);
    this.storage.removeItem(AuthStoreService.ACCESS_TOKEN_KEY);
    this.storage.removeItem(AuthStoreService.USER_DATA);
    this.storage.removeItem(AppDataService.ADDRESS);
    this.storage.removeItem(AppDataService.FAVORITE_RESTAURANTS);
  }

  saveAppUser(user: AuthenticatedUserResponse, gotoFallbackUrl = true) {
    this.storage.setItem(AuthStoreService.USER_DATA, JSON.stringify(user));
    this.isAuthenticatedSignal.update((c) => true);
    this.$loginStatus.next(LoginStatus.loginSuccess);
    if (gotoFallbackUrl) {
      this.gotoFallbackUrl();
    }
  }

  getAppUser(): AuthenticatedUserResponse | null {
    const userJsonString = this.storage.getItem(AuthStoreService.USER_DATA);
    if (userJsonString == null) {
      return null;
    }
    return JSON.parse(userJsonString);
  }

  gotoFallbackUrl() {
    this.router.navigateByUrl(this.redirectUrl);
  }

  logout() {
    this.removeUserData();
    this.isAuthenticatedSignal.update((c) => false);
    this.$loginStatus.next(LoginStatus.loggedOut);
  }

  isAppUserLoggedIn(): boolean {
    const user = this.getAppUser();
    if (user) {
      return true;
    }
    return false;
  }
}

<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error">Cancel Reservation</p>
    <p class="text-body2">Are you sure, do you want to cancel the reservation?</p>
    <div class="flex justify-end gap-4 mt-3">
      <app-button appearance="outline" (click)="onNoClicked()">No
      </app-button>
      <app-button buttonColor="bg-error"
                  (click)="onYesClicked()">Yes
      </app-button>
    </div>
  </div>
</mat-dialog-content>











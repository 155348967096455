export class CreditCardImageUtils {
  static getCardImage(cardType: string | undefined) {
    switch (cardType) {
      case 'amex':
        return 'assets/images/payment/amex.svg';
      case 'cartes_bancaires':
        return 'assets/images/payment/cb.svg';
      case 'diners':
        return 'assets/images/payment/diners.svg';
      case 'discover':
        return 'assets/images/payment/discover.svg';
      case 'jcb':
        return 'assets/images/payment/jcb.svg';
      case 'mastercard':
        return 'assets/images/payment/mastercard.svg';
      case 'unionpay':
        return 'assets/images/payment/unionpay.svg';
      case 'visa':
        return 'assets/images/payment/visa.svg';
      default:
        return 'assets/images/payment/credit-card.svg';
    }
  }
}

import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {State} from "../../../../../shared/base/base-state";
import {CheckInResponse} from "../../../data/check-in/check-in-response";
import {OrderInformationResponse} from "../../../data/order-information/order-information-response";
import {CheckInRequest} from "../../../data/check-in/check-in-request";
import {
  CheckInConfirmationOverlayComponent
} from "../check-in-confirmation-overlay/check-in-confirmation-overlay.component";
import {Constants} from "../../../../../core/constants/constants";

@Component({
  selector: 'app-check-in-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    TextInputComponent
  ],
  templateUrl: './check-in-overlay.component.html',
  styleUrl: './check-in-overlay.component.scss'
})
export class CheckInOverlayComponent extends BaseComponent implements OnInit {
  orderId = input<string>('');

  private fb = inject(FormBuilder);
  pickupOrderService = inject(PickupOrderService);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef<CheckInOverlayComponent>);
  data = inject(MAT_DIALOG_DATA);


  checkInState = new State<CheckInResponse>();
  orderDetailsState = new State<OrderInformationResponse>();

  checkInForm!: FormGroup;


  ngOnInit(): void {
    this.checkInForm = this.fb.group({
      vehicleType: ['', [Validators.required]],
      vehicleColor: ['', [Validators.required]],
      parkingNumber: ['', [Validators.required]]
    });
  }

  onConfirmClicked() {
    if (this.checkInForm.invalid) {
      return;
    }
    const request: CheckInRequest = {
      parkingNumber: this.checkInForm.value.parkingNumber,
      vehicleType: this.checkInForm.value.vehicleType,
      vehicleColor: this.checkInForm.value.vehicleColor,
    };
    this.executeRequest<CheckInResponse>({
      state: this.checkInState,
      request: this.pickupOrderService.checkInPickup(this.data.orderId, request),
      showLoader: true,
      onSuccess: response => {
        this.getOrderDetails(this.data.orderId);
        this.dialogRef.close(true);
      }
    });
  }

  getOrderDetails(orderId: string) {
    this.executeRequest<OrderInformationResponse>({
      state: this.orderDetailsState,
      request: this.pickupOrderService.getOrderDetails(orderId),
      onSuccess: response => {
        this.dialog.open(CheckInConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response.data,
        });
      }
    });

  }

}

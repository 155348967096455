import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime',
  standalone: true
})
export class MinutesToTimePipe implements PipeTransform {

  transform(minutes?: number): string {
    if (minutes  === undefined || minutes === null || minutes < 0 || minutes > 1440) {
      return 'N/A';
    }

    const hours = Math.floor(minutes! / 60);
    const remainingMinutes = minutes! % 60;

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    const formattedMinutes = String(remainingMinutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

}

export function calculateDistance(latitude1: number, longitude1: number, latitude2: number, longitude2: number) {
  const earthRadiusMiles = 3958.8;
  const earthRadiusKilometers = 6371;

  const dLat = toRadians(latitude2 - latitude1);
  const dLon = toRadians(longitude2 - longitude1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(latitude1)) *
    Math.cos(toRadians(latitude2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceMiles = earthRadiusMiles * c;
  const distanceKilometers = earthRadiusKilometers * c;

  return {
    miles: distanceMiles,
    kilometers: distanceKilometers
  };

}


function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {State} from "../../../../../shared/base/base-state";
import {LoginResponse} from "../../../data/login/login-response";
import {AuthenticatedUserResponse} from "../../../data/authenticated-user/authenticated-user-response";
import {AuthService} from "../../../data/auth.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-user-login-overlays',
  standalone: true,
  imports: [
    BaseDialogComponent,
    TextInputComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './user-login-overlay.component.html',
  styleUrl: './user-login-overlay.component.scss'
})
export class UserLoginOverlayComponent extends BaseComponent implements OnInit {

  form!: FormGroup;
  loginState = new State<LoginResponse>();
  authenticatedUserState = new State<AuthenticatedUserResponse>();
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private authStoreService = inject(AuthStoreService);
  dialogRef = inject(MatDialogRef);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', Validators.required]
    });
  }

  loginClicked() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<LoginResponse>({
      state: this.loginState,
      request: this.authService.login(this.form.value),
      onSuccess: response => {
        this.authStoreService.saveToken(response.data.refreshToken, response.data.token);
        this.handleLogin();
      }
    });
  }

  handleLogin() {
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        if (response.data.userType == 'USER_TYPE_USER') {
          this.authStoreService.saveAppUser(response, false);
          this.dialogRef.close(LoginStatus.loginSuccess);
        } else {
          this.authStoreService.logout();
          this.toasterService.error('Invalid credentials, kindly use a valid credentials');
        }
      }
    });
  }
}

export enum LoginStatus {
  loggedOut,
  loginSuccess,
  loginFailed
}

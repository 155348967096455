<div class="flex-inline relative hover:bg-neutral-50 py-2 px-4 rounded-lg select-none cursor-pointer"
     (clickOutside)="outsideClicked()">
  <div class="flex gap-4 items-center" (click)="togglePopup()">
    <div class="flex flex-col gap-2">
      <p class="text-body2 text-neutral-500">{{ title() }}</p>
      <p class="text-caption sm:text-body2">{{ (formControl.value != null && formControl.value != '') ? getProperty(formControl.value) : '-' }}</p>
    </div>
    <app-svg-icon src="assets/icons/chevron-down.svg" class="text-neutral-400" [size]="12"></app-svg-icon>
  </div>
  @if (isPopUpOpen()) {
    <div class="absolute z-50 mt-6 min-w-72 transform -translate-x-1/3 rounded-md bg-white shadow-2 p-3 max-h-60 overflow-y-scroll">
      <div class="flex flex-col gap-2">
        <p class="text-body2 text-neutral-600">Time</p>
        <div class="grid grid-cols-3 w-full gap-2">
          @for (item of items(); track item) {
            <div class="inline-flex justify-center items-center cursor-pointer" (click)="onItemClicked(item)">
              <p
                class="text-caption hover:border-primary-500 border-2 border-neutral-200 hover:border-2 p-2 rounded">{{ getProperty(item) }}</p>
            </div>
          }
        </div>
        @if(items().length <= 0) {
          <span class="text-neutral-400 block px-4 py-4 text-sm select-none" role="menuitem" tabindex="-1">No options available</span>
        }
      </div>
    </div>
  }
</div>

<div>
  @if (formControl.errors && hasErrors) {
    <p class="text-caption text-red-500">
      {{ formControl.errors | humanizeFormMessages: errorMessages() }}
    </p>
  }
</div>

@if (isError()) {
  <img
    [src]="placeHolderUrl()"
    class="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-105" alt="Image">
} @else {
  <img
    [src]="sourceUrl()"
    (error)="handleImageError()" alt=""
    class="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-105">
}

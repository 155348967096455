import {AfterContentInit, Component, EventEmitter, inject, input, OnInit, Output, signal} from '@angular/core';
import {FormControl, NgControl, ReactiveFormsModule} from "@angular/forms";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {elementAt} from "rxjs";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";


@Component({
    selector: 'app-checkbox',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatCheckbox,
    ],
    templateUrl: './checkbox.component.html',
    styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent extends BaseControlValueAccessor implements OnInit, AfterContentInit {
    title = input<string | null>();

    id = signal<string>('');

    @Output()
    valueChanged = new EventEmitter<boolean>();

    ngControl = inject(NgControl, {optional: true, self: true});

    errorMessages = input<{ [key: string]: string }>({});

    constructor() {
        super();
        if (this.ngControl) {
            this.ngControl!.valueAccessor = this;
        }
    }

    ngAfterContentInit(): void {
        let formControl = this.ngControl?.control as FormControl;
        if (formControl) {
            this.formControl = this.ngControl?.control as FormControl;
        }
    }

    ngOnInit(): void {
        this.id.set(this.getId());
    }

    onCheckboxSelected($event: Event, enterKeyPressed: boolean = false) {
        if (!this.disabled()) {
            this.markAsTouched();
            let checkbox = $event.target as HTMLInputElement;
            let value = ($event.target as HTMLInputElement).checked;
            if (enterKeyPressed) {
                value = !value;
                checkbox.checked = value;
            }
            this.onChange(value);
            this.valueChanged.emit(value);
        }
    }

    private getId(): string {
        const randomNumber = Math.floor(1000 + Math.random() * 9000);
        return `checkbox-${randomNumber.toString()}`;
    }

    handleKeydown($event: KeyboardEvent) {
        if ($event.key == 'Enter') {
            this.onCheckboxSelected($event, true);
        }
    }
}

import {Component, computed, ElementRef, inject, input, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {DatePipe, DecimalPipe, DOCUMENT, NgClass} from "@angular/common";
import {DirectionButtonComponent} from "../../../../../core/components/direction-button/direction-button.component";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";

import {ReactiveFormsModule} from "@angular/forms";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";

import {BaseComponent} from "../../../../../shared/base/base-component";
import {State} from "../../../../../shared/base/base-state";
import {
  OrderInformationResponse,
  OrderInformationResponseDataChecks
} from "../../../data/order-information/order-information-response";
import {arrayToCSV} from "../../../../../shared/utils/string-utils";
import {openLink} from "../../../../../shared/utils/html-utils";
import {GeoPoint} from "../../../../../core/services/location-service/location.service";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {
  getOrderStatusSummary,
  getOrderStatusText,
  getOrderStatusTextColor,
  getOrderTypes,
  OrderStatusSummary
} from "../../../utilities/order-status-utils";
import {MatDialog} from "@angular/material/dialog";
import {OrderSurveyOverlayComponent} from "../../overlay/order-survey-overlay/order-survey-overlay.component";
import {Constants} from "../../../../../core/constants/constants";
import {
  CancelOrderAction,
  CancelOrderConfirmationOverlayComponent
} from "../../overlay/cancel-order-confirmation-overlay/cancel-order-confirmation-overlay.component";
import {CancelOrderRequest} from "../../../data/cancel-order/cancel-order-request";
import {CancelOrderResponse} from "../../../data/cancel-order/cancel-order-response";

import {CheckInOverlayComponent} from "../../overlay/check-in-overlay/check-in-overlay.component";

@Component({
  selector: 'app-order-information',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    DatePipe,
    DirectionButtonComponent,
    NetworkImageComponent,
    ReactiveFormsModule,
    ShimmerComponent,
    DecimalPipe,
    NgClass
],
  templateUrl: './order-information.component.html',
  styleUrl: './order-information.component.scss'
})
export class OrderInformationComponent extends BaseComponent implements OnInit {
  protected readonly arrayToCSV = arrayToCSV;
  protected readonly getOrderStatusText = getOrderStatusText;
  protected readonly getOrderTypes = getOrderTypes;
  protected readonly getOrderStatusSummary = getOrderStatusSummary;
  protected readonly OrderStatusSummary = OrderStatusSummary;

  cancelOrderState = new State<CancelOrderResponse>();
  orderDetailsState = new State<OrderInformationResponse>();


  orderId = input<string>('');

  document = inject(DOCUMENT);

  elementRef = inject(ElementRef);
  pickupOrderService = inject(PickupOrderService);
  dialog = inject(MatDialog);


  order = signal<OrderInformationResponse | null>(null);
  checkInfo = signal<OrderInformationResponseDataChecks | null>(null);


  ngOnInit() {
    this.getOrderInformation();
  }

  getOrderInformation() {
    this.executeRequest<OrderInformationResponse>({
      state: this.orderDetailsState,
      request: this.pickupOrderService.getOrderDetails(this.orderId()),
      onSuccess: response => {
        this.order.set(response);
        let checks = response.data.checks ?? [];
        if (checks.length > 0) {
          this.checkInfo.set(checks[0]);
        }
      }
    });
  }

  onCallClicked() {
    openLink(`tel:${this.order()?.data?.restaurant.phoneNumber}`, false, this.document, this.elementRef);
  }

  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.order()?.data?.restaurant?.location?.location?.coordinates[1],
      longitude: this.order()?.data?.restaurant?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  isPastOrder = computed(() => {
    const order = this.orderDetailsState.response()?.data;
    const currentDate = new Date();
    if (order?.booking?.startTime != null) {
      const bookingStartTime = new Date(order?.booking?.startTime);
      return bookingStartTime.getTime() < currentDate.getTime();
    }

    return null;
  });


  getStatusColor(): string {
    return getOrderStatusTextColor(this.order()?.data?.orderStatus ?? '');
  }


  onRateYourOrderClicked() {
    this.dialog.open(OrderSurveyOverlayComponent, {
      ...Constants.defaultDialogConfig,
      data: this.order()?.data?._id
    }).afterClosed().subscribe((result: OrderSurveyStatus) => {
      if (result == OrderSurveyStatus.success) {
        this.getOrderInformation();
      }
    });
  }

  cancelOrder() {
    const orderId = this.orderId() ?? '';
    const request: CancelOrderRequest = {
      orderId: orderId
    };
    this.executeRequest({
      state: this.cancelOrderState,
      request: this.pickupOrderService.cancelOrder(orderId, request),
      showLoader: true,
      successMessage: 'Order cancelled successfully',
      onSuccess: response => {
        this.getOrderInformation();
      }
    });
  }


  onCancelOrderClicked() {
    this.dialog.open(CancelOrderConfirmationOverlayComponent, {
      ...Constants.defaultDialogConfig,
    }).afterClosed()
      .subscribe((data: CancelOrderAction) => {
        switch (data) {
          case CancelOrderAction.confirmed:
            this.cancelOrder();
            break;
          case CancelOrderAction.rejected:
            break;
        }
      });
  }

  onCheckInClicked() {
    this.dialog.open(CheckInOverlayComponent, {
      ...Constants.defaultDialogConfig,
      data: {orderId: this.orderId()}
    }).afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.getOrderInformation();
      }
    });
  }

}

export enum OrderSurveyStatus {
  success = 1,
  failed,
}

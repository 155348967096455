import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  PLATFORM_ID, signal,
  ViewChild
} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

// @ts-ignore
import {} from '@types/google.maps';
import {AppDataService} from "../../../../../core/services/app-data/app-data.service";
import {State} from "../../../../../shared/base/base-state";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  GeoPoint,
  LocationAddress,
  LocationService
} from "../../../../../core/services/location-service/location.service";
import {SavedAddress} from "../../../../../core/services/app-data/saved-address.model";
import {LocalFavoriteRestaurantComponent} from "../local-favorite-restaurant/local-favorite-restaurant.component";
import {
  LocationSelectionOverlayComponent
} from "../../../../common/presentation/overlay/location-selection-overlay/location-selection-overlay.component";
import {Constants} from "../../../../../core/constants/constants";
import {
  SelectAddressOverlayComponent
} from "../../../../common/presentation/overlay/select-address-overlay/select-address-overlay.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {MatDialog} from "@angular/material/dialog";
import {
  RestaurantSearchResponseData, TimeAvailability
} from "../../../../restaurants/data/restaurant-search/restaurant-search-response";

@Component({
  selector: 'app-hero-section',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    LocalFavoriteRestaurantComponent
  ],
  templateUrl: './hero-section.component.html',
  styleUrl: './hero-section.component.scss'
})
export class HeroSectionComponent extends BaseComponent implements OnInit, AfterViewInit {
  locationService = inject(LocationService);
  appDataService = inject(AppDataService);
  authStoreService = inject(AuthStoreService);
  private platformId = inject(PLATFORM_ID);
  dialog = inject(MatDialog);

  restaurants = signal<RestaurantSearchResponseData[]>([]);

  protected readonly DineInOption = DineInOption;

  @ViewChild('addressInputField') addressInputField?: ElementRef;

  addressState = new State<any>();

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initPlacesAutoComplete();
    this.handleSavedAddress();
  }

  private handleSavedAddress() {
    let location = this.appDataService.getAddress();
    if(this.addressInputField) {
      this.addressInputField.nativeElement.value = location?.address || '';
    }
  }

  onGpsClicked() {
    this.executeRequest<GeoPoint>({
      state: new State<GeoPoint>(),
      request: this.locationService.getCurrentLocation(),
      showLoader: true,
      onSuccess: response => {
        this.getAddress(response.latitude!, response.longitude!);
      }
    });
  }

  initPlacesAutoComplete() {
    if (isPlatformBrowser(this.platformId)) {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addressInputField!.nativeElement,
        {
          componentRestrictions: {country: ['US', 'BJ', 'SN', 'IN']},
          types: ['geocode'],
        }
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const formattedAddress = place.formatted_address;
        const placeName = place.name;
        const latitude = place.geometry?.location?.lat();
        const longitude = place.geometry?.location?.lng();

        this.saveAddress(formattedAddress, latitude, longitude);
      });
    }
  }

  saveAddress(address?: string, latitude?: number, longitude?: number) {
    const addressToSave: SavedAddress = {
      address: address,
      latitude: latitude,
      longitude: longitude
    };
    this.appDataService.saveAddress(addressToSave);
  }

  getAddress(latitude: number, longitude: number) {
    this.executeRequest<LocationAddress>({
      state: this.addressState,
      request: this.locationService.reverseGeocode(latitude, longitude),
      showLoader: true,
      onSuccess: response => {
        if (this.addressInputField && response.formattedAddress) {
          this.addressInputField.nativeElement.value = response.formattedAddress;
          this.saveAddress(response.formattedAddress, latitude, longitude);
        }
      }
    });
  }

  onFindRestaurantsClicked() {
    const currentLocation = this.appDataService.getAddress();
    if (currentLocation != null) {
      this.router.navigate(['/restaurants']);
      return;
    }

    if (this.authStoreService.isAuthenticated()) {
      this.dialog.open(LocationSelectionOverlayComponent, {
        ...Constants.defaultDialogConfig
      }).afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/restaurants']);
        }
      });
    } else {
      this.dialog.open(SelectAddressOverlayComponent, {
        ...Constants.defaultDialogConfig
      }).afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/restaurants']);
        }
      });
    }
  }
}

export enum DineInOption {
  dineIn = 'dine-in',
  pickupOrder = 'pickup-order'
}

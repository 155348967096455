<app-base-dialog [title]="'Complete your reservation'">
  <div body>
    <form [formGroup]="reservationForm" class="flex flex-col gap-3">

      <div class="flex flex-wrap gap-16 bg-neutral-50 rounded-2xl py-3 px-4">
        <div class="h-[50px] rounded-xl overflow-hidden">
          <app-network-image [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
                             [imageUrl]="data.restaurantImage">
          </app-network-image>
        </div>
        <div class="flex gap-4 items-center">
          <app-svg-icon src="assets/icons/date.svg" [size]="15"></app-svg-icon>
          <div class="flex flex-col gap-2">
            <p class="text-caption">Date</p>
            <p class="text-caption text-black">{{ data.availability?.time | date: 'mediumDate' }}</p>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <app-svg-icon src="assets/icons/clock.svg" [size]="15"></app-svg-icon>
          <div class="flex flex-col gap-2">
            <p class="text-caption">Time</p>
            <p class="text-caption text-black">{{ data.availability?.timeString }}</p>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <app-svg-icon src="assets/icons/group.svg" [size]="15"></app-svg-icon>
          <div class="flex flex-col gap-2">
            <p class="text-caption">Party size</p>
            <p class="text-caption text-black">{{ data.partySize }}</p>
          </div>
        </div>
      </div>

      <app-single-selection-field formControlName="occasion"
                                  [title]="'Occasion'"
                                  [items]="occasionsState.response()?.data ?? []"
                                  [display]="'name'"
                                  [dynamicIconPath]="'icon'"
                                  [dynamicIconColor]="'iconColor'">
      </app-single-selection-field>
      <app-single-selection-field formControlName="seatingPreferences" [title]="'Seating Preferences'"
                                  [items]="seatingPreferenceState.response()?.data ?? []"
                                  [display]="'name'"
                                  [dynamicIconPath]="'icon'"
                                  [dynamicIconColor]="'iconColor'"></app-single-selection-field>
      <app-multi-selection-field formControlName="tags" [title]="'Tags'" [items]="tagsState.response()?.data ?? []"
                                 [display]="'name'"
                                 [dynamicIconPath]="'icon'"
                                 [dynamicIconColor]="'iconColor'"></app-multi-selection-field>
      <app-text-input formControlName="note" class="mt-2" [label]="'Special notes'" viewType="text"></app-text-input>
      <p class="text-body2 text-black">Cancellation Policy</p>
      <p class="text-body2">While you won’t be charge if you need cancel, we ask that you notify us at least 24 hours in
        advance</p>
      <p class="text-body2">By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy,
        and Cookie Policy</p>
    </form>
  </div>
  <div actions>
    <div class="flex">
      <div class="flex-1"></div>
      <app-button [loading]="reservationState.loading()" (click)="reserveClicked()">Reserve</app-button>
    </div>
  </div>
</app-base-dialog>

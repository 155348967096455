import {
  CategoryInfo,
  CategoryVisibility,
  FoodItem, FoodItemModifier, FoodItemModifierGroup,
  FoodItemVisibility,
  FoodMenu,
  MenuCategory,
  MenuVisibility
} from "../data/menu/menu";
import {
  MenuResponseCategoryInfo, MenuResponseCategoryVisibility,
  MenuResponseData, MenuResponseFoodItem, MenuResponseFoodItemPrice, MenuResponseFoodItemSizePrice,
  MenuResponseMenuCategory,
  MenuResponseMenuVisibility, MenuResponseModifier, MenuResponseModifierGroup
} from "../data/menu/menu-response";
import {Image} from "../data/menu/food-menu/image";
import {Price, SizePrice} from "../data/menu/food-menu/price";

export class MenuResponseToMenuUtil {
  static convert(menuResponse: MenuResponseData): FoodMenu {
    let foodMenu = new FoodMenu();
    foodMenu.id = menuResponse._id
    foodMenu.enable = menuResponse.enable;
    foodMenu.name = menuResponse.name;
    foodMenu.description = menuResponse.description;
    foodMenu.image = this.getImage(foodMenu.image);
    foodMenu.visibility = this.getMenuVisibility(menuResponse.visibility);
    foodMenu.categories = this.getCategories(menuResponse.categories);
    return foodMenu;
  }

  static getImage(image: Image | undefined): Image {
    let _image = new Image();
    _image.url = image?.url;
    _image.publicId = image?.publicId;
    return _image;
  }

  private static getMenuVisibility(visibility: MenuResponseMenuVisibility | undefined): MenuVisibility {
    let menuVisibility = new MenuVisibility();
    menuVisibility.dineIn = visibility?.dineIn;
    menuVisibility.kiosk = visibility?.kiosk;
    menuVisibility.pickup = visibility?.pickup;
    menuVisibility.reservation = visibility?.reservation;
    menuVisibility.partner = visibility?.partner;
    menuVisibility.catering = visibility?.catering;
    menuVisibility.waitlist = visibility?.waitlist;
    return menuVisibility;
  }

  private static getCategories(categories: MenuResponseMenuCategory[]): MenuCategory[] {
    let _categories: MenuCategory[]  = [];
    for (const category of categories) {
      let _category = new MenuCategory();
      _category.category = this.getCategoryInfo(category.category);
      _category.menuItems = this.getMenuItems(category.menuItems);

      _categories.push(_category);
    }
    return _categories;
  }

  private static getCategoryInfo(category: MenuResponseCategoryInfo | undefined): CategoryInfo {
    let categoryInfo = new CategoryInfo();
    categoryInfo.visibility = this.getCategoryVisibility(category?.visibility);
    categoryInfo.guid = category?.guid;
    categoryInfo.enable = category?.enable;
    categoryInfo.delete = category?.delete;
    categoryInfo.createdInTestMode = category?.createdInTestMode;
    categoryInfo.createdDate = category?.createdDate;
    categoryInfo.restaurant = category?.restaurant;
    categoryInfo.companyUID = category?.companyUID;
    categoryInfo._id = category?._id;
    categoryInfo.name = category?.name;
    categoryInfo.menu = category?.menu;
    categoryInfo.posName = category?.posName;
    categoryInfo.description = category?.description;
    categoryInfo.sort = category?.sort;
    categoryInfo.__v = category?.__v;

    return categoryInfo;
  }

  private static getCategoryVisibility(visibility: MenuResponseCategoryVisibility | undefined): CategoryVisibility {
    let categoryVisibility = new CategoryVisibility();
    categoryVisibility.inherited = visibility?.inherited;
    categoryVisibility.dineIn = visibility?.dineIn;
    categoryVisibility.pickup = visibility?.pickup;
    categoryVisibility.partner = visibility?.partner;
    categoryVisibility.catering = visibility?.catering;
    categoryVisibility.pos = visibility?.pos;
    categoryVisibility.kiosk = visibility?.kiosk;
    categoryVisibility.takeout = visibility?.takeout;
    categoryVisibility.reservation = visibility?.reservation;

    return categoryVisibility;
  }

  private static getMenuItems(menuItems: MenuResponseFoodItem[] | undefined): FoodItem[] {
    let items: FoodItem[] = []
    for (const menuItem of menuItems ?? []) {
      let item = new FoodItem();
      item.visibility = this.getFoodItemVisibility(item?.visibility);
      item.image = this.getImage(menuItem.image);
      item.price = this.getPrice(menuItem?.price);
      item.appliedDiscounts = menuItem.appliedDiscounts;
      item.itemGroupGuid = menuItem.itemGroupGuid;
      item.discounts = menuItem.discounts;
      item.guid = menuItem.guid;
      item.prepTimeMinutes = menuItem.prepTimeMinutes;
      item._id = menuItem._id;
      item.modifierGroups = this.getModifierGroups(menuItem.modifierGroups);
      item.delete = menuItem.delete;
      item.createdInTestMode = menuItem.createdInTestMode;
      item.enable = menuItem.enable;
      item.rating = menuItem.rating;
      item.ratingCount = menuItem.ratingCount;
      item.companyUID = menuItem.companyUID;
      item.restaurant = menuItem.restaurant;
      item.menu = menuItem.menu;
      item.category = menuItem.category;
      item.name = menuItem.name;
      item.posName = menuItem.posName;
      item.description = menuItem.description;
      item.calories = menuItem.calories;
      item.sort = menuItem.sort;
      item.tags = menuItem.tags;
      item.subcategories = menuItem.subcategories;
      item.submenus = menuItem.submenus;
      item.createdDate = menuItem.createdDate;

      items.push(item);
    }
    return items;
  }

  private static getFoodItemVisibility(visibility: FoodItemVisibility | undefined): FoodItemVisibility {
    let foodItemVisibility = new FoodItemVisibility();
    foodItemVisibility.inherited = visibility?.inherited;
    foodItemVisibility.dineIn = visibility?.dineIn;
    foodItemVisibility.pickup = visibility?.pickup;
    foodItemVisibility.partner = visibility?.partner;
    foodItemVisibility.catering = visibility?.catering;
    foodItemVisibility.pos = visibility?.pos;
    foodItemVisibility.pickup = visibility?.pickup;
    foodItemVisibility.kiosk = visibility?.kiosk;
    foodItemVisibility.takeout = visibility?.takeout;
    foodItemVisibility.reservation = visibility?.reservation;

    return foodItemVisibility;
  }

  private static getPrice(price: MenuResponseFoodItemPrice | undefined): Price {
    let itemPrice = new Price();
    itemPrice.type = price?.type;
    itemPrice.basePrice = price?.basePrice;
    itemPrice.sizePrices = this.getSizePrices(price?.sizePrices);

    return itemPrice;
  }

  private static getSizePrices(sizePrices: MenuResponseFoodItemSizePrice[] | undefined): SizePrice[] {
    let _sizePrices: SizePrice[] = [];
    for (let sizePrice of sizePrices ?? []) {
      let _sizePrice = new SizePrice();
      _sizePrice.size = sizePrice.size;
      _sizePrice.price = sizePrice.price;
      _sizePrice.isDefault = sizePrice.isDefault;

      _sizePrices.push(_sizePrice);
    }

    return _sizePrices;
  }

  private static getModifierGroups(modifierGroups: MenuResponseModifierGroup[]): FoodItemModifierGroup[] {
    let _modifierGroups: FoodItemModifierGroup[] = [];
    for (const modifierGroup of modifierGroups) {
      let _modifierGroup = new FoodItemModifierGroup();
      _modifierGroup.groupPrepTimeMinutes = modifierGroup.groupPrepTimeMinutes;
      _modifierGroup._id = modifierGroup._id;
      _modifierGroup.selection = modifierGroup.selection;
      _modifierGroup.min = modifierGroup.min;
      _modifierGroup.max = modifierGroup.max;
      _modifierGroup.delete = modifierGroup.delete;
      _modifierGroup.createdInTestMode = modifierGroup.createdInTestMode;
      _modifierGroup.restaurant = modifierGroup.restaurant;
      _modifierGroup.name = modifierGroup.name;
      _modifierGroup.description = modifierGroup.description;
      _modifierGroup.sort = modifierGroup.sort;
      _modifierGroup.isRequired = modifierGroup.isRequired;
      _modifierGroup.modifiers = this.getModifiers(modifierGroup.modifiers);
      _modifierGroup.companyUID = modifierGroup.companyUID;

      _modifierGroups.push(_modifierGroup);
    }
    return _modifierGroups;
  }

  private static getModifiers(modifiers: MenuResponseModifier[]): FoodItemModifier[] {
    let _modifiers: FoodItemModifier[] = [];
    for (const modifier of modifiers) {
      let _modifier = new FoodItemModifier();
      _modifier.prepTimeMinutes = modifier.prepTimeMinutes;
      _modifier._id = modifier._id;
      _modifier.name = modifier.name;
      _modifier.price = modifier.price;

      _modifiers.push(_modifier);
    }
    return _modifiers;
  }
}

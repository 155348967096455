import { HttpInterceptorFn } from '@angular/common/http';
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";

export const httpGlobalErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(catchError((error) => {
    console.error('Caught error in errorInterceptor:', error.message);
    return throwError(() => error);
  }));
};

import { AuthService } from '../../../data/auth.service';
import {Component, Input, OnInit, inject, input, signal} from '@angular/core';
import { ButtonComponent } from "../../../../../shared/components/button/button.component";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BaseComponent } from '../../../../../shared/base/base-component';
import { GenericResponse } from '../../../../../core/models/generic-response';
import {State} from "../../../../../shared/base/base-state";
import {Subscription} from "rxjs";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    ButtonComponent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    TextInputComponent
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

  email = input('');

  count = signal(0);

  form!: FormGroup;
  private fb = inject(FormBuilder);

  private authService = inject(AuthService);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [this.email(), [Validators.required, Validators.email]],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      otp: ['', Validators.required]
    });
  }

  resetPassword() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<GenericResponse>({
      state: new State<GenericResponse>(),
      request: this.authService.resetPassword(this.form.value),
      handleSuccess: true,
      onSuccess: response => {
        this.router.navigate(["/login"]);
      }
    })
  }
}



















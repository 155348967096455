import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], args?: string): any[] {
    if (!value || !args) {
      return value;
    }

    const searchText = args.toLowerCase();
    return value.filter(item => {
      return !!(item.name && item.name.toLowerCase().includes(searchText) ||
        item.category && item.category.name && item.category.name.toLowerCase().includes(searchText) ||
        item.category && item.category.description && item.description.toLowerCase().includes(searchText));

    });
  }

}











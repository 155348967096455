<div class="flex gap-5 shadow-1 bg-white border border-neutral-200 rounded-xl overflow-hidden cursor-pointer">
  <app-network-image class="h-auto w-32 object-cover"
                     [imageUrl]="invitation()?.restaurant?.logo?.url">
  </app-network-image>

  <div class="flex flex-grow py-3 pr-4">
    <div class="flex flex-col flex-grow gap-2">
      <div class="flex gap-3 items-center">
        @if(isReceivedInvitation()) {
          <p class="text-body1 text-success">{{ whoInvited() }} Invited you!</p>
        }
        @if(isSentInvitation()) {
          <p class="text-body1 text-success">You have invited {{ inviter() }}</p>
        }
        <div class="flex-grow"></div>
<!--        <app-booking-type [type]="invitation()?.type ?? ''"></app-booking-type>-->
      </div>
      <div class="flex gap-3 items-center">
        <p class="text-body2 text-primary-500">{{ invitation()?.restaurant?.restaurantName }}</p>
      </div>
      <p class="text-body2 text-neutral-400">
        {{
          arrayToCSV([
            invitation()?.restaurant?.location?.address1,
            invitation()?.restaurant?.location?.address2,
            invitation()?.restaurant?.location?.city,
            invitation()?.restaurant?.location?.state,
            invitation()?.restaurant?.location?.country,
            invitation()?.restaurant?.location?.zipCode
          ])
        }}
      </p>
      <div class="flex items-center gap-4">
        <div class="flex items-center gap-2">
          <app-svg-icon src="assets/icons/calendar.svg" [size]="16" class="text-primary-500"></app-svg-icon>
          <p class="text-caption text-neutral-600">{{ invitation()?.startTime | date:'d MMM y - h:mm a' }}</p>
        </div>
        <div class="flex items-center gap-2">
          <app-svg-icon src="assets/icons/guest.svg" [size]="16" class="text-primary-500"></app-svg-icon>
          <p class="text-caption text-neutral-600">{{ invitation()!.partySize }} Guests</p>
        </div>
      </div>
      <div class="flex items-center">
        <p class="rounded-md px-3 py-2 text-caption bg-opacity-5"
           [ngClass]="getBookingColor(invitation()?.status!)">{{ getBookingStatusText(invitation()!.status) }}</p>
        <div class="flex-grow"></div>
        <div class="flex items-center gap-4">
          <div class="flex gap-3 items-center">
            @if(isReceivedInvitation()) {
              <span class="flex gap-2 items-center text-body2 rounded px-3 py-2 bg-green-100 text-green-900" (click)="onAcceptInvitationClicked()">Accept</span>
              <span class="flex gap-2 items-center text-body2 rounded px-3 py-2 bg-red-100 text-red-900" (click)="onDeclineInvitationClicked()">Decline</span>
            }

            @if(isSentInvitation()) {
              <span class="flex gap-2 items-center text-body2 rounded px-3 py-2 bg-red-100 text-red-900" (click)="onDeclineInvitationClicked()">Cancel</span>
            }
          </div>
          @if (restaurantCoordinates().latitude && restaurantCoordinates().longitude) {
            <app-direction-button appearance="iconButton"
                                  [destinationLatitude]="restaurantCoordinates().latitude"
                                  [destinationLongitude]="restaurantCoordinates().longitude">
            </app-direction-button>
          }
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, inject, input, OnInit} from '@angular/core';

import {OrdersTabComponent} from "../../components/orders-tab/orders-tab.component";
import {BaseComponent} from "../../../../../shared/base/base-component";

import {
  CompletedOrdersResponse,
  GetCompletedOrdersResponseData
} from "../../../data/completed-orders/completed-orders-response";
import {State} from "../../../../../shared/base/base-state";
import {CompletedOrdersRequest} from "../../../data/completed-orders/completed-orders-request";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";

import {OrdersCardComponent} from "../../components/orders-card/orders-card.component";
import {NoDataComponent} from "../../../../../core/components/no-data/no-data.component";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";

@Component({
  selector: 'app-past-orders',
  standalone: true,
  imports: [
    OrdersTabComponent,
    ShimmerComponent,
    OrdersCardComponent,
    NoDataComponent
],
  templateUrl: './past-orders.component.html',
  styleUrl: './past-orders.component.scss'
})
export class PastOrdersComponent extends BaseComponent implements OnInit {

  pickupOrderService = inject(PickupOrderService);
  authStoreService = inject(AuthStoreService);


  orderState = new State<CompletedOrdersResponse>();


  ngOnInit() {
    this.getCompletedOrders();
  }

  getCompletedOrders() {
    const appUser = this.authStoreService.getAppUser()?.data;
    if (appUser) {
      const request: CompletedOrdersRequest = {
        phoneNumber: appUser?.phoneNumber,
        email: appUser?.email,
      };
      this.executeRequest({
        state: this.orderState,
        request: this.pickupOrderService.getCompletedOrdersByCustomerId(request)
      });
    }
  }

  onCompletedOrdersClicked(order: GetCompletedOrdersResponseData) {
    this.router.navigate([`/order/${order._id}`])
  }
}















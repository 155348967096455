<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">
  <p class="text-body1 font-medium">Received Invitations</p>
  <div class="my-2">
    <app-invitations-tab [isReceivedInvitation]="true"></app-invitations-tab>
  </div>
  @if (receivedInvitationsState.loading()) {
    <div class="flex flex-col gap-5">
      <app-shimmer></app-shimmer>
      <app-shimmer></app-shimmer>
      <app-shimmer></app-shimmer>
    </div>
  }
  <div class="flex flex-col gap-5">
    @for (receivedInvitation of receivedInvitationsState.response()?.data; track receivedInvitation) {
      <app-received-invitation-card [isReceivedInvitation]="true"
                                    [invitation]="receivedInvitation">
      </app-received-invitation-card>
    }
  </div>
  @if (receivedInvitationsState.success() && (receivedInvitationsState.response()?.data?.length ?? 0) <= 0) {
    <div class="flex flex-col w-full h-full justify-center items-center">
      <app-no-data class="self-center py-2" message="No Bookings found"></app-no-data>
    </div>
  }
</div>

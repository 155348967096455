import {Component, computed, ElementRef, inject, input, OnInit} from '@angular/core';
import {BookingsResponseData} from "../../../data/bookings/bookings/bookings-response";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BookingTypeComponent} from "../booking-type/booking-type.component";
import {DatePipe, DOCUMENT, NgClass} from "@angular/common";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {openLink} from "../../../../../shared/utils/html-utils";
import {
  getBookingStatusTextColor,
  getBookingStatusText,
  getBookingStatusBgColor
} from "../../../utilities/booking-status-utils";
import {DirectionButtonComponent} from "../../../../../core/components/direction-button/direction-button.component";
import {GeoPoint} from "../../../../../core/services/location-service/location.service";
import {State} from "../../../../../shared/base/base-state";
import {RestaurantDetailResponse} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";

@Component({
  selector: 'app-booking-card',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BookingTypeComponent,
    DatePipe,
    NetworkImageComponent,
    NgClass,
    DirectionButtonComponent
  ],
  templateUrl: './booking-card.component.html',
  styleUrl: './booking-card.component.scss'
})
export class BookingCardComponent implements OnInit{
  booking = input.required<BookingsResponseData>();
  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);

  protected readonly getBookingStatusText = getBookingStatusText;

  ngOnInit(): void {

  }


  onCallClicked(phoneNumber: string) {
    openLink(`tel:${phoneNumber}`, false, this.document, this.elementRef);
  }


  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.booking().restaurant?.location?.location?.coordinates[1],
      longitude: this.booking().restaurant?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  getBookingColor(status: string) {
    return `${getBookingStatusTextColor(status)} ${getBookingStatusBgColor(status)}`;
  }
}

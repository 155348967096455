@if (dineInOption() == DineInOption.dineIn) {
  <div class="flex items-center gap-4 md:gap-12 px-4 py-2 shadow-2 rounded-lg">

    <div class="relative hover:bg-neutral-50 py-2 px-4 rounded-lg select-none cursor-pointer">
      <app-date-selector-input
        title="Select Date"
        [(ngModel)]="restaurantsFilterService.selectedDate"
        (valueChanged)="onDateSelected($event)"
        [minDate]="currentDate">
      </app-date-selector-input>
    </div>

    <div class="relative hover:bg-neutral-50 py-2 px-4 rounded-lg select-none cursor-pointer"
         (clickOutside)="closeTimePopup()">
      <div class="flex gap-4 items-center" (click)="toggleTimePopup()">
        <div class="flex flex-col gap-2">
          <p class="text-body2 text-neutral-500 hidden sm:block">Select Time</p>
          <p class="text-body2 text-neutral-500 sm:hidden">Time</p>
          <p class="text-caption sm:text-body2">{{ restaurantsFilterService.selectedTime().timeString }}</p>
        </div>
        <app-svg-icon src="assets/icons/chevron-down.svg" class="text-neutral-400" [size]="12"></app-svg-icon>
      </div>
      @if (isTimeOpen()) {
        <div
          class="absolute z-10 mt-6 min-w-72 transform -translate-x-1/3 rounded-md bg-white shadow-2 p-3 max-h-60 overflow-y-scroll">
          <div class="flex flex-col gap-2">
            <p class="text-body2 text-neutral-600">Time</p>
            <div class="grid grid-cols-3 w-full gap-2">
              @for (time of availableTimes(); track time) {
                <div class="inline-flex justify-center items-center cursor-pointer" (click)="onTimeSelected(time)">
                  <p
                    class="text-caption hover:border-primary-500 border-2 border-neutral-200 hover:border-2 p-2 rounded">{{ time.timeString }}</p>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>

    <div class="relative hover:bg-neutral-50 py-2 px-4 rounded-lg select-none cursor-pointer"
         (clickOutside)="clickOutsidePartySizePopup()">
      <div class="flex gap-4 items-center" (click)="togglePartySizePopup()">
        <div class="flex flex-col gap-2">
          <p class="text-body2 text-neutral-500 hidden sm:block">Select Party</p>
          <p class="text-body2 text-neutral-500 sm:hidden">Party</p>
          <p class="text-caption sm:text-body2">{{ restaurantsFilterService.selectedPartySize() }}</p>
        </div>
        <app-svg-icon src="assets/icons/chevron-down.svg" class="text-neutral-400" [size]="12"></app-svg-icon>
      </div>
      @if (isPartySizeOpen()) {
        <div class="absolute z-10 mt-6 min-w-72 transform -translate-x-1/3 rounded-md bg-white shadow-2 p-3">
          <div class="flex flex-col gap-3">
            <p class="text-body2 text-neutral-600">Party Size</p>
            <div class="grid grid-cols-4 w-full gap-2">
              @for (partySize of partySizeList(); track partySize) {
                <div class="inline-flex justify-center items-center cursor-pointer"
                     (click)="onPartySizeSelected(partySize)">
                  <p
                    class="text-caption hover:border-primary-500 border-2 border-neutral-200 hover:border-2 py-2 px-4 min-w-14 rounded text-center">{{ partySize }}</p>
                </div>
              }
            </div>
            <div class="flex gap-2 justify-center items-center">
              <div class="border-b flex-1"></div>
              <p class="text-caption text-neutral-500">OR</p>
              <div class="border-b flex-1"></div>
            </div>
            <div class="flex gap-3 items-center">
              <p class="bg-neutral-50 text-primary-500 font-bold py-2 px-4 rounded-full"
                 (click)="onPartySizeDecreased()">
                -</p>
              <p class="text-body2">{{ restaurantsFilterService.selectedPartySize() }}</p>
              <p class="bg-neutral-50 text-primary-500 font-bold py-2 px-4 rounded-full"
                 (click)="onPartySizeIncreased()">
                +</p>
            </div>
          </div>
        </div>
      }
    </div>

  </div>
}

import {Component, EventEmitter, Output,} from '@angular/core';
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-filter-chip',
  standalone: true,
  templateUrl: './filter-chip.component.html',
  styleUrl: './filter-chip.component.scss',
  imports: [AppSvgIconComponent]
})
export class FilterChipComponent {

  @Output()
  removeClicked = new EventEmitter();

  onChipRemoveClicked() {
    this.removeClicked.emit();
  }

}

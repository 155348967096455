<app-base-dialog [title]="'Select Address'" [showAction]="false">
  <div body>
    <div class="flex flex-col gap-3">
      <div class="flex flex-col cursor-pointer" (click)="onDetectLocationClicked()">
        <div class="flex items-center gap-4">
          <app-svg-icon src="assets/icons/gps.svg" class="text-primary-500" [size]="16"></app-svg-icon>
          <div class="flex flex-col">
            <p class="text-body2 text-primary-500">Locate current place</p>
            <p class="text-body2 text-neutral-400">Using Device GPS</p>
          </div>
        </div>
        <div class="border border-b border-neutral-100 my-3"></div>
      </div>

      <!-- Saved address-list -->
      <div class="flex flex-col gap-2 py-2">
        <div class="flex justify-between items-center w-full">
          @if (authStoreService.isAppUserLoggedIn()) {
            <p class="text-body2 font-semibold">Saved Addresses</p>
            <div class="flex gap-3 items-center cursor-pointer" (click)="onAddAddressClicked()">
              <app-svg-icon src="assets/icons/plus.svg" class="text-primary-500" [size]="11"></app-svg-icon>
              <p class="text-body2 text-primary-500">Add address</p>
            </div>
          }
        </div>

        @if (addressState.loading()) {
          <app-shimmer type="list"></app-shimmer>
        }

        @if (authStoreService.isAuthenticated()) {
          @for (address of addressState.response()?.data; track address) {
            <div class="flex items-center text-body2 gap-2 py-3 cursor-pointer" (click)="onAddressSelected(address)">
              <app-svg-icon src="assets/icons/location.svg" class="text-primary-500" [size]="18"></app-svg-icon>
              <p class="text-body2 px-2 flex-grow">{{ arrayToCSV([address.address, address.city, address.state, address.country, address.zipCode]) }}</p>
              <app-svg-icon src="assets/icons/arrow-top-right.svg" class="text-neutral-400" [size]="18"></app-svg-icon>
            </div>
          }
        }
      </div>
    </div>
  </div>
</app-base-dialog>

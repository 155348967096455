<div class="text-end">
  <app-button (click)="onAddPaymentClicked()">Add Payment</app-button>
</div>
<div class="flex flex-col gap-3 w-full">
  <p class="text-body2 py-3">Saved Cards</p>

  @if (savedCardsState.loading()) {
    <div class="flex flex-col gap-3">
      <app-shimmer type="list"></app-shimmer>
      <app-shimmer type="list"></app-shimmer>
      <app-shimmer type="list"></app-shimmer>
    </div>
  }

  @for (card of savedCardsState.response()?.data ?? []; track card) {
    <div class="flex items-center justify-between px-5 py-3 bg-white shadow-1 rounded-md cursor-pointer">
      <div class="flex items-center gap-3">
        <app-svg-icon [src]="CreditCardImageUtils.getCardImage(card.card.brand)"></app-svg-icon>
        <p class="text-body2">XXXX- XXXX - XXXX - {{ card.card.last4 }}</p>
      </div>
      <div class="flex items-center gap-3">
<!--        <app-button (click)="onSetAsDefaultClicked(card)" appearance="outline" class="text-primary-500">Set as Default</app-button>-->
        <app-svg-icon src="assets/icons/delete.svg"
                      class="hover:bg-neutral-100 rounded-full px-2 text-neutral-400 py-2 cursor-pointer"
                      (click)="onDeleteClicked(card)"></app-svg-icon>
      </div>
    </div>
  }
</div>

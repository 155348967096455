<div class="flex flex-col h-dvh overflow-y-auto">
  <app-top-nav class="fixed top-0 left-0 right-0 z-50 bg-white"></app-top-nav>
  <app-hero-section class="grow"></app-hero-section>
</div>
<app-feature-1-section></app-feature-1-section>
<app-feature-2-section></app-feature-2-section>
<app-feature-3-section></app-feature-3-section>
<app-mobile-app-section></app-mobile-app-section>
<app-footer></app-footer>


import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {TextAreaInputComponent} from "../../../../../shared/inputs/text-area-input/text-area-input.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {State} from "../../../../../shared/base/base-state";
import {ContactUsResponse} from "../../../../../core/services/common-data/contact-us/contact-us-response";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {ContactUsRequest} from "../../../../../core/services/common-data/contact-us/contact-us-request";

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    TextAreaInputComponent,
    ButtonComponent
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  commonDataService = inject(CommonDataService);
  formBuilder = inject(FormBuilder);

  contactUsState = new State<ContactUsResponse>();

  contactUsForm!: FormGroup;

  ngOnInit() {
    this.contactUsForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.minLength(3)]],
      phoneNumber: ['', Validators.required],
      feedback: ['', Validators.required]
    });

  }

  onSendMessageClicked() {

    if (this.contactUsForm.invalid) {
      return;
    }

    const request: ContactUsRequest = {
      firstName: this.contactUsForm.value.firstName,
      lastName: this.contactUsForm.value.lastName,
      category: 'General Inquiry',
      email: this.contactUsForm.value.email,
      phoneNumber: this.contactUsForm.value.phoneNumber,
      message: this.contactUsForm.value.feedback,
    }

    this.executeRequest({
      state: this.contactUsState,
      request: this.commonDataService.contactUs(request),
      successMessage: 'Thank you for contacting us, we will get back to you soon!',
      onSuccess: response => {
        this.contactUsForm.reset();
        this.router.navigate(['/home']);
      }
    });
  }
}

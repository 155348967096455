import {AfterViewInit, Component, inject, input, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {DOCUMENT, NgClass, NgStyle} from "@angular/common";
import {CuisinesResponse, CuisinesResponseData} from "../../../data/cuisines/cuisines-response";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {numberToPricePoint} from "../../../../../core/utils/price-point-utils";
import {RestaurantsFilterService} from "../../../data/restaurants-filter.service";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {
  MultiSelectionFieldComponent
} from "../../../../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {PricePointResponse} from "../../../../../core/services/common-data/price-point/price-point-response";
import {State} from "../../../../../shared/base/base-state";

@Component({
  selector: 'app-dine-in-filter-overlays',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass,
    ButtonComponent,
    NgStyle,
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    MultiSelectionFieldComponent
  ],
  templateUrl: './dine-in-filter-overlay.component.html',
  styleUrl: './dine-in-filter-overlay.component.scss'
})
export class DineInFilterOverlayComponent extends BaseComponent implements OnInit {
  dialogRef = inject(MatDialogRef<DineInFilterOverlayComponent>);
  formBuilder = inject(FormBuilder);

  restaurantsFilterService = inject(RestaurantsFilterService);
  commonDataService = inject(CommonDataService);

  data: any = inject(MAT_DIALOG_DATA);

  pricePointsState = new State<PricePointResponse>();

  ratings = signal([1, 2, 3, 4, 5]);
  cuisines = signal<CuisinesResponse | null>(null);

  protected readonly numberToPricePoint = numberToPricePoint;

  filterForm!: FormGroup;

  ngOnInit(): void {
    this.cuisines.set(this.data.cuisines);

    let selectedCuisines: CuisinesResponseData[] = [];

    this.cuisines()?.data?.forEach(cuisine => {
      const index = this.restaurantsFilterService.selectedCuisines().findIndex(x => x == cuisine.name);
      if(index != -1) {
        selectedCuisines.push(cuisine);
      }
    });

    this.getPricePoints();
    this.filterForm = this.formBuilder.group({
      rating: [this.restaurantsFilterService.selectedRating()],
      cuisines: [selectedCuisines],
      pricePoint: []
    });

  }

  getPricePoints() {
    this.executeRequest<PricePointResponse>({
      state: this.pricePointsState,
      request: this.commonDataService.getPricePoints(),
      onSuccess: response => {
        const pricePoints = response.data ?? [];
        const index = response.data?.findIndex(x => x.value == this.restaurantsFilterService.selectedPricePoint());
        if(index != -1) {
          this.filterForm.patchValue({
            pricePoint: pricePoints[index]
          });
        }
      }
    });
  }

  onClearClicked() {
    this.filterForm.reset();

    this.restaurantsFilterService.selectedRating.set(null);
    this.restaurantsFilterService.selectedCuisines.set([]);
    this.restaurantsFilterService.selectedPricePoint.set(null);

    this.onCloseClicked();
  }

  onCloseClicked() {
    this.dialogRef.close();
  }

  onApplyClicked() {
    const filterValue = this.filterForm.value;
    this.restaurantsFilterService.setSeparateFilter(filterValue.rating, filterValue.cuisines, filterValue.pricePoint);
    this.dialogRef.close();
  }
}

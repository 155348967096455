import {
  Component, inject, OnInit, signal,

} from '@angular/core';
import {FilterChipComponent} from "../filter/filter-chip/filter-chip.component";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  SingleSelectionFieldComponent
} from "../../../shared/inputs/single-selection-field/single-selection-field.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FormsModule} from '@angular/forms';
import {AsyncPipe, CommonModule, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {CounterInputComponent} from "../../../shared/inputs/counter-input/counter-input.component";
import {BaseComponent} from "../../../shared/base/base-component";
import {DatePickerComponent} from "../../../shared/inputs/date-picker/date-picker.component";
import {TextInputComponent} from "../../../shared/inputs/text-input/text-input.component";
import {
  SingleItemPickerInputComponent
} from "../../../shared/inputs/single-item-picker-input/single-item-picker-input.component";
import {DateSelectorInputComponent} from "../../../shared/inputs/date-selector-input/date-selector-input.component";
import {TopNavComponent} from "../../../features/layout/top-nav/top-nav.component";

import {RatingInputComponent} from "../../../shared/inputs/rating-input/rating-input.component";
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {FooterComponent} from "../../../features/layout/footer/footer.component";
import {CheckboxComponent} from "../../../shared/inputs/checkbox/checkbox.component";
import {RadioButtonComponent} from "../../../shared/inputs/radio-button/radio-button.component";
import {
  PickupCheckoutComponent
} from "../../../features/pickup-order/presentation/pages/pickup-checkout/pickup-checkout.component";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteTrigger,
  MatOption
} from "@angular/material/autocomplete";
import {MatInput, MatInputModule} from "@angular/material/input";
import {RestaurantSuggestionComponent} from "../../../features/restaurant-suggestion/restaurant-suggestion.component";
import {
  CheckInConfirmationOverlayComponent
} from "../../../features/pickup-order/presentation/overlay/check-in-confirmation-overlay/check-in-confirmation-overlay.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-test',
  standalone: true,
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
  imports: [
    FilterChipComponent,
    AppSvgIconComponent,
    DateSelectorInputComponent,
    SingleSelectionFieldComponent,
    ReactiveFormsModule,
    JsonPipe,
    ButtonComponent,
    CounterInputComponent,
    DatePickerComponent,
    TextInputComponent,
    SingleItemPickerInputComponent,
    TopNavComponent,
    RatingInputComponent,
    CdkVirtualScrollViewport,
    AsyncPipe,
    CdkVirtualForOf,
    CdkFixedSizeVirtualScroll,
    InfiniteScrollModule,
    NgIf,
    NgForOf,
    FooterComponent,
    CheckboxComponent,
    RadioButtonComponent,
    PickupCheckoutComponent,
    FormsModule,
    MatFormField,
    MatAutocomplete,
    MatOption,
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteTrigger,
    MatInput,
    RestaurantSuggestionComponent,
    CheckInConfirmationOverlayComponent
  ]
})
export class TestComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);

  ngOnInit() {
  }

  searchItems: SearchItem[] = [
    {name: 'Hidden Fork', type: 'restaurant'},
    {name: 'Blue Grill', type: 'restaurant'},
    {name: "Nandha's Delight", type: 'restaurant'},
    {name: 'American', type: 'cuisine'},
    {name: 'Bakery', type: 'cuisine'},
    {name: 'Burger', type: 'cuisine'},
    {name: 'Chicken', type: 'cuisine'},
    {name: 'Chinese', type: 'cuisine'},
    {name: 'Dessert', type: 'cuisine'},
    {name: 'Fast Food', type: 'cuisine'},
    {name: 'Healthy', type: 'cuisine'},
    {name: 'Italian', type: 'cuisine'},
    {name: 'Japanese', type: 'cuisine'},
    {name: 'Mediterranean', type: 'cuisine'},
    {name: 'Mexican', type: 'cuisine'},
    {name: 'Pizza', type: 'cuisine'},
    {name: 'Sandwich', type: 'cuisine'},
    {name: 'Seafood', type: 'cuisine'},
    {name: 'Soup', type: 'cuisine'},
    {name: 'Steak', type: 'cuisine'},
    {name: 'Sushi', type: 'cuisine'},
    {name: 'Thai', type: 'cuisine'},
    {name: 'Vegan', type: 'cuisine'},
  ];

  filteredItems: SearchItem[] = [];
  searchTerm = '';

  walkIn = signal(50);
  waitlist = signal(30);
  reservation = signal(20);

  onSearch() {
    const search = this.searchTerm.toLowerCase();
    this.filteredItems = this.searchItems.filter(item =>
      item.name.toLowerCase().includes(search)
    );
  }

  onButtonClicked() {
    this.dialog.open(CheckInConfirmationOverlayComponent);
  }
}

interface SearchItem {
  name: string;
  type: 'restaurant' | 'cuisine';
}

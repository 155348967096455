<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error">Clear cart</p>
    <p class="text-body2 text-neutral-700">Are you sure you want to clear the existing cart?</p>
  </div>
  <div class="flex justify-end gap-4 mt-3">
    <app-button buttonColor="bg-error"
                (click)="onYesClicked()">Yes
    </app-button>
    <app-button appearance="outline" (click)="onNoClicked()">No</app-button>
  </div>
</mat-dialog-content>

import {signal} from "@angular/core";

export class Price {
  public type?: string;
  public basePrice?: number;
  public sizePrices: SizePrice[] = [];
}

export class SizePrice {
  public size?: string;
  public price?: number;
  public isDefault?: boolean;

  // FF
  isSelected = signal(false);

}

import {Component, computed, ElementRef, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {DatePipe, DecimalPipe, DOCUMENT, NgClass} from "@angular/common";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";

import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  MenuResponse, MenuResponseData,
} from "../../../data/menu/menu-response";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {State} from "../../../../../shared/base/base-state";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";



import {
  DateSelectorInputComponent
} from "../../../../../shared/inputs/date-selector-input/date-selector-input.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {
  CustomizableItemSelection,
  CustomizableItemSelectorOverlayComponent
} from "../../overlay/customizable-item-selector-overlay/customizable-item-selector-overlay.component";
import {FoodItem, FoodMenu, MenuCategory} from "../../../data/menu/menu";
import {MenuResponseToMenuUtil} from "../../../utilities/menu-response-to-menu-util";
import {ShimmerComponent} from "../../../../../shared/components/shimmer/shimmer.component";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {CartService} from "../../../data/cart.service";
import {
  SingleItemPickerInputComponent
} from "../../../../../shared/inputs/single-item-picker-input/single-item-picker-input.component";
import {OrderPickupTime, PickupScheduleResponse} from "../../../data/pickup-schedule/pickup-schedule-response";
import {PickupScheduleRequest} from "../../../data/pickup-schedule/pickup-schedule-request";

import {DirectionButtonComponent} from "../../../../../core/components/direction-button/direction-button.component";
import {GeoPoint} from "../../../../../core/services/location-service/location.service";
import {
  RestaurantDetailResponse,
  RestaurantDetailsResponseDataCuisines
} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";
import {
  UserLoginOverlayComponent
} from "../../../../auth/presentation/overlays/user-login-overlay/user-login-overlay.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {MatDialog} from "@angular/material/dialog";
import {FavoriteRestaurantRequest} from "../../../../restaurants/data/favourite-restaurant/favorite-restaurant-request";
import {AppDataService} from "../../../../../core/services/app-data/app-data.service";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {RestaurantFavoriteService} from "../../../../settings/data/restaurant-favorite.service";
import {ShareLinkComponent} from "../../../../common/presentation/overlay/share-link/share-link.component";
import {RestaurantService} from "../../../../restaurants/data/restaurant.service";
import {calculateDistance} from "../../../../../shared/utils/geo-utils";
import {arrayToCSV, toDashCase} from "../../../../../shared/utils/string-utils";
import {RatingCountComponent} from "../../../../../core/components/rating-count/rating-count.component";
import {ChipComponent} from "../../../../../core/components/chip/chip.component";
import {numberToPricePoint} from "../../../../../core/utils/price-point-utils";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {pixelToTopCss} from "../../../../../shared/utils/html-utils/css-top-utils";
import {fromEvent, Subscription, throttleTime} from "rxjs";
import {RestaurantsFilterService} from "../../../../restaurants/data/restaurants-filter.service";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";

@Component({
  selector: 'app-pickup-order-restaurant-details',
  standalone: true,
  imports: [
    NgClass,
    ButtonComponent,
    AppSvgIconComponent,
    NetworkImageComponent,
    FormsModule,
    DateSelectorInputComponent,
    DecimalPipe,
    ShimmerComponent,
    RadioButtonComponent,
    SingleItemPickerInputComponent,
    ReactiveFormsModule,
    ChipComponent,
    DirectionButtonComponent,
    RatingCountComponent,
    OutlineTextInputComponent
],
  templateUrl: './pickup-order-restaurant-details.component.html',
  styleUrl: './pickup-order-restaurant-details.component.scss',
  providers: [
    DatePipe
  ]
})


export class PickupOrderRestaurantDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  restaurantId = input('');

  formBuilder = inject(FormBuilder);
  pickupOrderService = inject(PickupOrderService);
  bottomSheet = inject(MatBottomSheet);
  cartService = inject(CartService);
  datePipe = inject(DatePipe);
  authStoreService = inject(AuthStoreService);
  dialog = inject(MatDialog);
  appDataService = inject(AppDataService);
  favoritesService = inject(RestaurantFavoriteService);
  document = inject(DOCUMENT);
  restaurantService = inject(RestaurantService);
  restaurantsFilterService = inject(RestaurantsFilterService);


  protected readonly arrayToCSV = arrayToCSV;
  protected readonly numberToPricePoint = numberToPricePoint;

  elementRef = inject(ElementRef);
  stickyClass = signal('top-0');
  private scrollSubscription!: Subscription;

  pickupMenuState = new State<MenuResponse>();
  pickupScheduleState = new State<PickupScheduleResponse>();
  restaurantDetailState = new State<RestaurantDetailResponse>();
  removeFavoriteRestaurantState = new State<GenericResponse>();
  state = new State<GenericResponse>();

  cuisines = signal<RestaurantDetailsResponseDataCuisines[]>([]);
  searchFilter = signal<string | null>(null);
  filteredMenu = signal<FoodMenu | null>(null);
  restaurantLiked = signal(false);
  selectedDineInOption = signal(DineInOption.dineIn);
  distanceInMiles = computed<string | null>(() => {
    const restaurant = this.restaurantDetailState.response()?.data;
    let destinationLatitude = restaurant?.location?.location?.coordinates[1];
    let destinationLongitude = restaurant?.location?.location?.coordinates[0];

    let currentLocation = this.appDataService.getAddress();
    let sourceLatitude = currentLocation?.latitude;
    let sourceLongitude = currentLocation?.longitude;
    if (sourceLatitude && sourceLongitude && destinationLatitude && destinationLongitude) {
      const distanceInMiles = calculateDistance(sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude).miles.toFixed(2);
      return `${distanceInMiles} miles away`
    } else {
      return 'N/A';
    }
  });
  selectedMenu = signal<MenuResponseData | null>(null);

  filterForm!: FormGroup;

  timeAvailabilities: AvailableTimeForView[] = [];

  protected readonly DineInOption = DineInOption;

  dineInOptionSubscription!: Subscription;

  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.restaurantDetailState.response()?.data?.location?.location?.coordinates[1],
      longitude: this.restaurantDetailState.response()?.data?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  ngOnInit(): void {
    this.initPickupOrderForm();
    this.getPickupMenus();
    this.getRestaurantDetails();
    this.initUIWork();
    this.initListeners();
    this.restaurantsFilterService.setDineInOptionFilter(DineInOption.pickupOrder)
  }

  ngOnDestroy(): void {
    try {
      this.scrollSubscription.unsubscribe();
      this.dineInOptionSubscription.unsubscribe();
    } catch (e) {
    }
  }

  initListeners() {
    this.dineInOptionSubscription = this.restaurantsFilterService.dineInOptionUpdated$.subscribe((dineInOption) => {
      switch (dineInOption) {
        case DineInOption.dineIn:
          this.gotoDineInPage();
          break;
        case DineInOption.pickupOrder:
          this.gotoPickupOrderPage();
          break;
      }
    });
  }

  gotoDineInPage() {
    const parameters = this.route.snapshot.queryParams;
    this.router.navigate(['/dine-in-restaurant-details', this.restaurantId()], {queryParams: parameters});
  }

  gotoPickupOrderPage() {
    const parameters = this.route.snapshot.queryParams
    const currentCartRestaurantId = this.cartService.restaurantId;
    if (currentCartRestaurantId !== null && currentCartRestaurantId != this.restaurantId()) {
      this.cartService.resetCart();
      this.toasterService.warning('Your cart will be emptied since you\'re choosing items from different restaurants');
      this.router.navigate([`pickup-restaurant-details/${this.restaurantId()}`], {queryParams: parameters});
      return;
    }

    this.router.navigate(['/pickup-restaurant-details', this.restaurantId()], {queryParams: parameters});
  }

  getRestaurantDetails() {
    this.executeRequest<RestaurantDetailResponse>({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(this.restaurantId()),
      onSuccess: response => {
        const isRestaurantLiked = this.appDataService.isRestaurantLiked(response.data._id);
        this.restaurantLiked.set(isRestaurantLiked);
        const cuisines = response.data?.cuisines ?? [];
        this.cuisines.set(cuisines);
      }
    });
  }

  onFavoriteClicked(event: MouseEvent) {
    event.stopPropagation();
    if (!this.authStoreService.isAuthenticated()) {
      this.dialog.open(UserLoginOverlayComponent);
      return;
    }

    if (this.restaurantLiked()) {
      this.removeRestaurantFromFavorite();
    } else {
      this.addRestaurantToFavorite();
    }
  }

  removeRestaurantFromFavorite() {
    const restaurant = this.restaurantDetailState.response()?.data;
    const favoriteRestaurantId = this.appDataService.getFavoriteRestaurantId(restaurant?._id ?? '');
    this.executeRequest({
      state: this.removeFavoriteRestaurantState,
      request: this.favoritesService.deleteFavoriteRestaurant(favoriteRestaurantId ?? ''),
      showLoader: true,
      successMessage: 'Restaurant removed from favorite list',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(false);
      }
    });
  }

  addRestaurantToFavorite() {
    const appUser = this.authStoreService.getAppUser()?.data;
    const restaurant = this.restaurantDetailState.response()?.data;
    let request: FavoriteRestaurantRequest = {
      companyUID: restaurant?.companyUID ?? '',
      user: appUser?.user ?? '',
      restaurant: restaurant?._id ?? ''
    };
    this.executeRequest({
      state: this.state,
      request: this.favoritesService.addToFavorite(request),
      showLoader: true,
      successMessage: 'Restaurant added as favorite',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(true);
      }
    });
  }


  onShareClicked() {
    const fullUrl = this.document.URL;
    this.dialog.open(ShareLinkComponent, {
      minWidth: '400px',
      maxWidth: '400px',
      maxHeight: '95vh',
      data: {
        url: fullUrl,
        mailSubject: 'Checkout this restaurant',
        message: `Discover something delicious! Checkout this restaurant - ${fullUrl}`
      }
    });
  }

  initPickupOrderForm() {
    this.filterForm = this.formBuilder.group({
      pickupOption: ['ASAP'],
      date: [Date()],
      time: []
    });

    this.filterForm.controls['pickupOption'].valueChanges.subscribe(value => {
      switch (value) {
        case 'ASAP':
          this.filterForm.controls['date'].setValidators([]);
          this.filterForm.controls['time'].setValidators([]);
          this.filterForm.controls['date'].updateValueAndValidity();
          this.filterForm.controls['time'].updateValueAndValidity();
          break;
        case 'LATER':
          this.filterForm.controls['time'].setValue(null);
          this.filterForm.controls['date'].setValidators([Validators.required]);
          this.filterForm.controls['time'].setValidators([Validators.required]);
          this.filterForm.controls['date'].updateValueAndValidity();
          this.filterForm.controls['time'].updateValueAndValidity();
          this.filterForm.controls['time'].markAsUntouched();
          this.getPickupSchedules(new Date());
          break;
      }
    });
  }

  initUIWork() {
    let topBarHeight = this.document.getElementById('topBar')?.clientHeight || 0;
    this.stickyClass.update((c) => pixelToTopCss(topBarHeight));
    this.scrollSubscription = fromEvent(this.document, 'scroll')
      .pipe(throttleTime(100))
      .subscribe((event) => this.onWindowScroll(event));
  }

  onWindowScroll(event: Event) {
    let scrollPosition = window.scrollY || document.documentElement.scrollTop;
    scrollPosition = scrollPosition + 160;

    const sections: any = this.document.querySelectorAll('.section');

    sections.forEach((section: HTMLElement) => {
      if (
        section.offsetTop <= scrollPosition &&
        section.offsetTop + section.offsetHeight > scrollPosition
      ) {
        let navLinks: any = this.elementRef.nativeElement.querySelectorAll('.nav-link');
        navLinks.forEach((link: HTMLAnchorElement) => {
          if (link.classList.contains(section.id)) {
            link.classList.add('nav-link-active');
            link.classList.remove('nav-link-inactive');
          } else {
            link.classList.add('nav-link-inactive');
            link.classList.remove('nav-link-active');
          }
        });
      }
    });
  }


  getPickupMenus() {
    this.executeRequest<MenuResponse>({
      state: this.pickupMenuState,
      request: this.pickupOrderService.getPickupMenus(this.restaurantId()),
      onSuccess: (response) => {
        this.processMenuItems(response.data);
        if (response.data.length > 0) {
          this.onMenuTabClicked(response.data[0]);
        }
      }
    });
  }

  private getPickupSchedules(date: Date) {
    const request: PickupScheduleRequest = {
      restaurantId: this.restaurantId(),
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.executeRequest<PickupScheduleResponse>({
      state: this.pickupScheduleState,
      request: this.pickupOrderService.getPickupSchedule(request),
      onSuccess: response => {
        this.timeAvailabilities = [];
        for (const pickupTime of response.data.orderPickupTimes ?? []) {
          let ta: AvailableTimeForView = {
            pickupTime: pickupTime,
            formattedTime: this.datePipe.transform(pickupTime.pickupTime, 'hh:mm a')
          };
          this.timeAvailabilities.push(ta);
        }
      }
    });
  }

  onDateChanged(date: Date) {
    this.filterForm.controls['time'].setValue(null);
    this.getPickupSchedules(date);
  }

  private processMenuItems(data: MenuResponseData[]) {
    if (data.length > 0) {
      this.filteredMenu.set(MenuResponseToMenuUtil.convert(data[0]));
    }
  }

  onCategoryToggle(categoryName: MenuCategory) {
    categoryName.isExpanded.update(prev => !prev);
  }

  onAddButtonClicked(menuItem: FoodItem) {
    this.cartService.setRestaurantId(this.restaurantId());
    if (menuItem.isSimpleItem()) {
      this.cartService.cart().addFoodItem(menuItem, 1);
    }

    if (menuItem.isCustomizableItem()) {
      menuItem.resetSelection();
      this.bottomSheet.open(CustomizableItemSelectorOverlayComponent, {data: menuItem})
        .afterDismissed().subscribe((selection: CustomizableItemSelection) => {
        if (selection != null) {
          this.cartService.cart().addFoodItem(selection.foodItem, selection.quantity);
        }
      });
    }
  }

  onMenuSearch(searchText: string) {
    let menu = this.filteredMenu();
    if (menu == null) {
      return;
    }

    let categories = menu.categories ?? [];
    for (let category of categories) {
      let menuItems = category.menuItems ?? [];
      let categoryMatched = false;
      for (let menuItem of menuItems) {
        let menuName = menuItem.name ?? '';
        let menuMatched = menuName.toLowerCase().includes(searchText.toLowerCase());
        menuItem.filterMatched.set(menuMatched);
        if (!categoryMatched && menuMatched) {
          categoryMatched = true;
        }
      }
      category.filterMatched.set(categoryMatched);
    }
  }

  onSearchFilterCleared() {
    this.searchFilter.set('');
    this.onMenuSearch('');
  }

  onViewCartPressed() {
    this.validateForm(this.filterForm);

    if (this.cartService.cart().quantity <= 0) {
      this.toasterService.warning('Please choose your preferred items to checkout');
      return;
    }

    if (!this.filterForm.valid) {
      this.toasterService.warning('Please choose the schedule time');
      return;
    }

    let pickupOption = this.filterForm.value.pickupOption;
    let pickupTime = this.filterForm.value.time?.pickupTime?.pickupTime;
    this.cartService.setPickupOptions(pickupOption, pickupTime);

    this.router.navigate([`/checkout/${this.restaurantId()}`]);

  }

  onCategoryTabClicked(categoryId: string | undefined) {
    const element = this.elementRef.nativeElement.querySelector(`#${categoryId}`);
    if (element && categoryId) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

  protected readonly toDashCase = toDashCase;

  onCallForOrderClicked() {
    const phoneNumber = this.restaurantDetailState.response()?.data.phoneNumber;
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      this.toasterService.error('Phone number not available');
    }
  }

  onDineOptionSelected(dineInOption: DineInOption) {
    this.restaurantsFilterService.setDineInOptionFilter(dineInOption);
  }

  onMenuTabClicked(menu: MenuResponseData) {
    this.selectedMenu.set(menu);
    this.filteredMenu.set(MenuResponseToMenuUtil.convert(menu));
  }
}

export interface AvailableTimeForView {
  pickupTime: OrderPickupTime;
  formattedTime: string | null;
}















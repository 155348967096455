import {Component, inject, OnInit, signal} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LoginComponent} from "../../pages/login/login.component";
import {UserLoginOverlayComponent} from "../user-login-overlay/user-login-overlay.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";

import {BaseComponent} from "../../../../../shared/base/base-component";
import {State} from "../../../../../shared/base/base-state";
import {GenericResponse} from "../../../../../core/models/generic-response";
import {AuthService} from "../../../data/auth.service";
import {SendGuestConfirmCodeRequest} from "../../../data/send-guest-confirm-code/send-guest-confirm-code-request";
import {VerifyGuestConfirmationCode} from "../../../data/verify-guest-confirmation-code/verify-guest-confirmation-code";

@Component({
  selector: 'app-guest-login-overlays',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    TextInputComponent,
    ReactiveFormsModule
],
  templateUrl: './guest-login-overlay.component.html',
  styleUrl: './guest-login-overlay.component.scss'
})
export class GuestLoginOverlayComponent extends BaseComponent implements OnInit {
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef);
  formBuilder = inject(FormBuilder);
  authStoreService = inject(AuthStoreService);
  authService = inject(AuthService);

  sendGuestCodeState = new State<GenericResponse>();
  verifyGuestCodeState = new State<GenericResponse>();

  guestInfoForm!: FormGroup;
  verifyOtpForm!: FormGroup;

  sendGuestConfirmCodeRequest!: SendGuestConfirmCodeRequest;

  guestOtpSent = signal(false);

  ngOnInit(): void {
    this.guestInfoForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]]
    });

    this.verifyOtpForm = this.formBuilder.group({
      phoneNumber: '',
      code: ['', Validators.required]
    });
  }

  onLoginPressed() {
    this.dialogRef.close(false);
    this.dialog.open(UserLoginOverlayComponent);
  }

  onLoginAsGuestClicked() {
    if (this.guestInfoForm.invalid) {
      return;
    }

    this.sendGuestPhoneNumber();
  }

  sendGuestPhoneNumber() {
    this.sendGuestConfirmCodeRequest = {
      firstName: this.guestInfoForm.value.firstName,
      phoneNumber: this.guestInfoForm.value.phoneNumber,
    };
    this.executeRequest({
      state: this.sendGuestCodeState,
      request: this.authService.sendGuestConfirmCode(this.sendGuestConfirmCodeRequest ),
      onSuccess: response => {
        this.guestOtpSent.set(true);
        this.verifyOtpForm.patchValue({
          phoneNumber: this.sendGuestConfirmCodeRequest .phoneNumber
        });
      }
    });
  }

  onVerifyOTPClicked() {
    const request: VerifyGuestConfirmationCode = {
      phoneNumber: this.verifyOtpForm.value.phoneNumber,
      code: this.verifyOtpForm.value.code
    };
    this.executeRequest({
      state: this.verifyGuestCodeState,
      request: this.authService.verifyGuestConfirmationCode(request),
      handleSuccess: true,
      successMessage: 'OTP verified successfully!',
      onSuccess: response => {
        const loginData: GuestLoginData = {
          status: GuestLoginStatus.success,
          firstName: this.sendGuestConfirmCodeRequest.firstName,
          phoneNumber: this.sendGuestConfirmCodeRequest.phoneNumber,
        };
        this.dialogRef.close(loginData);
      }
    });
  }

  onSignUpPressed() {
    this.dialogRef.close(true);
    this.router.navigate(['/sign-up']);
  }
}

export interface GuestLoginData {
  status: GuestLoginStatus;
  firstName: string;
  phoneNumber: string;
}

export enum GuestLoginStatus {
  success,
  failed
}

<form [formGroup]="form" class="flex flex-col gap-3 text-center text-neutral-700">
  <p class="text-h5 ">
    Reset Password
  </p>
  <p class="text-subtitle2">Check your {{ email() }} for an email to reset your password. You'll receive and
    if there is an associated Smart Dining Account</p>

  <app-text-input formControlName="newPassword" type="password" label="New password"></app-text-input>
  <app-text-input formControlName="confirmPassword" type="password" label="Confirm Password"></app-text-input>
  <app-text-input formControlName="otp" type="text" label="Email verification code"></app-text-input>

  <p class="text-subtitle2 text-neutral-500">
    Don't see an email? Try your spam folder or
    <span class="text-primary-500 font-semibold">Resend new code</span>
  </p>
  <app-button [fullWidth]="true" (click)="resetPassword()">Reset Password</app-button>
</form>

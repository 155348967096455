export const environment = {
  production: false,
  baseUrl: 'https://cloudapis.gosmartdining.com',
  stripePublishableKey: 'pk_test_51Kt9oKKwQBgPaTGiO8h5ArjCukzpIcNANZNzVQLq2ra6aqAKfmphXsLKHz2qo0sn4PxVmcli2M507yFAKDKQUnLb00egDqeXSz',
  GOOGLE_MAP_KEY: 'AIzaSyAlee97PLNsJBx_Rtz3kFVk--y9mYn7yQE',
  firebaseConfig: {
    "projectId": "smart-dining-development",
    "appId": "1:769366846121:web:ad586c749c6251c510d7b8",
    "storageBucket": "smart-dining-development.appspot.com",
    "apiKey": "AIzaSyCUq6o7JQlimOGrpCH2lafm5vAMfoFbfck",
    "authDomain": "smart-dining-development.firebaseapp.com",
    "messagingSenderId": "769366846121",
    "measurementId": "G-2ZW8LY1Q5P"
  }
};

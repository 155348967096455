import {MenuResponseFoodItem} from "../data/menu/menu-response";
import {CartItem, CartItemDetail} from "../data/cart/cart";
import {FoodItemPriceType} from "../../../core/constants/food-item-constants";

export class CartItemUtils {
  static isCustomizableItem(menuItem: CartItemDetail) {

    if(menuItem.price?.type == FoodItemPriceType.SIZE_PRICE) {
      return true;
    }

    const modifierGroups = menuItem.modifierGroups ?? [];
    if (modifierGroups.length > 0) {
      return true;
    }
    return false;
  }


  static isSimpleItem(menuItem: CartItemDetail) {

    if(menuItem.price?.type == FoodItemPriceType.SIZE_PRICE) {
      return false;
    }

    const modifierGroups = menuItem.modifierGroups ?? [];
    if (modifierGroups.length > 0) {
      return false;
    }
    return true;
  }
}

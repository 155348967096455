import { Injectable } from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from "../../../core/base/urls";
import {RestaurantReviewsRequest} from "./restaurant-reviews/RestaurantReviewsRequest";
import {WriteRestaurantReviewRequest} from "./write-restaurant-review/write-restaurant-review-request";

@Injectable({
  providedIn: 'root'
})
export class ReviewsService extends BaseApiService {
  writeRestaurantReview(request: WriteRestaurantReviewRequest) {
    return this.post(URLs.writeRestaurantReview, request);
  }

  getRestaurantReviews(request: RestaurantReviewsRequest) {
    return this.post(URLs.getReviewByRestaurant, request);
  }
}

<app-base-dialog [title]="'Login as Guest'" [showAction]="false">
  <div body>
    <div class="flex flex-col gap-4">

      <!--      <div class="flex flex-col gap-2">-->
      <!--        <p class="text-subtitle2 text-primary-500 self-center">Already a user?</p>-->
      <!--        <app-text-input label="Email"></app-text-input>-->
      <!--        <app-text-input label="Password"></app-text-input>-->
      <!--        <app-button [fullWidth]="true">Login</app-button>-->
      <!--      </div>-->

      <!--      <div class="flex items-center">-->
      <!--        <div class="border-b border-neutral-300 flex-grow "></div>-->
      <!--        <p class="text-caption mx-4">OR</p>-->
      <!--        <div class="border-b border-neutral-300 flex-grow"></div>-->
      <!--      </div>-->

      <form [formGroup]="guestInfoForm" class="flex flex-col gap-2" (ngSubmit)="onLoginAsGuestClicked()">
        <!--        <p class="text-subtitle1 text-primary-500 self-center">Sign in as guest</p>-->
        <app-text-input formControlName="firstName" label="First name"></app-text-input>
        <app-text-input formControlName="phoneNumber" label="Phone number"></app-text-input>

        @if (!guestOtpSent()) {
          <app-button [fullWidth]="true" [loading]="sendGuestCodeState.loading()" type="submit">Login as
            Guest
          </app-button>
        } @else {
          <form [formGroup]="verifyOtpForm" (ngSubmit)="onVerifyOTPClicked()"
                class="flex flex-col items-center gap-2 w-full">
            <app-text-input class="w-full" [fullWidth]="true" formControlName="code" label="OTP"
                            placeholder="Enter OTP"></app-text-input>
            <app-button type="submit" class="w-full" [fullWidth]="true" [loading]="verifyGuestCodeState.loading()">
              Verify OTP
            </app-button>
          </form>
        }
      </form>

      <div class="flex items-center">
        <div class="border-b border-neutral-300 flex-grow "></div>
        <p class="text-caption mx-4">OR</p>
        <div class="border-b border-neutral-300 flex-grow"></div>
      </div>

      <p class="text-subtitle1 text-primary-500 self-center">Already have an account?</p>
      <app-button [fullWidth]="true" appearance="outline" (click)="onLoginPressed()">Login</app-button>
      <app-button [fullWidth]="true" appearance="textType" (click)="onSignUpPressed()">Sign up</app-button>
    </div>
  </div>
  <div actions></div>
</app-base-dialog>

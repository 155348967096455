import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  CancelReservationAction
} from "../../../../booking/presentation/overlays/cancel-reservation-confirmation-overlay/cancel-reservation-confirmation-overlay.component";
import {State} from "../../../../../shared/base/base-state";
import {CancelOrderResponse} from "../../../data/cancel-order/cancel-order-response";
import {CancelOrderRequest} from "../../../data/cancel-order/cancel-order-request";
import {PickupOrderService} from "../../../data/pickup-order.service";

@Component({
  selector: 'app-cancel-order-confirmation-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './cancel-order-confirmation-overlay.component.html',
  styleUrl: './cancel-order-confirmation-overlay.component.scss'
})
export class CancelOrderConfirmationOverlayComponent extends BaseComponent {

  dialogRef = inject(MatDialogRef<CancelOrderConfirmationOverlayComponent>);

  onYesClicked() {
    this.dialogRef.close(CancelOrderAction.confirmed);
  }

  onNoClicked() {
    this.dialogRef.close(CancelOrderAction.rejected);
  }
}


export enum CancelOrderAction {
  confirmed,
  rejected
}

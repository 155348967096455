import {Component} from '@angular/core';
import {RouterLink, RouterOutlet} from "@angular/router";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    RouterOutlet,
    AppSvgIconComponent,
    RouterLink,
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent {

}

import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {TagsResponse} from "../../../core/services/common-data/tags/tags-response";
import {TermsAndConditionsResponse} from "./terms-and-conditions/TermsAndConditionsResponse";
import {PrivacyPolicyResponse} from "./privacy-policy/PrivacyPolicyResponse";

@Injectable({
  providedIn: 'root'
})
export class ComplianceService extends BaseApiService {
  getTermsAndConditions() {
    return this.fromFile<TermsAndConditionsResponse>('assets/json/app-data/terms-and-conditions.json');
  }
  getPrivacyPolicy() {
    return this.fromFile<PrivacyPolicyResponse>('assets/json/app-data/privacy-policy.json')
  }
}

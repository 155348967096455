<mat-form-field [ngClass]="fullWidth() ? 'w-full' : 'false'" [appearance]="appearance()">
  <mat-label>{{ label() }}</mat-label>

  <input matInput [formControl]="formControl"
         [type]="type()"
         [placeholder]="placeholder()"
         (blur)="onTouched()"
         (input)="onValueChange($event)">

  @if (formControl.errors && hasErrors) {
    <mat-error>
      {{ formControl.errors | humanizeFormMessages: errorMessages() }}
    </mat-error>
  }

</mat-form-field>


import {Component, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {CounterInputComponent} from "../../../../../shared/inputs/counter-input/counter-input.component";
import {
  DateSelectorInputComponent
} from "../../../../../shared/inputs/date-selector-input/date-selector-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {Subscription} from "rxjs";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {State} from "../../../../../shared/base/base-state";
import {RestaurantDetailResponse} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";
import {RestaurantService} from "../../../../restaurants/data/restaurant.service";
import {Constants} from "../../../../../core/constants/constants";
import {
  TimeAvailabilityForView
} from "../../../../restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {TableAvailabilitiesRequest} from "../../../data/table-availabilities/table-availabilities-request";
import {TableAvailabilitiesResponse} from "../../../data/table-availabilities/table-availabilities-response";
import {BookingsService} from "../../../data/bookings.service";
import {DatePipe} from "@angular/common";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {OccasionsResponse} from "../../../../../core/services/common-data/occasions/occasions-response";
import {TagsResponse, TagsResponseData} from "../../../../../core/services/common-data/tags/tags-response";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {LoginUserType} from "../../../../../core/models/login-user-type";
import {
  GuestLoginData,
  GuestLoginOverlayComponent,
  GuestLoginStatus
} from "../../../../auth/presentation/overlays/guest-login-overlay/guest-login-overlay.component";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {
  MakeReservationRequest,
  MakeReservationRequestCustomerInfo
} from "../../../data/make-reservation/make-reservation-request";
import {MakeReservationResponse} from "../../../data/make-reservation/make-reservation-response";
import {
  BookingConfirmationOverlayComponent
} from "../../overlays/booking-confirmation-overlay/booking-confirmation-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {arrayToCSV} from "../../../../../shared/utils/string-utils";
import {
  MultiSelectionFieldComponent
} from "../../../../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {
  SeatingPreferenceResponse, SeatingPreferenceResponseData
} from "../../../../../core/services/common-data/seating-preference/seating-preference-response";

@Component({
  selector: 'app-make-reservation',
  standalone: true,
  imports: [
    ButtonComponent,
    CounterInputComponent,
    DateSelectorInputComponent,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    TextInputComponent,
    NetworkImageComponent,
    MultiSelectionFieldComponent
  ],
  providers: [
    DatePipe
  ],
  templateUrl: './make-reservation.component.html',
  styleUrl: './make-reservation.component.scss'
})
export class MakeReservationComponent extends BaseComponent implements OnInit, OnDestroy {
  restaurantId = input('');
  date = input<string | null>(null);
  partySize = input<number | null>(4);

  dialog = inject(MatDialog);
  formBuilder = inject(FormBuilder);
  restaurantService = inject(RestaurantService);
  bookingService = inject(BookingsService);
  datePipe = inject(DatePipe);
  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);
  bookingsService = inject(BookingsService);

  reservationForm!: FormGroup;

  private formSubscription!: Subscription;

  availabilities = signal<TimeAvailabilityForView[]>([]);

  restaurantDetailState = new State<RestaurantDetailResponse>();
  tableAvailabilitiesState = new State<TableAvailabilitiesResponse>();
  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  reservationState = new State<MakeReservationResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();

  ngOnInit(): void {
    this.initReservationForm();
    this.getRestaurantDetails();
    this.getTableAvailabilities();
    this.getOccasions();
    this.getTags();
    this.getSeatingPreference();

  }

  initReservationForm() {
    let date = this.date() ? new Date(this.date()!) : new Date();
    this.reservationForm = this.formBuilder.group({
      date: [date, [Validators.required]],
      partySize: [Number(this.partySize() ?? 4), [Validators.required]],
      time: [null, [Validators.required]],
      tags: [[]],
      occasion: [],
      note: [''],
      seatingOption: [],
    });
    this.formSubscription = this.reservationForm.valueChanges.subscribe(data => {
      this.mergeQueryParams({date: data.date.toISOString(), partySize: data.partySize});
    });
  }


  getRestaurantDetails() {
    this.executeRequest({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(this.restaurantId()),
    });
  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  onDateSelected($event: Date) {
    this.getTableAvailabilities();
  }

  onPartSizeChanged(event: number) {
    this.getTableAvailabilities();
  }

  getTableAvailabilities() {
    let date = this.reservationForm.get('date')?.value;
    const partySize = this.reservationForm.get('partySize')?.value;
    let request: TableAvailabilitiesRequest = {
      restaurantId: this.restaurantId(),
      year: date?.getFullYear(),
      month: date!.getMonth() + 1,
      day: date?.getDate(),
      partySize: partySize,
      tableIds: []
    };
    this.executeRequest<TableAvailabilitiesResponse>({
      state: this.tableAvailabilitiesState,
      request: this.bookingService.getTableAvailabilities(request),
      onSuccess: response => {
        let availabilities: TimeAvailabilityForView[] = [];
        response.data.forEach(shift => {
          shift.availabilities.forEach(time => {
            availabilities.push({
              shiftId: shift.shiftId,
              partySize: time.partySize,
              time: time.time,
              timeString: this.datePipe.transform(time.time, 'HH:mm'),
            });
          });
        });
        this.availabilities.set(availabilities);
      }
    });
  }

  onReserveClicked() {
    if (this.reservationForm.invalid) {
      return;
    }

    this.handleReservation();
  }

  handleReservation() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.reserveTable(LoginUserType.appUser, null);
      return;
    }

    this.dialog.open(GuestLoginOverlayComponent, {
      minWidth: '450px',
      maxWidth: '600px',
      maxHeight: '100vh'
    }).afterClosed().subscribe((result: GuestLoginData) => {
      if (result.status == GuestLoginStatus.success) {
        this.reserveTable(LoginUserType.guestUser, result);
      }
    });
  }

  reserveTable(userType: LoginUserType, guestUserData: GuestLoginData | null) {
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
      case LoginUserType.guestUser:
        customerInfo = {
          firstName: guestUserData?.firstName ?? '',
          phoneNumber: guestUserData?.phoneNumber ?? ''
        };
        break;
    }

    let availability = this.reservationForm.get('time')?.value;
    const tagsValue = (this.reservationForm.value.tags ?? []) as TagsResponseData[];
    let tags = ['NO'];
    if(tagsValue.length > 0) {
      tags = tagsValue.map(x => x.name)
    }

    const occasion = this.reservationForm.value.occasion?.name ?? 'NO';

    const seatingOption = this.reservationForm.value.seatingPreferences?.name;


    const request: MakeReservationRequest = {
      restaurantId: this.restaurantId(),
      shiftId: availability?.shiftId,
      partySize: this.reservationForm.value.partySize,
      time: availability?.time,
      autoAssignTable: true,
      customerInfo: customerInfo,
      note: this.reservationForm.value.note,
      tags: tags,
      occasion: occasion,
      seatingOption: seatingOption,
      coverSource: 'GUEST_WEB_APP'
    };

    this.executeRequest<MakeReservationResponse>({
      state: this.reservationState,
      request: this.bookingsService.makeReservation(request),
      handleSuccess: true,
      successMessage: 'Table reserved successfully!',
      onSuccess: response => {
        this.dialog.open(BookingConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        }).afterClosed().subscribe(data => {
          this.router.navigate([`/dine-in-restaurant-details/${this.restaurantId()}`]);        });
      }
    });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  protected readonly arrayToCSV = arrayToCSV;
}

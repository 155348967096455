export function numberToPricePoint(pricePoint: number | null): string {
  switch (pricePoint) {
    case 1:
      return '$';
    case 2:
      return '$$';
    case 3:
      return '$$$';
    case 4:
      return '$$$$';
    case 5:
      return '$$$$$';
    default:
      return '$$$$$';
  }
}

export function pricePointToNumber(pricePoint: string): number {
  switch (pricePoint) {
    case '$':
      return 1;
    case '$$':
      return 2;
    case '$$$':
      return 3;
    case '$$$$':
      return 4;
    case '$$$$$':
      return 5;
    default:
      return 5;
  }
}

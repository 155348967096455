<div class="container-fluid mx-auto bg-neutral-50 py-20" style="background-image: url('assets/images/home/bg-line.svg')">
  <div class="container mx-auto px-8 sm:px-16 py-8">
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 items-center">
          <div class="py-20 sm:py-0">
              <img src="assets/images/home/image-f.png" alt="">
          </div>
          <div class="flex justify-center items-center">
              <div class="flex flex-col gap-5">
                  <p class="text-h6 text-neutral-500">Easy and Fast</p>
                  <p class="text-h4 text-neutral-800 font-bold">Save money On every meal in 3 easy steps</p>

                  <div class="flex gap-5">
                      <img src="assets/images/home/phone.png" alt="" class="w-15">
                      <div class="flex flex-col gap-2">
                          <p class="text-h6 font-bold text-neutral-500">Download Smart Dining App</p>
                          <p class="text-body1 text-neutral-500">Download Smart Dining for 100's of exclusive deals every day.</p>
                      </div>
                  </div>
                  <div class="flex gap-5">
                      <img src="assets/images/home/check.svg" alt="" class="w-15">
                      <div class="flex flex-col gap-2">
                          <p class="text-h6 font-bold text-neutral-500">100's of exclusive deals</p>
                          <p class="text-body1 text-neutral-500">100's of exclusive deals every day from local favorite restaurants.</p>
                      </div>
                  </div>
                  <div class="flex gap-5">
                      <img src="assets/images/home/premium.svg" alt="" class="w-15">
                      <div class="flex flex-col gap-2">
                          <p class="text-h6 font-bold text-neutral-500">Premium loyalty Program</p>
                          <p class="text-body1 text-neutral-500">You and your guests can pre-order online before the reservation date and time.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

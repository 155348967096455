import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../../shared/base/base-component";
import {TermsAndConditionsResponse} from "../../../data/terms-and-conditions/TermsAndConditionsResponse";
import {State} from "../../../../../shared/base/base-state";
import {ComplianceService} from "../../../data/compliance.service";

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent extends BaseComponent implements OnInit {

  complianceService = inject(ComplianceService);

  termsAndConditionsState = new State<TermsAndConditionsResponse>();

  ngOnInit(): void {
    this.getTermsAndConditions();
  }

  private getTermsAndConditions() {
    this.executeRequest<TermsAndConditionsResponse>({
      state: this.termsAndConditionsState,
      request: this.complianceService.getTermsAndConditions()
    });
  }
}

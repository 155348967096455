<div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 gap-x-16">
    <div class="flex flex-col gap-3 items-">
      <p class="text-neutral-800 text-body1 font-medium">Reviews</p>
      <p class="text-primary-500 text-body1 font-medium">Customer reviews & ratings</p>
      <div class="flex gap-3 items-center">
        <div class="flex gap-1">
          <app-individual-rating [readonly]="true" [rating]="review()?.rating ?? 0"></app-individual-rating>
        </div>
        <p class="text-body2">({{ review()?.rating }} out of 5)</p>
      </div>
      <p class="text-neutral-600 text-body2">Based on {{ review()?.ratingCount }} reviews</p>
    </div>
    <div class="flex flex-col gap-3">
      <div class="grid items-center grid-cols-5 gap-x-4">
        <span class="text-body2 text-neutral-700 whitespace-nowrap"> 5 stars </span>
        <div class="col-span-3 relative w-full bg-neutral-200 rounded-full h-1.5">
          <div  class="absolute inset-y-0 left-0 bg-neutral-900 rounded-full" [ngClass]="getCss(fiveStarPercentage)"></div>
        </div>
        <span class="text-body2 text-neutral-700"> {{ review()?.fiveStarRatings }}  </span>
      </div>

      <div class="grid items-center grid-cols-5 gap-x-4">
        <span class="text-body2 text-neutral-700 whitespace-nowrap"> 4 stars </span>
        <div class="col-span-3 relative w-full bg-neutral-200 rounded-full h-1.5">
          <div class="absolute inset-y-0 left-0 bg-neutral-900 rounded-full" [ngClass]="getCss(fourStarPercentage)"></div>
        </div>
        <span class="text-body2 text-neutral-700"> {{ review()?.fourStarRatings }}  </span>
      </div>

      <div class="grid items-center grid-cols-5 gap-x-4">
        <span class="text-body2 text-neutral-700 whitespace-nowrap"> 3 stars </span>
        <div class="col-span-3 relative w-full bg-neutral-200 rounded-full h-1.5">
          <div class="absolute inset-y-0 left-0 bg-neutral-900 rounded-full" [ngClass]="getCss(threeStarPercentage)"></div>
        </div>
        <span class="text-body2 text-neutral-700"> {{ review()?.threeStarRatings }}  </span>
      </div>

      <div class="grid items-center grid-cols-5 gap-x-4">
        <span class="text-body2 text-neutral-700 whitespace-nowrap"> 2 stars </span>
        <div class="col-span-3 relative w-full bg-neutral-200 rounded-full h-1.5">
          <div class="absolute inset-y-0 left-0 bg-neutral-900 rounded-full" [ngClass]="getCss(twoStarPercentage)"></div>
        </div>
        <span class="text-body2 text-neutral-700"> {{ review()?.twoStarRatings }}  </span>
      </div>

      <div class="grid items-center grid-cols-5 gap-x-4">
        <span class="text-body2 text-neutral-700 whitespace-nowrap"> 1 star </span>
        <div class="col-span-3 relative w-full bg-neutral-200 rounded-full h-1.5">
          <div class="absolute inset-y-0 left-0 bg-neutral-900 rounded-full" [ngClass]="getCss(oneStarPercentage)"></div>
        </div>
        <span class="text-body2 text-neutral-700"> {{ review()?.oneStarRatings }}  </span>
      </div>
    </div>
  </div>


import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../../shared/base/base-component";
import {ComplianceService} from "../../../data/compliance.service";
import {PrivacyPolicyResponse} from "../../../data/privacy-policy/PrivacyPolicyResponse";
import {State} from "../../../../../shared/base/base-state";

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent extends BaseComponent implements OnInit {

  complianceService = inject(ComplianceService);

  privacyPolicyState = new State<PrivacyPolicyResponse>();

  ngOnInit(): void {
    this.getPrivacyPolicy();
  }

  private getPrivacyPolicy() {
    this.executeRequest<PrivacyPolicyResponse>({
      state: this.privacyPolicyState,
      request: this.complianceService.getPrivacyPolicy()
    });
  }
}

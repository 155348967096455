<div class="container-fluid mx-auto bg-neutral-100 py-20">
    <div class="container mx-auto px-8 sm:px-16 py-8">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="flex justify-center items-center gap-4">
          <div class="flex flex-col gap-5">
            <p class="text-h6 font-bold text-neutral-500">Easy and Fast</p>
            <p class="text-h4 text-neutral-800 font-bold">Order ahead next meals in 3 easy steps</p>

            <div class="flex gap-5">
              <img src="assets/images/home/order.svg" alt="" class="w-15">
              <div class="flex flex-col gap-2">
                <p class="text-h6 text-neutral-500 font-bold">Order Ahead</p>
                <p class="text-body1 text-neutral-500">Order ahead takeout from your favorite restaurants.</p>
              </div>
            </div>
            <div class="flex gap-5">
              <img src="assets/images/home/waiter.svg" alt="" class="w-15">
              <div class="flex flex-col gap-2">
                <p class="text-h6 font-bold text-neutral-500">Pay in seconds</p>
                <p class="text-body1 text-neutral-500">and save your infor for easy re-order</p>
              </div>
            </div>
            <div class="flex gap-5">
              <img src="assets/images/home/pickup.svg" alt="" class="w-15">
              <div class="flex flex-col gap-2">
                <p class="text-h6 text-neutral-500 font-bold">Pickup in store</p>
                <p class="text-body1 text-neutral-500">Pick up in store or get delivered and never wait in line.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="relative -top-10 py-20">
          <img src="assets/images/home/image-e" alt="">
        </div>
      </div>
    </div>
  </div>

export function arrayToCSV(arr: (string | null | undefined)[]): string {
  const filteredArr = arr.filter((item) => item !== null && item !== undefined && item !== '');
  return filteredArr.join(', ');
}

export function arrayToSpaceSeparator(arr: (string | null | undefined)[]): string {
  const filteredArr = arr.filter((item) => item !== null && item !== undefined && item !== '');
  return filteredArr.join(' ');
}

export function toDashCase(sentence: any): string {
  return sentence.replace(/\s+/g, '-').toLowerCase();
}

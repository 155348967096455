<app-top-nav class="fixed top-0 left-0 right-0 z-50 bg-white"></app-top-nav>
<div class="mt-20"></div>
<div class="container-fluid sm:container mx-auto py-8 px-4 flex flex-col sm:flex-row gap-6">
  <div class="block sm:hidden">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a mat-tab-link routerLink="/settings/account">Account</a>
      <a mat-tab-link routerLink="/settings/addresses">Address</a>
      <a mat-tab-link routerLink="/settings/payment-methods">Payment methods</a>
      <a mat-tab-link routerLink="/settings/security">Security</a>
      <a mat-tab-link routerLink="/settings/favorites">Favorites</a>
    </nav>
  </div>

  <div class=" hidden sm:flex gap-3 sm:flex-col">
    <app-setting-menu-item routerLink="/settings/account" title="Account" description="Personal Information"
                           icon="assets/icons/guest.svg"
                           [isSelected]="activeRoute() == '/settings/account'"></app-setting-menu-item>

    <app-setting-menu-item routerLink="/settings/addresses" title="Address" description="Address details"
                           icon="assets/icons/location.svg"
                           [isSelected]="activeRoute() == '/settings/addresses'"></app-setting-menu-item>

    <app-setting-menu-item routerLink="/settings/payment-methods" title="Payment method"
                           description="Saved credit/dedit cards"
                           icon="assets/icons/payment-method.svg"
                           [isSelected]="activeRoute() == '/settings/payment-methods'"></app-setting-menu-item>

    <app-setting-menu-item routerLink="/settings/security" title="Security" description="Password 2FA"
                           icon="assets/icons/security.svg"
                           [isSelected]="activeRoute() == '/settings/security'"></app-setting-menu-item>

    <app-setting-menu-item routerLink="/settings/favorites" title="Favorites" description="Your Favorites"
                           icon="assets/icons/heart-outline.svg"
                           [isSelected]="activeRoute() == '/settings/favorites'"></app-setting-menu-item>
  </div>
  <div class="px-6 py-8 shadow-1 border border-neutral-200 rounded-3xl w-full">
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
<app-footer></app-footer>













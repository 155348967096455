import {Component, inject, OnInit, signal} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {DatePipe} from "@angular/common";
import {OrderInformationResponseData} from "../../../data/order-information/order-information-response";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-order-confirmation-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    AppSvgIconComponent,
    DatePipe
  ],
  templateUrl: './order-confirmation-overlay.component.html',
  styleUrl: './order-confirmation-overlay.component.scss'
})
export class OrderConfirmationOverlayComponent implements OnInit {
  data: OrderInformationResponseData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);

  orderScheduleOption = signal<string>('');
  orderType = signal<string>('');

  ngOnInit(): void {

    switch (this.data.orderScheduleOption.toLowerCase()) {
      case 'asap':
        this.orderScheduleOption.set('ASAP');
        break;
      case 'later':
        this.orderScheduleOption.set('Scheduled');
        break;
    }

    switch (this.data.diningOption) {
      case 'DINE_IN':
        this.orderType.set('Dine in');
        break;
      case 'RESERVATION_DINE_IN':
        this.orderType.set('Dine in');
        break;
      case 'TAKE_OUT':
        this.orderType.set('Dine in');
        break;
      case 'DELIVERY':
        this.orderType.set('Delivery');
        break;
      case 'PICK_UP':
        this.orderType.set('Pickup');
        break;
      case 'CURBSIDE_PICK_UP':
        this.orderType.set('Curbside pickup');
        break;
    }

  }

}


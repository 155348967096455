<form [formGroup]="form" class="flex flex-col gap-3 text-center text-neutral-700">
    <p class="text-h5">Sign Up</p>
    <p>Build a bridge to the future.</p>
    <app-text-input formControlName="firstName" label="First Name"></app-text-input>
    <app-text-input formControlName="lastName" label="Last Name"></app-text-input>
    <app-text-input formControlName="email" label="Email"></app-text-input>
    <app-text-input formControlName="phoneNumber" label="Phone Number"></app-text-input>
    <app-text-input formControlName="password" label="Password" type="password"></app-text-input>
    <p>By clicking create account, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and Cookie Policy
    </p>
    <app-button [fullWidth]="true" (click)="signup()">Create Account</app-button>
</form>

<section class="flex flex-col bg-white border-b">
  <div class="container flex top-bar-height justify-between items-center mx-auto py-2 px-4 sm:px-6">

    <div class="flex gap-3 items-center">
      <img routerLink="/home" class="cursor-pointer" src="assets/images/branding/app-logo.png" width="48"
           alt="app-logo">

      <app-location-selector
        [location]="appDataService.currentAddress()?.address ?? 'Select an address'"
        (click)="onLocationClicked()"></app-location-selector>

      @if (topNavService.showBookingOption()) {
        <div class=" hidden lg:flex gap-4 items-center border rounded-full px-3 py-2">
          <div class="flex items-center gap-2 cursor-pointer px-2 py-2 rounded-full"
               [ngClass]="dineInOption() == DineInOption.dineIn ? 'bg-primary-500 text-white' : 'bg-white text-primary-500'"
               (click)="onDineOptionSelected(DineInOption.dineIn)">
            <app-svg-icon src="assets/icons/dine-in.svg"
                          [ngClass]="dineInOption() == DineInOption.dineIn ? 'text-white' : 'text-primary-500'">
            </app-svg-icon>
            <p class="text-body2 whitespace-nowrap">Dine In</p>
          </div>
          <div class="flex items-center gap-2 cursor-pointer px-2 py-2 rounded-full"
               [ngClass]="dineInOption() == DineInOption.pickupOrder ? 'bg-primary-500 text-white' : 'bg-white text-primary-500'"
               (click)="onDineOptionSelected(DineInOption.pickupOrder)">
            <app-svg-icon src="assets/icons/food-orders.svg"
                          [ngClass]="dineInOption() == DineInOption.pickupOrder ? 'text-white' : 'text-primary-500'">
            </app-svg-icon>
            <p class="text-body2 whitespace-nowrap">Pickup</p>
          </div>
        </div>

      }
    </div>


    <div class="flex items-center gap-5 sm:hidden">
      @if (topNavService.showRestaurantButton()) {
        <a
          class="hidden text-body2 text-primary-500 hover:text-primary-900 p-2 hover:bg-primary-50 hover:bg-opacity-50 rounded-xl"
          href="https://restaurant.gosmartdining.com" target="_blank">For business
        </a>
      }
      @if (authStoreService.isAuthenticated()) {
        <div class="flex items-center cursor-pointer p-2 rounded-md hover:bg-neutral-100 transition-all duration-500"
             (click)="onViewCartPressed()">
          <app-svg-icon src="assets/icons/cart.svg"></app-svg-icon>
          <span class="pl-1 text-xs font-semibold text-white rounded-full px-1 py-0.5 min-w-[20px] text-center"
                [ngClass]="cartService.cart().quantity > 0 ? 'bg-red-500' : 'bg-red-400'">
            {{ cartService.cart().quantity }}
          </span>

        </div>

        <app-svg-icon [matMenuTriggerFor]="menu" src="assets/icons/account-circle.svg"
                      class="text-neutral-500 cursor-pointer" [size]="24"></app-svg-icon>
        <mat-menu #menu="matMenu" class="mat-elevation-z3 " xPosition="before">
          <div routerLink="/settings/account"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <app-svg-icon src="assets/icons/account-circle.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></app-svg-icon>
            <span class="text-body2">Account</span>
          </div>
          <div routerLink="/bookings/past-bookings"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <app-svg-icon src="assets/icons/calendar.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></app-svg-icon>
            <span class="text-body2">Bookings</span>
          </div>
          <div routerLink="/orders/active-orders"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <app-svg-icon src="assets/icons/food-orders.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></app-svg-icon>
            <span class="text-body2">Orders</span>
          </div>
          <div routerLink="/invitations/received-invitations"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <app-svg-icon src="assets/icons/email-open-heart-outline.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></app-svg-icon>
            <span class="text-body2">Invitations</span>
          </div>
          <div class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100"
               (click)="onLogoutClick()">
            <app-svg-icon src="assets/icons/logout.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></app-svg-icon>
            <span class="text-body2">Logout</span>
          </div>
        </mat-menu>
      }


      @if (expanded()) {
        <img src="assets/icons/menu-icon.png" (click)="onHamburgerClick()" alt="menu-icon" class="w-5">

      } @else {
        <app-svg-icon src="assets/icons/close.svg" (click)="onHamburgerClick()" alt="menu-icon" [size]="16"
                      class="text-neutral-500 w-5"></app-svg-icon>
      }
    </div>
    <div class="hidden sm:flex items-center gap-5">

      <div class="flex items-center gap-2 ml-5">
        <div class="hidden sm:block px-5">
          @if (topNavService.showRestaurantSuggestion()) {
            <app-restaurant-suggestion></app-restaurant-suggestion>
          }
        </div>
        @if (topNavService.showRestaurantButton()) {
          <div class="flex items-center gap-2 cursor-pointer hover:text-primary-900 p-2 hover:bg-primary-50
          hover:bg-opacity-50 rounded-xl" (click)="onForBusinessClicked()">
            <app-svg-icon src="assets/icons/open-in-new.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
            <p class="flex items-center gap-2 text-body2 whitespace-nowrap text-primary-500">For business</p>
          </div>
        }
        @if (authStoreService.isAuthenticated()) {

          <app-svg-icon [matMenuTriggerFor]="menu" src="assets/icons/account-circle.svg"
                        class="text-neutral-500 cursor-pointer" [size]="24"></app-svg-icon>
          <mat-menu #menu="matMenu" class="mat-elevation-z3" xPosition="before">
            <div routerLink="/settings/account"
                 class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <app-svg-icon src="assets/icons/account-circle.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></app-svg-icon>
              <span class="text-body2">Account</span>
            </div>
            <div routerLink="/bookings/upcoming-bookings"
                 class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <app-svg-icon src="assets/icons/calendar.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></app-svg-icon>
              <span class="text-body2">Bookings</span>
            </div>
            <div routerLink="/orders/active-orders"
                 class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <app-svg-icon src="assets/icons/food-orders.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></app-svg-icon>
              <span class="text-body2">Orders</span>
            </div>
            <div routerLink="/invitations/received-invitations"
                 class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <app-svg-icon src="assets/icons/email-open-heart-outline.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></app-svg-icon>
              <span class="text-body2">Invitations</span>
            </div>
            <div class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100"
                 (click)="onLogoutClick()">
              <app-svg-icon src="assets/icons/logout.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></app-svg-icon>
              <span class="text-body2">Logout</span>
            </div>
          </mat-menu>
        } @else {
          <app-button routerLink="/login" class="text-white text-sm">
            <span>Login</span>
          </app-button>
        }
        <div class="flex items-center cursor-pointer p-2 rounded-md hover:bg-neutral-100 transition-all duration-500"
             (click)="onViewCartPressed()">
          <app-svg-icon src="assets/icons/cart.svg"></app-svg-icon>
          <span class="pl-1 text-xs font-semibold text-white rounded-full px-1 py-0.5 min-w-[20px] text-center"
                [ngClass]="cartService.cart().quantity > 0 ? 'bg-red-500' : 'bg-red-400'">
            {{ cartService.cart().quantity }}
          </span>
        </div>
      </div>
      <!--      <app-svg-icon src="assets/icons/bag.svg" class="text-neutral-500" [size]="24"></app-svg-icon>-->
    </div>
  </div>

  @if (!expanded()) {
    <div class="container px-5 py-8 sm:mx-auto" (clickOutside)="clickedOutSideMobileNav()">
      <div class="grid gap-y-7">
        <a class="text-body2 text-primary-700" routerLink="/restaurants">Restaurants</a>

        @if (!authStoreService.isAuthenticated()) {
          <a class="text-body2 text-primary-700" routerLink="/signup">Sign up</a>
          <app-button class="w-full" [fullWidth]="true" (click)="onLoginClicked()">Login</app-button>
        }

      </div>
    </div>
  }
</section>




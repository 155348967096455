import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetContainer, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {CounterInputComponent} from "../../../../../shared/inputs/counter-input/counter-input.component";
import {FormsModule} from "@angular/forms";
import {FoodItem, FoodItemModifierGroup} from "../../../data/menu/menu";
import {DecimalPipe, NgClass} from "@angular/common";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {CheckboxComponent} from "../../../../../shared/inputs/checkbox/checkbox.component";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {CloseButtonComponent} from "../../../../../shared/components/close-button/close-button.component";
import {ModifierGroupSelectionType} from "../../../../../core/constants/food-item-constants";

@Component({
  selector: 'app-customizable-item-selector-overlay',
  standalone: true,
  imports: [
    NetworkImageComponent,
    CounterInputComponent,
    FormsModule,
    DecimalPipe,
    MatBottomSheetContainer,
    ButtonComponent,
    CheckboxComponent,
    RadioButtonComponent,
    AppSvgIconComponent,
    NgClass,
    CloseButtonComponent
  ],
  templateUrl: './customizable-item-selector-overlay.component.html',
  styleUrl: './customizable-item-selector-overlay.component.scss'
})
export class CustomizableItemSelectorOverlayComponent implements OnInit {

  foodItem: FoodItem = inject(MAT_BOTTOM_SHEET_DATA);
  bottomSheetRef = inject(MatBottomSheetRef);
  activeModifierGroup = signal<FoodItemModifierGroup | null>(null);

  noOfItems = signal(1);

  ngOnInit(): void {
    this.activeModifierGroup.set(this.foodItem.modifierGroups[0]);
  }

  currentGroupIndex = computed(() => {
    let currentGroupIndex = this.foodItem.modifierGroups.indexOf(this.activeModifierGroup()!);
    return currentGroupIndex;
  });

  isNextAvailable() {
    if (this.currentGroupIndex() < this.foodItem.modifierGroups.length - 1) {
      return true;
    }

    return false;
  }

  isLastOption() {
    if(this.currentGroupIndex() == this.foodItem.modifierGroups.length - 1) {
      return true;
    }
    return false;
  }

  isPreviousAvailable() {
    if (this.currentGroupIndex() > 0) {
      return true;
    }

    return false;
  }

  onPreviousPressed() {
    if (this.currentGroupIndex() > 0) {
      this.activeModifierGroup.set(this.foodItem.modifierGroups[this.currentGroupIndex() - 1]);
    }
  }

  onNextPressed() {
    if (this.currentGroupIndex() < this.foodItem.modifierGroups.length) {
      this.activeModifierGroup.set(this.foodItem.modifierGroups[this.currentGroupIndex() + 1]);
    }
  }

  onCloseClicked() {
    this.bottomSheetRef.dismiss();
  }

  onAddItemPressed() {
    let selection: CustomizableItemSelection = {
      quantity: this.noOfItems(),
      foodItem: this.foodItem
    };
    this.bottomSheetRef.dismiss(selection);
  }

  protected readonly ModifierGroupSelectionType = ModifierGroupSelectionType;
}

export interface CustomizableItemSelection {
  quantity: number;
  foodItem: FoodItem;
}

import {Component, input} from '@angular/core';
import {NgClass} from "@angular/common";
import {BaseComponent} from "../../../../../shared/base/base-component";

@Component({
  selector: 'app-orders-tab',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './orders-tab.component.html',
  styleUrl: './orders-tab.component.scss'
})
export class OrdersTabComponent extends BaseComponent {
  isActiveOrder = input(false);
  isPastOrder = input(false);

  onActiveOrdersClicked() {
    this.router.navigate(['/orders/active-orders']);
  }

  onPastOrdersClicked() {
    this.router.navigate(['/orders/past-orders']);
  }
}
